<template>
  <div class="v-footer" v-if="show">
    <div class="container">
      <div class="top-bar">
        <div class="name">相关链接</div>
        <div class="cont">
          <a target="_blank" href="http://www.cma.gov.cn/">中国气象局</a>
          <a target="_blank" href="http://www.pmsc.cn/"
            >中国气象局公共气象服务中心</a
          >
          <a target="_blank" href="http://www.ncc-cma.net/">国家气候中心</a>
          <a target="_blank" href="http://www.chinamsa.org/"
            >中国气象服务协会</a
          >
        </div>
      </div>
      <div class="main-bar">
        <div class="left-column">
          <div class="content mgb-20">
            <p><strong>创建示范活动主办单位</strong></p>
            <p class="mgb-10">
              <a target="_blank" href="https://www.cma.gov.cn/">中国气象局</a>
            </p>
            <p><strong>创建示范活动承办单位</strong></p>
            <p>
              <a target="_blank" href="https://www.pmsc.cn/"
                >中国气象局公共气象服务中心</a
              >
            </p>
            <p>
              <a target="_blank" href="http://www.ncc-cma.net/cn/"
                >国家气候中心</a
              >
            </p>
          </div>
          <div class="title">联系我们</div>
          <div class="content">
            <div>
              <i class="iconfont icon-phone-2"></i> 咨询电话：010-58993157
            </div>
            <div>
              <i class="iconfont icon-xinfeng"></i> 邮箱：<a
                target="_blank"
                href="https://www.163.com/"
                >wxbv@163.com</a
              >
            </div>
            <div>
              <i class="iconfont icon-map"></i>
              地址：北京市海淀区中关村南大街46号
            </div>
            <div class="copyright j-copyright">
              <a class="cn" target="_blank" href="https://beian.miit.gov.cn/"
                >京ICP备11012639号-8</a
              >
              <KOimg
                class="b"
                :src="require('@/assets/img/index/b.png')"
              ></KOimg>
              <div class="cn">京公网安备 11010802040288号</div>
              <div class="cn">技术支持：北京华泰德丰技术有限公司</div>
            </div>
          </div>
        </div>
        <div class="right-column">
          <KOimg
            class="m-ew"
            :src="require('@/assets/img/index/ew.png')"
          ></KOimg>
          <div class="name">扫码关注“国家气候标志”公众号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (['/book'].includes(val.path)) {
          this.show = false
        } else {
          this.show = true
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
  background: linear-gradient(90deg, #4cbe9d, #25c4de);
  font-size: 14px;
  padding: 50px 0;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #ff0;
      cursor: pointer;
    }
  }
  .container {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .top-bar {
      display: flex;
      margin-bottom: 30px;
      .name {
        flex-shrink: 0;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid #fff;
        font-weight: bold;
      }
      .cont {
        flex-grow: 1;
        display: flex;
        a {
          margin-right: 30px;
          &:hover {
            color: #ff0;
            cursor: pointer;
          }
        }
      }
    }
    .main-bar {
      display: flex;
      .left-column {
        flex-grow: 1;
        .title {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .content {
          line-height: 2;
          .copyright {
            padding-top: 20px;
            display: flex;
            align-items: center;
            .b {
              width: 20px;
              height: 20px;
            }
            .cn {
              margin-right: 20px;
            }
          }
        }
      }
      .right-column {
        width: 300px;
        .m-ew {
          width: 126px;
          height: 126px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px;
        }
        .name {
          text-align: center;
        }
      }
    }
  }
}
</style>
