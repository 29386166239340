export default {
  install(app) {
    //批量注册全局组件
    //加载该目录下所有.vue文件
    //组件必须要有name字段，并且name字段就是组件的tag
    const ctx = require.context('./', true, /\.vue$/)
    ctx.keys().forEach((item) => {
      //item：组件的地址 ctx(item) 导入这个组件
      const component = ctx(item).default
      app.component(component.name, component)
    })
  }
}
