<template>
  <div class="car-page-news-first">
    <div class="img-box">
      <Swiper
        effect="fade"
        :autoplay="true"
        :slides-per-view="1"
        @swiper="initSwiper"
        :modules="modules"
        :pagination="{ clickable: true }"
      >
        <SwiperSlide
          v-for="(item, ind) in list"
          :key="item"
          @click="EventOpen(item, ind)"
        >
          <KOimg :src="item.img" fit="cover"></KOimg>
          <div class="title" :class="getClass(item)" :title="item.title">
            {{ item.title }}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div v-if="false" class="text-box">
      <div class="title"></div>
      <div class="line"></div>
      <div class="content"></div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, EffectFade, Autoplay } from 'swiper'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
export default {
  log: '',
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modules: [Pagination, EffectFade, Autoplay]
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    initSwiper(swiper) {
      this.swiper = swiper
    },
    EventOpen(item) {
      this.Api.openNewsDetail(item)
    },
    getNewStyle(item) {
      return this.Api.isNew(item.publishTime)
    },
    getClass(item) {
      let isTop = item.isTop ? 'news-top' : ''
      let isNew = this.getNewStyle(item) ? 'new' : ''
      return isTop + ' ' + isNew
    }
  }
}
</script>
<style lang="scss" scoped>
.car-page-news-first {
  height: 100%;
  .img-box {
    position: relative;
    background: #dff8fc;
    ::v-deep(.swiper) {
      .swiper-pagination {
        position: absolute;
        bottom: 15px;
        right: 10px;
        left: auto;
        width: auto;
        --swiper-pagination-bullet-inactive-opacity: 1;
        .swiper-pagination-bullet {
          background: #dfeaec;
          border-radius: 5px;
          &.swiper-pagination-bullet-active {
            background: #23c4e0;
          }
        }
      }
    }
    .ko-img {
      width: 100%;
      height: 320px;
      cursor: pointer;
      background: #fff;
      @media screen and (min-width: 1200px) {
        height: 390px;
      }
    }
    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      &.new::before {
        margin-right: 6px;
        display: block;
        width: 27px;
        height: 14px;
        content: '';
        flex-shrink: 0;
        background: url('~@/assets/img/icon/icon-new.png') left center/contain
          no-repeat;
      }
      &.news-top::after {
        display: inline-block;
        padding: 2px 5px;
        content: '置顶';
        font-size: 12px;
        background: #f00;
        color: #fff;
        margin-left: 10px;
        border-radius: 2px;
      }
    }
  }
  .text-box {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.6;
    text-align: justify;
    .title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .line {
      width: 100px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.36);
      margin-bottom: 20px;
    }
    .content {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
