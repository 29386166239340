<template>
  <div class="cpn-chart-bar-oxy-city">
    <div class="sub-title">{{ subTitle }}</div>
    <ChartCore
      ref="RChartView"
      class="m-chart-core"
      v-loading="loading"
    ></ChartCore>
  </div>
</template>
<script>
import ChartCore from '@/components/content/chart/ChartCore.vue'
//import { nowSize } from '@/plug/chart/chartFn.js'
import { Info_chartNegativeO2YearList } from '@/info/infoHome.js'
import { getLinearColor } from '@/plug/chart/chartFn.js'
export default {
  log: '',
  components: { ChartCore },
  props: {
    fmt: {
      type: String,
      default: 'YYYY'
    },
    areaCode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      listData: [],
      xAxisData: [],
      title: '',
      subTitle: new Date().DateAdd('d', -1).Format('YYYY年'),
      option: {},
      loading: false
    }
  },
  watch: {
    areaCode: {
      handler() {
        this.getData()
      }
    }
  },
  computed: {},
  updated() {},
  mounted() {
    this.getData()
    this.initEvent()
  },
  methods: {
    initEvent() {
      window.addEventListener('resize', () => {
        this.initData()
      })
    },
    getTestData() {
      this.listData = []
      this.xAxisData = [
        '黑河',
        '伊春',
        '牡丹江',
        '大兴安岭',
        '双鸭山',
        '哈尔滨',
        '鹤岗',
        '鸡西',
        '绥化'
      ]
      for (let i = 0; i < 12; i++) {
        this.listData.push(parseInt(Math.random() * 2000) + 3000)
      }
    },
    getData() {
      this.listData = []
      this.xAxisData = []
      this.loading = true
      Info_chartNegativeO2YearList({
        regionProvincialId: this.areaCode
      }).then((ret) => {
        this.loading = false
        if (ret.data?.length) {
          this.subTitle = ret.data[0].year + '年'
          for (let item of ret.data) {
            this.listData.push(parseInt(item.negativeO2))
            this.xAxisData.push(item.cityName)
          }
          this.initData()
        }
      })
    },
    initData() {
      let option = this.getOptions()
      if (this.$refs.RChartView) {
        if (option) {
          this.$refs.RChartView.initData(option)
        } else {
          this.$refs.RChartView.initData(option)
        }
      }
    },
    getOptions() {
      let opt = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          //调整图表上下左右位置
          top: 35,
          left: 10,
          right: 20,
          bottom: 0,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: this.xAxisData,
            axisLabel: {
              rotate: -30
              // fontSize: nowSize(16)
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '个',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true
            },
            splitLine: {},
            axisLabel: {
              formatter: '{value}'
              //fontSize: nowSize(16)
            }
          }
        ],
        series: [
          {
            type: 'bar',
            data: this.listData,
            label: {
              show: true,
              color: '#00bfbf',
              position: 'top'
            },
            lineStyle: {
              color: '#00bfbf'
            },
            itemStyle: {
              color: getLinearColor(['#6ddabd', 'rgba(109,218,189,0)'])
            },
            barWidth: 15
          }
        ]
      }
      return opt
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-chart-bar-oxy-city {
  .sub-title {
    text-align: center;
    color: #999;
    margin-bottom: 10px;
  }
  .m-chart-core {
    height: 200px;
  }
  @media screen and (max-width: 1200px) {
    .sub-title {
      font-size: 12px;
    }
  }
}
</style>
