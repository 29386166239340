import OMU from '@/plug/map/openLayer/utils.js'
import GLPoint from '@/plug/map/openLayer/WebGLPoints.js'
import { InfoMap_marker } from '@/info/infoMap.js'
export const MXmap = {
  data() {
    return {
      markerPoints: [],
      oxyMarkerPoints: [],
      filterMarkerPoints: [],
      filterViewPoints: []
    }
  },
  computed: {},
  watch: {
    'MapRoot.visibleYear': {
      handler() {
        this.setPoint()
      }
    },
    'PageRoot.brandType': {
      handler() {
        this.setPoint()
        this.initVisibleYear()
      }
    },
    'MapRoot.areaCode': {
      handler() {
        this.setPoint()
      }
    },
    'MapRoot.selectAreaCode': {
      handler() {
        this.setPoint()
      }
    },
    'PageRoot.pointView': {
      handler() {
        this.setPoint()
        this.initVisibleYear()
      }
    }
  },
  mounted() {},
  methods: {
    initMXMap() {
      this.initMXEvent()
      this.getData_marker()
    },
    getData_marker() {
      this.MapRoot.onLoading()
      Promise.all([InfoMap_marker()]).then((rets) => {
        console.log(rets)
        this.MapRoot.offLoading()
        let list = []
        if (rets[0]?.data?.length) {
          list = rets[0].data
        }
        this.oxyMarkerPoints = []
        /*for (let item of rets[1].data) {
          this.oxyMarkerPoints.push(item)
        }*/
        //list.push(...this.getBrandType5Point())
        this.markerPoints = list
        this.filterPoint()
        this.initVisibleYear()
        this.setPoint()
      })
    },
    getBrandType5Point() {
      //写死的避暑旅游地数据
      return [
        {
          createYear: 2018,
          productName: '新疆温泉县',
          brandType: 5,
          lat: 44.96882,
          lon: 81.02478,
          description:
            '温泉县三面环山，有瑰丽的河谷风光，壮丽的瀑布、以及茂盛的草原，泉水清幽，如诗如画'
        }
      ]
    },
    initVisibleYear() {
      let colors = [
        '#FF0094',
        '#5C7BD9',
        '#4BBE9E',
        '#FFB401',
        '#F68E42',
        '#23C4E0',
        '#71AB2C',
        '#c23531',
        '#2f4554',
        '#61a0a8',
        '#d48265',
        '#91c7ae',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3'
      ]
      let yearDict = {},
        i = 0
      for (let item of this.filterMarkerPoints) {
        if (item.brandType == this.PageRoot.brandType) {
          if (typeof yearDict[item.createYear] == 'undefined') {
            yearDict[item.createYear] = {
              key: item.createYear,
              name: item.createYear,
              color: colors[i++],
              data: []
            }
          }
        }
      }
      let list = []
      for (let item in yearDict) {
        list.push(yearDict[item])
      }
      this.MapRoot.legendDict = list
      this.MapRoot.visibleYear = []
      for (let item of this.MapRoot.legendDict) {
        this.MapRoot.visibleYear.push(item.key)
      }
    },
    /**
     * 把筛选出来的点用于组装成地图点格式数据
     * @returns points //返回所有dict[year]数据
     */
    getMarkerPoint() {
      let points = {}
      let markers = []
      let legendDict = this.MapRoot.legendDict
      for (let item of this.filterMarkerPoints) {
        if (item.brandType == this.PageRoot.brandType) {
          if (this.MapRoot.selectAreaCode == '100000') {
            markers.push(item)
          } else {
            if (
              this.MapRoot.areaLevel == 1 &&
              this.MapRoot.selectAreaCode == item.province
            ) {
              markers.push(item)
            } else if (
              this.MapRoot.areaLevel >= 2 &&
              this.MapRoot.selectAreaCode == item.city
            ) {
              markers.push(item)
            }
          }
        }
      }
      this.MapRoot.viewMarkerList = Object.freeze(markers) //当前地图上显示的所有点
      this.filterViewPoints = []
      for (let i in legendDict) {
        let markerType = legendDict[i].key
        for (let item of markers) {
          if (
            item.createYear == markerType &&
            this.MapRoot.visibleYear.includes(markerType)
          ) {
            if (typeof points[markerType] == 'undefined') {
              points[markerType] = { ...legendDict[i], data: [] }
            }
            points[markerType].data.push({ ...item, markerType: markerType })
            this.filterViewPoints.push({ ...item, markerType: markerType })
          }
        }
      }
      return points
    },
    getHasO2MarkerList() {
      let markers = []
      for (let item of this.oxyMarkerPoints) {
        if (item.brandType == this.PageRoot.brandType) {
          if (this.MapRoot.areaCode == '100000') {
            markers.push(item)
          } else {
            if (
              this.MapRoot.parentAreaCodes.length == 1 &&
              this.MapRoot.areaCode == item.province
            ) {
              markers.push(item)
            } else if (
              this.MapRoot.parentAreaCodes.length == 2 &&
              this.MapRoot.areaCode == item.city
            ) {
              markers.push(item)
            }
          }
        }
      }
      return markers
    },
    getHasO2MarkerPoint() {
      let points = {}
      let markers = this.getHasO2MarkerList()
      let legendDict = this.MapRoot.legendDict
      for (let i in legendDict) {
        let markerType = legendDict[i].key
        for (let item of markers) {
          if (
            item.createYear == markerType &&
            this.MapRoot.visibleYear.includes(markerType)
          ) {
            if (typeof points[markerType] == 'undefined') {
              points[markerType] = { ...legendDict[i], data: [] }
            }
            points[markerType].data.push({ ...item, markerType: markerType })
          }
        }
      }
      return points
    },
    /**
     * 一级筛选：筛选有负氧离子的点
     */
    filterPoint() {
      let allPoints = []
      if (this.PageRoot.brandType == 3 && this.PageRoot.pointView == 1) {
        allPoints = this.oxyMarkerPoints
      } else {
        allPoints = this.markerPoints
      }
      this.filterMarkerPoints = allPoints
    },
    setPoint() {
      //海量点组件
      this.filterPoint()
      let points
      if (this.PageRoot.brandType == 3 && this.PageRoot.pointView == 1) {
        points = this.getHasO2MarkerPoint()
      } else {
        points = this.getMarkerPoint()
      }
      let params = {
        mapPlug: this.MapRoot.mapPlug,
        points: points //对象-数组：点经纬度数组，必有字段：lon，lat
      }
      this.MapRoot.markerType = ''
      if (this.MapRoot.areaLevel > 1) {
        params.symbolImg = {
          symbolType: 'circle',
          size: 20, //['interpolate', ['exponential', 2.5], ['zoom'], 2, 2, 6, 25]
          offset: [0, 0]
        }
        this.showFeaturePopup(this.filterViewPoints[0])
      } else {
        params.symbolImg = {
          symbolType: 'circle',
          size: 11, //['interpolate', ['exponential', 2.5], ['zoom'], 2, 2, 6, 25]
          offset: [0, 0]
        }
      }
      if (this.MapRoot.mapPlug && this.markerPoints?.length) {
        if (this.PlugGLPoint) {
          this.PlugGLPoint.update(params)
        } else {
          this.PlugGLPoint = new GLPoint(params)
        }
      }
      this.setO2Point()
    },
    /**
     * 次级筛选：筛选有负氧离子的点出来
     * @returns
     */
    getHasO2DataPoint() {
      let points = {}
      let markers = this.getHasO2MarkerList()
      let legendDict = this.MapRoot.legendDict
      for (let i in legendDict) {
        let markerType = legendDict[i].key
        for (let item of markers) {
          if (
            item.createYear == markerType &&
            this.MapRoot.visibleYear.includes(markerType)
          ) {
            if (typeof points[markerType] == 'undefined') {
              points[markerType] = { ...legendDict[i], data: [] }
            }
            points[markerType].data.push({ ...item, markerType: markerType })
          }
        }
      }
      return points
    },
    /**
     * 在地图上加入：有负氧离子数据的点样式
     * @param {Array} points
     */
    setO2Point() {
      let o2Points = {}
      //仅负氧离子生效
      if (this.PageRoot.brandType == 3 && this.PageRoot.pointView == 1) {
        o2Points = this.getHasO2DataPoint()
      }
      let params = {
        mapPlug: this.MapRoot.mapPlug,
        points: o2Points, //对象-数组：点经纬度数组，必有字段：lon，lat
        symbolImg: {
          symbolType: 'circle',
          size: 20, //['interpolate', ['exponential', 2.5], ['zoom'], 2, 2, 6, 25]
          opacity: ['match', ['get', 'hover'], 1, 0.95, 0.2],
          color: [],
          rotateWithView: false,
          offset: [0, 0]
        }
      }
      if (this.MapRoot.mapPlug && this.markerPoints?.length) {
        if (this.PlugGLO2Point) {
          this.PlugGLO2Point.update(params)
        } else {
          this.PlugGLO2Point = new GLPoint(params)
        }
      }
    },
    /**
     *
     * @param {Array} pixel //当前点相对于当前地图视口的位置[300,400]
     * @param {Object} selected //feature 对象，可不传
     */
    onSelectFeature(pixel, selected) {
      let map = this.MapRoot.mapPlug.map
      map.forEachFeatureAtPixel(pixel, (feature) => {
        let prop = feature?.getProperties()
        if (selected) {
          selected = feature
          this.PlugGLPoint.setPointShape(selected, true)
        }
        let pointData = prop?.data
        this.showFeaturePopup(pointData)
        return true
      })
    },
    /**
     *
     * @param {Object} pointData //当前数据对象，必有字段：lon,lat
     */
    showFeaturePopup(pointData) {
      if (pointData) {
        this.MapRoot.clickPoint = pointData
        this.MapRoot.markerType = 'label'
      }
    },
    initMXEvent() {
      this.MapRoot.mapPlug.view?.on('propertychange', (e) => {
        if (e.key == 'resolution') {
          this.MapRoot.plottingScale = OMU.getMapScale(
            this.MapRoot.mapPlug.view
          )
        }
      })
      let map = this.MapRoot.mapPlug.map

      map?.on('pointermove', (e) => {
        this.MapRoot.mouseCoord = this.MapRoot.mapPlug.toLonLat(e.coordinate)
      })
      let selected = null
      map?.on('click', (e) => {
        if (selected !== null) {
          this.PlugGLPoint.setPointShape(selected, false)
          this.MapRoot.markerType = ''
          this.MapRoot.clickPoint = false
        }
        this.onSelectFeature(e.pixel, selected)
      })
      /*map.on('pointerdrag', () => {
        if (this.MapRoot.markerType == 'label') {
          this.MapRoot.markerType = ''
          this.MapRoot.clickPoint = false
        }
      })*/
    },
    onClickMapFeature() {}
  }
}
