<template>
  <div class="layer-filter-point">
    <div
      class="li"
      :class="PageRoot.pointView == index ? 'current' : ''"
      v-for="(item, index) in list"
      :key="item"
      @click="EventClick(index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  inject: ['PageRoot'],
  components: {},
  props: {},
  data() {
    return {
      list: [
        { name: '天然氧吧地区分布', value: 0 },
        { name: '已接入负(氧)离子数据氧吧地区', value: 1 }
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(index) {
      this.PageRoot.pointView = index
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-filter-point {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .li {
    padding: 9px 0;
    width: 270px;
    text-align: center;
    cursor: pointer;
    background: #e0f5f8;
    color: #333;
    font-size: 16px;
    &.current {
      background: #23c4e0;
      color: #fff;
    }
    &:first-child {
      border-top-left-radius: 30px;
    }
    &:last-child {
      border-bottom-right-radius: 30px;
    }
  }
}
</style>
