<template>
  <div class="area-plaque">
    <div class="column-container" v-loading="loading">
      <CpnTitle icon="tt-10">中国天然氧吧牌匾规格说明</CpnTitle>
      <div class="m-plaque">
        <KOimg
          class="tt-msg"
          :src="require('@/assets/img/icon/title-msg.png')"
        ></KOimg>
        <KOimg class="plaque-pic" :src="img"></KOimg>
        <div class="content">
          <div class="title">{{ title }}</div>
          <div class="cont" v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import { Info_brandPlaque } from '@/info/infoHome.js'
export default {
  inject: [],
  components: { CpnTitle },
  props: {},
  data() {
    return {
      title: '',
      content: '',
      img: '',
      loading: false
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      Info_brandPlaque({
        brandType: 3
      }).then((ret) => {
        this.loading = false
        if (ret?.list?.length) {
          this.title = ret?.list[0].title
          this.content = ret?.list[0].content
          let imgJson = this.Api.jsonData(ret?.list[0].img)
          this.img = this.Api.imgPath(imgJson[0].url)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.m-plaque {
  position: relative;
  background: #f6fbfe;
  display: flex;
  align-items: center;
  padding: 30px;
  .plaque-pic {
    width: 400px;
    margin-right: 30px;
    flex-shrink: 0;
  }
  .content {
    line-height: 1.8;
    flex-grow: 1;
    text-align: center;
    padding: 0 50px;
    .title {
      font-size: 26px;
      margin-bottom: 10px;
    }
    .cont {
      font-size: 18px;
      color: #666;
      text-align: justify;
    }
  }
  .tt-msg {
    position: absolute;
    top: -10px;
    right: 0;
    width: 150px;
  }
}
</style>
