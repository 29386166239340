import { KOAjax } from './koajax.js'
import Api from '@/api/Api'
import { Base64 } from 'js-base64'
import axios from 'axios'
import TypeIs from '@/api/TypeIs.js'
import { asyncOnce } from '@/api/utilsFn.js'
const oneKOAx = asyncOnce(KOAjax)
export function MKAjax(param) {
  param.load = 'newUrl'
  param.baseURL =
    ' http://112.126.94.55:7300/mock/62ce3c7307c7d611d802c874/scnongqi'
  return oneKOAx(param)
}
export function DefAjax(param) {
  if (process.env.NODE_ENV === 'development') {
    param.baseURL = '/ko'
  } else {
    param.load = 'newUrl'
    param.baseURL = Api.getHostPath()
  }
  return oneKOAx(param)
}

function StaticJson(param) {
  let paramText = Api.obj2para(param.params || param.data)
  let base64Param = Base64.encode(paramText)
  let baseURL = Api.getHostPath()
  if (process.env.NODE_ENV === 'development') {
    baseURL = '/ko'
  }
  let url = baseURL + 'staticApi/' + param.url + '-' + base64Param + '.json'
  return axios.get(url)
}

let test = false
export function JsonAjax(param) {
  return new Promise((res) => {
    if (test) {
      StaticJson(param)
        .then((ret) => {
          if (ret?.data) {
            res(ret.data)
          } else {
            res(ret)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      DefAjax(param)
        .then((ret) => {
          res(ret)
        })
        .catch(() => {
          StaticJson(param)
            .then((ret) => {
              if (ret?.data) {
                if (TypeIs.Array(ret.data)) {
                  res(ret)
                } else {
                  res(ret.data)
                }
              } else {
                res(ret)
              }
            })
            .catch((err) => {
              console.error(err)
            })
        })
    }
  })
}
