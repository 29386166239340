import _ from 'lodash'
import VectorSource from 'ol/source/Vector'
import { Group as LayerGroup } from 'ol/layer'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { colorBrighten, colorToArr } from '@/api/func/colorRibbon.js'
/**
 * @param {
 * mapPlug:地图组件对象
 * points:点对象集合 cityStation: {color: '#FF4B4B',data: []},color:点颜色，data:点经纬度数据集
 * }
 */
class KOClass {
  selectValue = 'circles-zoom'
  pointObj = {}
  colorRam = [
    '#32B16C',
    '#6D9BD9',
    '#BCDC7D',
    '#FFB401',
    '#F68E42',
    '#23C4E0',
    '#71AB2C',
    '#c23531',
    '#2f4554',
    '#61a0a8',
    '#d48265',
    '#91c7ae',
    '#749f83',
    '#ca8622',
    '#bda29a',
    '#6e7074',
    '#546570',
    '#c4ccd3'
  ]
  symbolImg = {
    symbolType: 'circle',
    size: 11, //['interpolate', ['exponential', 2.5], ['zoom'], 2, 2, 6, 25]
    opacity: ['match', ['get', 'hover'], 1, 0.95, 0.7],
    color: [],
    rotateWithView: false,
    offset: [0, 0]
  }
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    if (this.mapPlug?.map) {
      this.pointObj = this.getPointObj()
      this.addLayer()
    }
  }
  getPointObj() {
    let pointObj = {}
    for (let key in this.points) {
      let source = this.getVectorSource(key)
      pointObj[key] = {
        ...this.points[key],
        source: source,
        layer: this.getLayer(source)
      }
    }
    return pointObj
  }
  getFeatures(points) {
    let features = []
    for (let item of points) {
      if (item.lon && item.lat) {
        let feature = new Feature({
          geometry: new Point(this.mapPlug.fromLonLat([item.lon, item.lat])),
          markerType: 'shape' + item.markerType,
          shape: 'shape' + item.markerType,
          color: item.color,
          data: item
        })
        features.push(feature)
      }
    }
    return features
  }
  getVectorSource(key) {
    //获取指定marker类型的源
    return new VectorSource({
      features: this.getFeatures(this.points[key].data)
    })
  }
  getLayer(source) {
    const style = {
      ...this.symbolImg,
      color: this.getSymbolColor()
    }
    return new WebGLPointsLayer({
      source: source,
      style: { symbol: style },
      opacity: 0.7,
      zIndex: 60
    })
  }
  addLayer() {
    let groupLayer = []
    for (let i in this.pointObj) {
      groupLayer.push(this.pointObj[i].layer)
    }
    if (this.layerGroup) {
      this.remove(this.layerGroup)
    }
    this.layerGroup = new LayerGroup({
      layers: groupLayer,
      zIndex: 59
    })
    this.mapPlug.map?.addLayer(this.layerGroup)
    setTimeout(() => {
      this.mapPlug.map.render()
    }, 1000)
  }
  update(config) {
    this.points = config.points
    if (config.symbolImg) {
      this.symbolImg = config.symbolImg
    }
    this.pointObj = this.getPointObj()
    this.addLayer()
  }
  remove(layerGroup) {
    if (layerGroup) {
      let layers = layerGroup.getLayers()
      if (layers) {
        layers.clear()
      }
    }
  }
  getSymbolColor() {
    //获取默认、选中图标的颜色配置(数组)
    let colors = ['match', ['get', 'shape']]
    for (let i in this.points) {
      let colorArr = colorToArr(this.points[i].color)
      let newColorArr = colorBrighten(colorArr)
      colors.push('shape' + i)
      colors.push(colorArr)
      colors.push('shape' + i + '-hover')
      colors.push(newColorArr)
    }
    colors.push([0, 255, 255])
    return colors
  }
  setPointShape(feature, bool) {
    if (feature) {
      let prop = feature?.getProperties()
      if (bool) {
        feature.set('shape', prop.markerType + '-hover')
        feature.set('hover', 1)
      } else {
        feature.set('shape', prop.markerType)
        feature.set('hover', 0)
        feature = null
      }
    }
  }
}

export default KOClass
/*
symbolImg = {
    symbolType: 'image',
    src: require('@/assets/img/icon/shape.png'),
    size: ['interpolate', ['exponential', 2.5], ['zoom'], 2, 1, 6, 25],
    opacity: ['match', ['get', 'hover'], 1, 0.95, 0.7],
    color: [
      'match',
      ['get', 'shape'],
      'cityStation',
      [255, 160, 110],
      'areaStation',
      [180, 255, 200],
      'circle',
      [180, 255, 200],
      'disc',
      [180, 255, 200],
      'oval',
      [180, 255, 200],
      'triangle',
      [180, 255, 200],
      'fireball',
      [180, 255, 200],
      [180, 255, 200]
    ],
    rotateWithView: false,
    offset: [0, 0],
    textureCoord: [
      'match',
      ['get', 'shape'],
      'cityStation',
      [0.25, 0, 0.5, 0.5], //[x,y,w,h]
      /*'areaStation',
      [0.25, 0, 0.5, 0.5],
      'circle',
      [0.25, 0, 0.5, 0.5],
      'disc',
      [0.5, 0, 0.75, 0.5],
      'oval',
      [0.5, 0, 0.75, 0.5],
      'triangle',
      [0.75, 0, 1, 0.5],
      'fireball',
      [0, 0.5, 0.25, 1],
      [0.25, 0, 0.5, 0.5] //未找到的情况下展示的图标位置
    ]
  }
*/
