export function getYears(list) {
  let years = [],
    dict = {}
  if (list?.length) {
    for (let item of list) {
      let year = item.dateTime?.Format('YYYY')
      if (typeof dict[year] == 'undefined') {
        dict[year] = true
        years.push(year)
      }
    }
  }
  return years
}

export function getDomHeight(w) {
  let maxw = 800
  if (w) {
    maxw = w
  }
  return window.innerHeight * 0.8 > maxw ? maxw : window.innerHeight * 0.8
}

export function getPostDataByAreaCode(areaCode) {
  let postData = {}
  if (areaCode == 100000) {
    postData = {}
  } else if (areaCode.toString().substr(2, 4) == '0000') {
    postData = { province: areaCode, city: '' }
  } else if (areaCode.toString().substr(4, 2) == '00') {
    postData = { province: '', city: areaCode }
  } else {
    postData = { province: '', city: '', region: areaCode }
  }
  return postData
}

export function getCityTypeByAreaCode(areaCode) {
  if (areaCode == 100000) {
    return 0
  } else if (areaCode.toString().substr(2, 4) == '0000') {
    return 1
  } else if (areaCode.toString().substr(4, 2) == '00') {
    return 2
  } else {
    return 3
  }
}
