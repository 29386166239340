// service统一出口
import router from '@/router'
import KORequest from './request'
import { BASE_URL, MOCK_URL, TIME_OUT } from './request/config'

import cookieCache from '@/utils/cookie.js'
let interceptors = {
  requestInterceptor: (config) => {
    // 携带token的拦截
    const token = cookieCache.get('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  requestInterceptorCatch: (err) => {
    //console.log('请求失败的拦截')
    return err
  },
  responseInterceptor: (res) => {
    //console.log('响应成功的拦截', res)
    return res
  },
  responseInterceptorCatch: (err) => {
    if (err?.response?.data?.code == 401 || err?.response?.status == 401) {
      cookieCache.delete('token')
      router.push('login')
      let msg = err?.response?.msg || err?.response?.data.msg
      return { msg: msg ?? '请先登录' }
    }
    return err.response
  }
}
let reqObj = {
  baseURL: BASE_URL,
  timeout: TIME_OUT
}

const KOPost = (ret) => {
  let KOReq
  if (ret.load == 'mock') {
    KOReq = new KORequest({
      timeout: TIME_OUT,
      baseURL: MOCK_URL,
      interceptors: interceptors
    })
  } else if (ret.load == 'newUrl') {
    KOReq = new KORequest({
      timeout: TIME_OUT,
      baseURL: ret.baseURL,
      interceptors: ret?.ic ?? interceptors
    })
  } else {
    KOReq = new KORequest({ ...reqObj, interceptors: interceptors })
  }
  return KOReq.request(ret)
}
export default KOPost
