<template>
  <div class="cpn-tab-swiper-a">
    <Swiper :slides-per-view="per" @swiper="initSwiper">
      <SwiperSlide
        v-for="(item, ind) in list"
        :class="ind == index ? 'current' : ''"
        :key="item"
        @click="EventClick(item, ind)"
      >
        <div class="box">
          <div class="name">{{ item[nameKey] }}</div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import DictLive from '@/api/dict/DictLive.js'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  log: '',
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    index: {
      type: [String, Number],
      default: 0
    },
    nameKey: {
      type: String,
      default: 'provinceName'
    }
  },
  data() {
    return {
      per: 'auto'
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    EventClick(item, index) {
      this.$emit('update:index', index)
      this.$emit('select', item)
    },
    getIcon(item) {
      return DictLive.live(item.name)
    },
    initSwiper(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-tab-swiper-a {
  ::v-deep(.swiper) {
    .swiper-slide {
      cursor: pointer;
      width: auto;
      .name {
        padding: 0 15px;
        background: #e5f5f5;
        color: #666;
        text-align: center;
        margin-right: 10px;
        border-radius: 20px;
        border: 1px solid #fff;
        box-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .current {
      .name {
        color: #33c3cc;
        font-weight: bold;
        border: 1px solid #abe6e3;
      }
    }
  }
}
</style>
