<template log="homeMap-包组件">
  <div class="cpn-map-wrap">
    <div class="cpn-map-oxy cpn-map-inner" :class="'cpn-map-oxy-' + mapId">
      <div class="top-bar">
        <MapAreaRadioButton></MapAreaRadioButton>
      </div>
      <div
        :id="mapId"
        class="map-container"
        v-loading="mapLoadingArr?.length"
        :element-loading-text="mapLoadingText"
        element-loading-background="rgba(255, 255, 255, 0.5)"
      ></div>
      <!--基础-->
      <CNMapInit></CNMapInit>
      <CNMapZoomButton></CNMapZoomButton>
      <!--功能-->
      <CNMapAdminDivi
        v-if="MapRoot.mapPlug && MapRoot.areaCode"
        log="地图组件-轮廓多边形"
      ></CNMapAdminDivi>
      <!--图层-->
      <MarkerPopup></MarkerPopup>
      <MarkerLegend></MarkerLegend>
      <MapEXNum log="审图号"></MapEXNum>
      <!--其他图层-->
      <CNMapOxyDatas v-if="MapRoot.mapPlug"></CNMapOxyDatas>
    </div>
    <OtherDataView class="m-layer-right"></OtherDataView>
  </div>
</template>
<script>
import initMap from '@/plug/map/openLayer/initMap.js'
//import MapBaseImg from '@/plug/map/openLayer/MapBaseImg.js'
/*base*/
import CNMapInit from '@/components/content/map/base/CNMapInit.vue'
import CNMapZoomButton from '@/components/content/map/base/CNMapZoomButton.vue'
/*cpn*/
import CNMapAdminDivi from '@/components/content/map/cpn/CNMapAdminDivi.vue'
/*lay*/
import MapEXNum from '@/components/common/map/layer/MapEXNum.vue'
import MarkerPopup from './layer/MarkerPopup.vue'
import MarkerLegend from './layer/MarkerLegend.vue'
import CNMapOxyDatas from './cpn/CNMapOxyDatas.vue'

import OtherDataView from './layer/OtherDataView.vue'
import MapAreaRadioButton from './topbar/MapAreaRadioButton'
//import { nowSize } from '@/plug/chart/chartFn.js'

export default {
  inject: ['PageRoot'],
  components: {
    CNMapInit,
    CNMapZoomButton,

    CNMapAdminDivi,
    MapEXNum,
    MarkerPopup,
    MarkerLegend,

    OtherDataView,
    MapAreaRadioButton,
    CNMapOxyDatas
  },
  props: {
    mapId: {
      type: String,
      default: 'map-main'
    }
  },
  provide: function () {
    return {
      MapRoot: this.MapRoot
    }
  },
  data() {
    return {
      mapLoadingArr: [],
      mapLoadingText: '地图加载中',
      MapRoot: {
        //地图共享数据集，子组件通过inject获取
        mapPlug: false, //地图组件
        areaCode: '230000', //当前区域的编码
        areaName: '黑龙江省',
        areaDatas: [], //负氧离子区域数组
        parentAreaCodes: [],
        parentAreaNames: [],
        adminDiviJson: {
          inner: {},
          outer: {}
        },
        pointView: 0, //氧吧点的数据类别：全部、只有负氧离子的
        plottingScale: '', //地图分辨率
        mouseCoord: '', //鼠标移动的经纬度
        clickPoint: false, //海量点组件-当前点击的点
        mapViewType: '', // 地图组件预览类型：1.label
        visibleYear: [], //显示的年份marker
        legendList: [], //点的图例字典
        currentOverlay: false,
        //方法
        onLoading: this.onLoading,
        offLoading: this.offLoading
      },
      coordinate: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let config = {
        id: this.mapId,
        ...this.Api.getMapConfig(),
        center: [103.5, 34],
        zoom: 4.35
      }
      let mapPlug = new initMap(config)
      /*mapPlug.mapBaseImg = new MapBaseImg({
        mapPlug: mapPlug,
        opacity: 1
      })*/

      this.MapRoot.mapPlug = mapPlug
    },
    onLoading(text) {
      this.mapLoadingArr.push(text)
      this.mapLoadingText = text
    },
    offLoading() {
      this.mapLoadingArr.splice(0, 1)
      this.mapLoadingText = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-wrap {
  display: flex;
}
.cpn-map-inner {
  position: relative;
  height: 690px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .top-bar {
    flex-shrink: 0;
  }
  //background: url('~@/assets/img/home/map-bg.jpg') center/cover no-repeat;
  .map-container {
    height: 10px;
    flex-grow: 1;
    ::v-deep(.ol-viewport) {
      position: static !important;
    }
  }
  .m-mapline-9 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    border: 1px solid #38cae3;
  }
}
</style>
