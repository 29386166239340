import html2canvas from 'html2canvas'
import Api from '@/api/Api.js'

export function saveImg(Dom, fileName) {
  getImg(Dom)
    .then((dataURL) => {
      downloadImage(dataURL, fileName)
    })
    .catch((ret) => {
      console.error(ret)
      Api.msg(ret)
    })
}

/*
export function getGif(imgs, callback) {
  gifshot.createGIF(
    {
      gifWidth: 1800,
      gifHeight: 1806,
      images: imgs,
      interval: 1,
      numFrames: 10,
      frameDuration: 1,
      fontWeight: 'normal',
      fontSize: '16px',
      fontFamily: 'sans-serif',
      fontColor: '#ffffff',
      textAlign: 'center',
      textBaseline: 'bottom',
      sampleInterval: 10,
      numWorkers: 2
    },
    (obj) => {
      callback(obj)
    }
  )
}*/
export function downloadImage(href, fileName) {
  // 如果是在网页中可以直接创建一个 a 标签直接下载
  let _a = document.createElement('a')
  let name = fileName ?? '图片'
  _a.setAttribute('href', href)
  _a.setAttribute('target', '_blank')
  _a.setAttribute('download', name + '.jpg')
  document.body.appendChild(_a)
  _a.click()
}

export function getImgs(Doms) {
  let pms = []
  for (let i = 0; i < Doms.length; i++) {
    pms.push(getImg(Doms[i]))
  }
  return Promise.all(pms)
}
export function getImg(Dom) {
  // 手动创建一个 canvas 标签
  const canvas = document.createElement('canvas')
  // 获取父标签，意思是这个标签内的 DOM 元素生成图片
  // lessonTableImg是给截图范围内的父级元素自定义的ref名称
  // 获取父级的宽高
  const width = parseInt(window.getComputedStyle(Dom).width)
  const height = parseInt(window.getComputedStyle(Dom).height)
  // 判断屏幕的 DPI
  const dpi = window.devicePixelRatio || 1 // 获取设备像素比，默认值为 1
  const scaleNumber = 2 //放大倍数、放大 2 倍 是为了防止图片模糊
  canvas.width = width * dpi * scaleNumber
  canvas.height = height * dpi * scaleNumber
  canvas.style.width = width + 'px'
  canvas.style.height = height + 'px'
  const context = canvas.getContext('2d')
  context.scale(scaleNumber, scaleNumber)
  const options = {
    backgroundColor: '#FFFFFF', //设置canvas背景图颜色 防止有些图片透明
    canvas: canvas,
    useCORS: true
  }
  return new Promise((resolve) => {
    html2canvas(Dom, options)
      .then((canvas) => {
        // toDataURL 图片格式转成 base64
        let dataURL = canvas.toDataURL('image/jpeg')
        //调用下载
        // this.downloadImage(dataURL);
        // 转为file文件
        resolve(dataURL)
      })
      .catch((err) => {
        resolve('')
        console.log(err)
      })
  })
}
