<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-04-26 10:37:24
-->
<template>
  <div class="child-search-brand">
    <div class="input-wrap">
      <el-input
        class="m-input"
        v-model="searchPinpai"
        @keyup.enter="EventSearch"
        clearable
        placeholder="请输入查询的地区(景点)名称"
      />
    </div>
    <slot></slot>
    <UiButton
      class="m-button"
      type="primary"
      @keyup.enter="EventSearch"
      @click="EventSearch"
      >查询</UiButton
    >
    <UiButton v-if="list?.length" class="m-button" @click="EventSave"
      >下载</UiButton
    >
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    list: {
      type: [Array, Boolean],
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      searchPinpai: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSearch() {
      this.$emit('search', this.searchPinpai)
    },
    EventSave() {
      this.$emit('save')
    }
  }
}
</script>
<style lang="scss" scoped>
.child-search-brand {
  display: flex;
  margin-bottom: 10px;
  .input-wrap {
    width: 300px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .m-input {
    width: 100%;
  }
  .m-button {
    flex-shrink: 0;
    margin-left: 10px;
  }
}
</style>
