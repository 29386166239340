<template>
  <div class="cpn-title-B">
    <div class="name">{{ name }}</div>
    <div class="other"><slot></slot></div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-title-B {
  display: flex;
  line-height: 30px;
  margin-bottom: 20px;
  .name {
    position: relative;
    padding-left: 10px;
    margin-right: 40px;
    color: #33c3cc;
    font-weight: bold;
    font-size: 18px;
    &:before {
      position: absolute;
      left: 0;
      top: 5px;
      bottom: 5px;
      content: '';
      display: block;
      border-left: 5px solid #33c3cc;
    }
  }
  .other {
    flex-grow: 1;
  }
}
</style>
