/**
 * @数据扁平化_传入一个区域树_返回扁平化的区域数组
 * ret.list:数据列表
 * ret.areaNameKey
 * ret.areaCodeKey
 */
export function DataFlat(ret) {
  let resArr = [],
    dict = {}
  const areaNameKey = ret.areaNameKey ?? 'areaName'
  const areaCodeKey = ret.areaCodeKey ?? 'areaCode'
  const fn = (arr, parent) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].children) && arr[i].children?.length) {
        if (parent) {
          arr[i].parent = {
            areaName: parent[areaNameKey],
            areaCode: parent[areaCodeKey],
            parent: parent?.parent
          }
        }
        fn(arr[i].children, arr[i])
      }
      resArr.push({
        areaCode: arr[i][areaCodeKey],
        areaName: arr[i][areaNameKey],
        parent: parent
      })
      dict[arr[i][areaCodeKey]] = arr[i][areaNameKey]
    }
  }
  fn(ret.list)
  return { arr: resArr, dict: dict }
}

/**
 * @param {any[]} data
 * @param {{id: string, pId: string}} config
 */
export const Arr2Tree = (data, config) => {
  const { id = 'id', pId = 'pid' } = config || {}

  const ids = data.map((_) => _[id])
  const result = []
  const waitChecked = []
  data.forEach((_) => (ids.includes(_[pId]) ? waitChecked : result).push(_))
  const flatData = [...result]

  while (waitChecked.length) {
    const flatIds = flatData.map((_) => _[id])
    const node = waitChecked.find((_) => flatIds.includes(_[pId]))
    const parNode = flatData.find((_) => _[id] === node[pId])
    waitChecked.splice(waitChecked.indexOf(node), 1)
    if (parNode.children) {
      parNode.children.push(node)
    } else {
      parNode.children = [node]
    }
    flatData.push(node)
  }

  return result
}
