/**
 * 获取从开始年份到结束年份的所有年份数组
 * @param {number} startYear - 开始年份
 * @param {number} [endYear=new Date().getFullYear()] - 结束年份，默认为当前年份
 * @returns {number[]} - 包含所有年份的数组
 */
export function getYears(startYear, endYear = new Date().getFullYear()) {
  const yearsArray = [] // 初始化年份数组

  // 从开始年份循环到结束年份
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year) // 将每个年份添加到数组中
  }

  return yearsArray // 返回年份数组
}
