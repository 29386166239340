<template>
  <ul class="cbox-news cbox-zhengce list" v-loading="loading">
    <li v-for="item in list" :key="item" @click="EventRead(item)">
      <CpnItemNews icon="zhengce" :title="item.name">{{
        item.name
      }}</CpnItemNews>
    </li>
  </ul>
</template>
<script>
import CpnItemNews from '@/components/view/listItem/CpnItemNews.vue'
import { IDict_channelType } from '@/info/infoDict.js'
import { Info_zcList } from '@/info/infoHome.js'
export default {
  inject: [],
  components: { CpnItemNews },
  props: {
    pageSize: {
      type: [Number, String],
      default: 2
    },
    brandType: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      tabs: [],
      tabIndex: 0,
      list: []
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initTabs()
  },
  methods: {
    initTabs() {
      IDict_channelType().then((ret) => {
        this.tabs = ret.data
        this.initData()
      })
    },
    initData() {
      this.loading = true
      Info_zcList({
        pageNum: 1,
        pageSize: this.pageSize,
        type: 3,
        sortField: 'isRecommend DESC,publishTime DESC',
        brandType: this.brandType
      }).then((ret) => {
        this.loading = false
        if (ret?.list?.length) {
          this.list = ret.list
        }
      })
    },
    EventSelect() {
      this.initData()
    },
    EventRead(item) {
      window.open(this.Api.pdfRead() + item.uri)
    }
  }
}
</script>
<style lang="scss" scoped>
.cbox-news {
  padding: 22px 20px;
  background: #f6fcfd;
  li {
    margin-bottom: 15px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
