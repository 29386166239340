<!--地图轮廓绘制-功能组件-->
<template>
  <div></div>
</template>
<script>
import {
  setMapInnerFeatures,
  setMapOuterFeatures,
  setFeatureLabel
} from '@/plug/map/openLayer/polygon/DrawMapFeatures.js'
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {
    geoJsonInner: {
      type: Object,
      default: () => {}
    },
    geoJsonOuter: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  watch: {
    geoJsonInner: {
      handler() {
        this.setMapInnerFeatures()
      }
    },
    geoJsonOuter: {
      handler() {
        this.setMapOuterFeatures()
      }
    }
  },
  computed: {
    outerFeaturesStyle() {
      return {
        fill: 'rgba(210, 227, 243,' + this.MapRoot.featureOpacity * 0.01 + ')',
        stroke: 'rgba(22, 127, 230, 1)',
        border: 3
      }
    }
  },
  created() {},
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
      this.setMapInnerFeatures()
      this.setMapOuterFeatures()
    },
    setMapInnerFeatures() {
      if (this.MapRoot.mapPlug) {
        if (this.MapRoot.areaLevel == 2) {
          setMapInnerFeatures(
            this.MapRoot.mapPlug,
            this.geoJsonInner,
            'greyDef'
          )
        } else {
          setMapInnerFeatures(this.MapRoot.mapPlug, this.geoJsonInner)
        }
        setFeatureLabel(this.MapRoot.mapPlug, this.geoJsonInner) //绘制feature标签
        /*this.MapRoot.mapPlug.FeatureInnerPlug.selectHover.on(
          'select',
          (feature) => {
            let areaCode = feature.selected[0]?.data?.adcode ?? ''
            this.setCurrentAreaYear(areaCode)
          }
        )*/
      }
    },
    setMapOuterFeatures() {
      let mapPlug = this.MapRoot.mapPlug
      if (mapPlug) {
        setMapOuterFeatures(mapPlug, this.geoJsonOuter)
        //显示当前轮廓区域,并设置中心点
        let opt = {
          geoJson: this.geoJsonOuter,
          padding: [0, 40, 0, 80]
        }
        if (this.MapRoot.areaCode == '100000') {
          opt.padding = [0, 10, -220, 10]
        }
        mapPlug.viewFeature(opt)
        mapPlug.resizeZoom = () => {
          mapPlug.viewFeature(opt)
        }
      }
    },
    setMapFeaturesStyle() {
      this.MapRoot.mapPlug.FeatureOuterPlug?.setFeatureStyle(
        this.outerFeaturesStyle
      )
    },
    //业务-设置当前选择区域的年份
    setCurrentAreaYear(areaCode) {
      let yearArr = []
      if (areaCode) {
        let yearArrSet = new Set(yearArr)
        if (this.MapRoot.viewMarkerList?.length) {
          for (let item of this.MapRoot.viewMarkerList) {
            if (
              item.region == areaCode ||
              item.province == areaCode ||
              item.city == areaCode
            ) {
              yearArrSet.add(item.createYear)
            }
          }
          yearArr = Array.from(yearArrSet)
        }
      }
      this.MapRoot.selectAreaYear = yearArr
    }
  }
}
</script>
<style lang="scss"></style>
