import { ref } from 'vue'
import FeatureHoverInteraction from '@/plug/map/openLayer/interaction/FeatureHoverInteraction.js'
import { AdminMultLinePlug, changeAreaCode } from './MapPlugAdminPolygon.js'
import { transformCoordinates } from '@/plug/map/openLayer/utils/utilsFn.js'
import { KOMap } from './initMap.js'
import { clickPointPopup } from '../layer/mapPopup/js/mapPointPopup'

export const MapInteraction = ref(null)
export function initMapInteraction() {
  MapInteraction.value = new FeatureHoverInteraction({
    map: KOMap.value,
    hoverFeatureTypes: ['admin-polygon', 'webGLPoint'],
    clickFeatureTypes: ['admin-polygon', 'webGLPoint', 'label-drawer'],
    EventSelect: (features) => {
      if (features?.length) {
        for (let item of features) {
          let prop = item.getProperties()
          if (prop.featureType == 'admin-polygon') {
            viewCurrentAreaPopup(prop)
            break
          } else if (prop.featureType == 'webGLPoint') {
            viewCurrentPointPopup(prop.data)
            break
          }
        }
      } else {
        showAllAreaPopup()
      }
    },
    EventClick: (features) => {
      hidePointPopup()
      if (features?.length) {
        for (let item of features) {
          let prop = item.getProperties()
          if (prop.featureType == 'webGLPoint') {
            viewCurrentPointPopup(prop.data)
            break
          }
          if (prop.featureType == 'admin-polygon') {
            changeAreaCode(prop.adcode, prop.name)
            break
          }
          if (prop.featureType == 'label-drawer') {
            changeAreaCode(prop.labelData.areaCode, prop.labelData.text)
            break
          }
        }
      }
    }
  })
}

export function viewCurrentPointPopup(data) {
  const coordinate = [parseFloat(data.lon), parseFloat(data.lat)]
  const pixel = KOMap.value.getPixelFromCoordinate(
    transformCoordinates(KOMap.value, coordinate)
  )
  clickPointPopup.value = {
    ...data,
    left: pixel[0],
    top: pixel[1] - 10
  }
}
function hidePointPopup() {
  clickPointPopup.value = null
}

function viewCurrentAreaPopup(feature) {
  for (let i in AdminMultLinePlug.value) {
    let selectPlug = AdminMultLinePlug.value[i]
    if (feature?.adcode == i) {
      selectPlug.line?.fadeIn()
      selectPlug.label?.fadeIn()
    } else {
      selectPlug.line?.fadeOut()
      selectPlug.label?.fadeOut()
    }
  }
}

function showAllAreaPopup() {
  for (let i in AdminMultLinePlug.value) {
    let selectPlug = AdminMultLinePlug.value[i]
    selectPlug.line?.fadeIn()
    selectPlug.label?.fadeIn()
  }
}

export function destroyMapInteraction() {
  MapInteraction.value?.destroy()
  MapInteraction.value = null
}
