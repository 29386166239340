<template>
  <div class="cpn-product-state">
    <ul class="m-pro-state">
      <li
        class="li-1"
        :class="modelValue == 0 ? 'current' : ''"
        @click="EventSelect(0)"
      >
        <div class="box">
          <div class="row-1">
            <div class="icon">
              <i class="icon-cb yangba"></i>
            </div>
          </div>
          <div class="row-2">
            <div class="num">{{ baseCount }}</div>
            <div class="name">{{ MapRoot.areaName }}现有总数</div>
          </div>
        </div>
      </li>
      <li
        v-if="['4', '5'].includes(PageRoot.brandType) ? false : true"
        class="li-2"
        :class="modelValue == 1 ? 'current' : ''"
        @click="EventSelect(1)"
      >
        <div class="box">
          <div class="row-1">
            <div class="icon">
              <i class="icon-cb zaixianshenqing"></i>
            </div>
          </div>
          <div class="row-2">
            <div class="num">{{ productCount }}</div>
            <div class="name" v-if="stateType == 'default'">今年申报总数</div>
            <div class="name" v-if="stateType == 'oxy-1'">接入数据总数</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  inject: ['PageRoot', 'MapRoot'],
  components: {},
  props: {
    stateType: {
      type: String,
      default: ''
    },
    baseCount: {
      type: [String, Number],
      default: 0
    },
    productCount: {
      type: [String, Number],
      default: 0
    },
    modelValue: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSelect(index) {
      this.$emit('update:modelValue', index)
      this.$emit('select', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-product-state {
  .m-pro-state {
    display: flex;
    padding: 0 20px;
    li {
      flex-basis: 50%;
      cursor: pointer;
      .box {
        display: flex;
        align-items: center;
        .row-1 {
          flex-shrink: 0;
          margin-right: 8px;
          .icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;

            background: #cff2e4;
            color: #4cbe9c;
            border-radius: 50%;
          }
        }
        .row-2 {
          .num {
            color: #4cbe9c;
            font-size: 20px;
          }
          .name {
            font-size: 16px;
          }
        }
      }
      &.current {
        .box {
          .row-1 {
            .icon {
              background: #d4f9ff;
              color: #23c4e0;
            }
          }
          .row-2 {
            .num {
              color: #23c4e0;
            }
          }
        }
      }
    }
  }
}
</style>
