/*
 * @Author: koyoyoo
 * @Date: 2023-08-03 10:36:09
 * @LastEditors: koyoyoo
 * @LastEditTime: 2023-10-13 10:23:12
 */
import { color2rgba, color2hex, color2arr } from './colorFormat'

//纠正hex颜色，把3位的纠正为6位
export function modifyHex(hex) {
  if (hex) {
    if (hex.length == 4) {
      hex = hex.replace('#', '')
    }
    if (hex.length == 3) {
      return '#' + hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    } else {
      return hex
    }
  } else {
    return hex
  }
}

//纠正hex颜色，把3位的纠正为6位，但是去掉#号
export function reModifyHex(hex) {
  if (hex) {
    if (hex.length == 4) {
      hex = hex.replace('#', '')
    }
    if (hex.length == 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    return hex
  } else {
    return hex
  }
}

export function pad(n) {
  var str = '' + n
  var pad = '00'
  return pad.substring(0, pad.length - str.length) + str
}

//['#F09','#FFF'] 颜色数组转换成css渐变色的style
export function colors2css(colors, deg = 180) {
  let colorStr = ''
  let leng = colors.length
  for (let i = 0; i < leng; i++) {
    let colorVal = ''
    if (colors[i]) {
      colorVal = colors[i]
    } else {
      colorVal = 'transparent'
    }
    colorStr += colorVal + ' ' + (i / (leng - 1)) * 100 + '%'
    if (i < leng - 1) {
      colorStr += ', '
    }
  }
  let bgStyle = 'linear-gradient(' + deg + 'deg,' + colorStr + ')'
  return { 'background-image': bgStyle }
}

/**
 * @description: 调整颜色更亮更暗
 * @param {string} color 六位十六禁止颜色
 * @param {number} range 正负数决定颜色更改
 * @return {string} new color
 */
export function colorBrighten(ret, range = 1) {
  let color = color2hex(ret)
  let newColor = '#'
  for (let i = 0; i < 3; i++) {
    const hxStr = color.substring(i * 2 + 1, i * 2 + 3)
    let val = parseInt(hxStr, 16)
    val += range
    if (val < 0) val = 0
    else if (val > 255) val = 255
    newColor += val.toString(16).padStart(2, '0')
  }
  return newColor
}

/**
 * 设置 RGBA 颜色的透明度。
 * @param {string} rgbaColor - 原始的 RGBA 颜色字符串，格式为 'rgba(r, g, b, a)'。
 * @param {number} alpha - 新的透明度值，范围在 0 到 1 之间。
 * @returns {string} - 带有新透明度的 RGBA 颜色字符串。
 */
export function setRgbaAlpha(color, alpha = 1) {
  // 解析传入的 RGBA 颜色字符串
  if (color) {
    const rgbaColor = color2rgba(color)
    const rgbaArray = rgbaColor.match(/[\d.]+/g)
    // 确保传入的颜色字符串格式正确
    if (rgbaArray.length < 3) {
      throw new Error('无效的RGBA颜色格式')
    }
    // 提取颜色通道值
    const [r, g, b] = rgbaArray // 去掉了 _ 变量
    // 合成新的 RGBA 颜色字符串
    const newRgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`
    return newRgbaColor
  } else {
    console.log('未传入color')
  }
}

/**
 * 获取十六进制颜色对应的描边颜色。
 * @param {string} hexColor - 十六进制颜色值，如 '#FF0000'。
 * @returns {string} - 对应的描边颜色，如 'black' 或 'white'。
 */
export function getBorderColor(color) {
  // 将十六进制颜色转换为 RGB
  const arrColor = color2arr(color)
  const r = arrColor[0]
  const g = arrColor[1]
  const b = arrColor[2]
  // 计算亮度
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  // 判断颜色明暗并返回相应的描边颜色
  return brightness > 128 ? 'black' : 'white'
}
