<template>
  <div class="cpn-marker-popup" ref="RMarkerPopup">
    <div
      class="inner-box"
      v-if="markerPopup && MapRoot.markerType == 'label'"
      :class="getClass()"
    >
      <MPopupClickMarker :data="markerPopup"></MPopupClickMarker>
      <div class="m-close iconfont icon-31guanbi" @click="EventClose"></div>
    </div>
  </div>
</template>
<script>
import MPopupClickMarker from './markerPopup/MPopupClickMarker.vue'
import { Overlay } from 'ol'
export default {
  log: '',
  inject: ['MapRoot'],
  components: {
    MPopupClickMarker
  },
  props: {},
  data() {
    return {
      popup: false
    }
  },
  watch: {
    markerType: {
      handler() {
        this.setPosition()
      }
    },
    markerPopup: {
      handler() {
        this.setPosition()
      }
    },
    'MapRoot.mapPlug': {
      handler() {
        this.init()
      }
    }
  },
  computed: {
    markerPopup() {
      return this.MapRoot.clickPoint
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.$refs?.RMarkerPopup && this.MapRoot?.mapPlug?.map) {
        if (this.popup == false) {
          this.popup = new Overlay({
            element: this.$refs.RMarkerPopup,
            positioning: 'bottom-center',
            stopEvent: true,
            autoPan: false, // 自动移动
            offset: [0, -10]
          })
          this.popup.setProperties({ type: 'marker-popup' })
          this.MapRoot.mapPlug.map.addOverlay(this.popup)
        }
      }
    },
    setPosition() {
      if (this.popup) {
        let coord = [this.markerPopup.lon, this.markerPopup.lat]
        let reCoord = this.MapRoot.mapPlug.fromLonLat(coord)
        this.$nextTick(() => {
          this.popup.setPosition(reCoord)
        })
      } else {
        this.init()
        this.setPosition()
      }
    },
    initEvent() {},
    getClass() {
      return this.markerPopup.disable ? 'marker-disable' : ''
    },
    EventClose() {
      this.MapRoot.markerType = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-marker-popup {
  .m-close {
    position: absolute;
    top: 1px;
    right: 1px;
    color: #fff;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
