/**
@获取地图表单实况
 */
export function IDict_channelType() {
  return new Promise((res) => {
    let dict = {}
    let data = [
      { name: '中国天然氧吧', key: '3' },
      { name: '中国气候宜居城市(县)', key: '4' },
      { name: '避暑旅游目的地', key: '5' }
      // { name: '茶叶', key: '6' }
    ]
    for (let item of data) {
      dict[item.key] = item.name
    }
    res({ data: data, dict: dict })
  })
}

/**
@获取服务产品navlist
 */
export function ISelect_service_nav() {
  return new Promise((res) => {
    let data = [
      { name: '农业气象情报', img: 'ser-1.jpg', value: '1' },
      { name: '农业气象预报', img: 'ser-2.jpg', value: '2' },
      { name: '农业气象专题', img: 'ser-3.jpg', value: '3' },
      { name: '特色农业气象', img: 'ser-4.jpg', value: '4' },
      { name: '农业气候资源利用', img: 'ser-5.jpg', value: '5' }
    ]
    res({ data: data })
  })
}
