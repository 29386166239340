import { fromLonLat } from 'ol/proj'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'
import VectorSource from 'ol/source/Vector'
import { Feature } from 'ol'
import Point from 'ol/geom/Point'

class MapWebGLPoints {
  //在VectorLayer图层中，我们只需要创建一个ol.style.Style对象即可，WebGLPoints则不同，它并不是基于Canvas进行绘制，因此其样式渲染不能直接使用ol.style.Style，取而代之的是使用样式表达式进行渲染。
  //match表达式-样式运算符
  colorMatch = [
    'match',
    ['get', 'value'],
    1,
    'red', // 如果 'value' 是 1，则颜色为红色
    2,
    'green', // 如果 'value' 是 2，则颜色为绿色
    3,
    'blue', // 如果 'value' 是 3，则颜色为蓝色
    'black' // 如果 'value' 是其他值，则颜色为黑色
  ]
  constructor({ map, colorMatch }) {
    this.map = map
    this.colorMatch = colorMatch
    this.createLayer()
  }

  createLayer() {
    this.source = new VectorSource()
    this.pointLayer = new WebGLPointsLayer({
      source: this.source,
      style: {
        symbol: {
          symbolType: 'circle',
          size: 10,
          color: this.colorMatch
        }
      },
      zIndex: 50
    })
    this.map.addLayer(this.pointLayer)
  }

  update(data) {
    this.source.clear()
    const features = data.map((item) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([item.lon, item.lat])),
        value: item.value,
        data: item
      })
      feature.set('featureType', 'webGLPoint')
      return feature
    })
    this.source.addFeatures(features)
  }
  clear() {
    this.source.clear()
  }
  destroy() {
    // 从地图中移除图层
    this.map.removeLayer(this.pointLayer)
    // 清空数据源
    this.source.clear()
    // 释放资源
    this.source = null
    this.pointLayer = null
  }
}

export default MapWebGLPoints
