import { createStore } from 'vuex'

import storeModules from './storeModules.js'

const store = createStore({
  state() {
    return {}
  },
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    ...storeModules
  }
})

export default store
