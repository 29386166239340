<template>
  <template v-for="(item, index) in navs" :key="item.name">
    <el-sub-menu
      popper-class="is-dark ko-sub-menu"
      :index="item.name"
      class="nav-li"
      v-if="item?.children"
    >
      <template #title>
        <div class="name">{{ item.name }}</div>
      </template>
      <SubMenu :navs="item.children"></SubMenu>
    </el-sub-menu>
    <el-menu-item
      v-else
      :index="item.name"
      class="nav-li"
      :class="getClass(item)"
      :disabled="item.disabled"
      @click="EventClick_navLi(item, index)"
    >
      <div class="name">{{ item.name }}</div>
    </el-menu-item>
  </template>
</template>
<script>
export default {
  name: 'SubMenu',
  components: {},
  props: {
    navs: {
      type: Array
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {},
  methods: {
    getNavImg() {
      return ''
    },
    getClass(item) {
      let homeNav = ['/', '/home']
      if (item.path == '/home' && homeNav.includes(this.$route.path)) {
        return 'current'
      } else {
        return item.path == this.$route.path ? 'current' : ''
      }
    },
    EventClick_navLi(item) {
      if (item.path.indexOf('http://') >= 0) {
        if (this.userInfo?.name) {
          window.open(item.path)
        } else {
          this.Api.msg('请先登录')
        }
      } else {
        this.Api.routerLink({ push: true, path: item.path })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-popper {
  .nav-li {
    .name {
      font-size: 20px;
    }
  }
}
.nav-li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 0 solid transparent;
  height: 60px;
  line-height: 60px;
  .name {
    font-size: 16px;
    color: #fff;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 20px;
  }
  .ko-img {
    height: 30px;
    width: 40px;
    margin-right: 10px;
    ::v-deep(img) {
      object-fit: contain;
      vertical-align: top;
    }
  }
  &.is-active {
    border-bottom: 0 solid transparent !important;
  }
  &.current {
    .name {
      position: relative;
      color: #fff;
      z-index: 2;
    }
    border-bottom: 0 solid transparent !important;
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -10px;
      bottom: -10px;
      background: #23c4e0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      right: -7px;
      top: -10px;
      width: 8px;
      height: 10px;
      display: block;
      background: url('@/assets/img/index/nav-i.png') center/contain no-repeat;
    }
  }
}
</style>
