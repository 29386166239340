import { DefAjax } from '../service/ajax.js'
/**
 * 查询氧吧地址
 * @returns
 */
export function Info_searchBrandData(ret) {
  let def = { brandTypes: '3,4,5', name: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: 'api/project/product/buildTime/byName',
    params: opt
    //load: 'mock'
  })
}
