<template>
  <div class="area-oxy-chart">
    <div class="column-container">
      <CpnTitleB name="试点省份负（氧）离子浓度">
        <CpnSwiperA
          :list="address.list"
          v-model:index="address.index"
          @select="EventSelect"
        ></CpnSwiperA>
      </CpnTitleB>
      <div class="title">
        <div class="name"></div>
        <div class="address"></div>
      </div>
      <div class="content">
        <div class="column-1 column-box">
          <CpnTitleA>黑龙江氧吧地区月均负 (氧)离子浓度</CpnTitleA>
          <ChartLineOxyMonth :areaCode="areaCode"></ChartLineOxyMonth>
        </div>
        <div class="column-2 column-box">
          <CpnTitleA>黑龙江氧吧地区年均负(氧)离子浓度</CpnTitleA>
          <ChartBarOxyCity :areaCode="areaCode"></ChartBarOxyCity>
        </div>
        <div class="column-3 column-box">
          <CpnTitleA>黑龙江氧吧地区日均负(氧)离子浓度排行</CpnTitleA>
          <BoxRankList :areaCode="areaCode"></BoxRankList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CpnSwiperA from '@/components/content/swiper/CpnSwiperA.vue'
import CpnTitleA from '@/components/view/title/CpnTitleA.vue'
import CpnTitleB from '@/components/view/title/CpnTitleB.vue'
import ChartLineOxyMonth from './areaOxyChart/ChartLineOxyMonth.vue'
import ChartBarOxyCity from './areaOxyChart/ChartBarOxyCity.vue'
import BoxRankList from './areaOxyChart/BoxRankList.vue'
import { Info_provinceList } from '@/info/infoHome.js'
export default {
  inject: [],
  components: {
    CpnSwiperA,
    CpnTitleA,
    CpnTitleB,
    ChartLineOxyMonth,
    ChartBarOxyCity,
    BoxRankList
  },
  props: {},
  data() {
    return {
      address: {
        list: [{ name: '黑龙江' }],
        index: 0
      },
      areaCode: 230000
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getTestData()
  },
  mounted() {},
  methods: {
    getData() {
      Info_provinceList().then((ret) => {
        let list = []
        if (ret.list?.length) {
          list = ret.list
        }
        this.address.list = list
      })
    },
    getTestData() {
      this.address.list = [{ province_id: '230000', provinceName: '黑龙江省' }]
    },
    EventSelect(ret) {
      this.areaCode = ret.province_id
    }
  }
}
</script>
<style lang="scss" scoped>
.area-oxy-chart {
  &.page-home {
    .column-box {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
  }
  .content {
    display: flex;
    .column-box {
      padding: 15px;
      background: #fff;
    }
    .column-1 {
      width: 10px;
      flex-grow: 1;
      margin-right: 20px;
    }
    .column-2 {
      width: 10px;
      margin-right: 20px;
      flex-grow: 1;
    }
    .column-3 {
      width: 10px;
      flex-grow: 0.7;
    }
  }
}
</style>
