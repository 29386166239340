<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-09-26 16:41:53
-->
<template>
  <div class="cpn-map-wrap">
    <div class="cpn-map-inner">
      <div :id="mapId" class="map-container"></div>
    </div>
  </div>
</template>
<script>
import initMap from '@/plug/map/openLayer/initMap.js'
import { getAdminDivi } from './js/HooksGeojson.js'
export default {
  provide: function () {
    return {
      MapRoot: this.MapRoot
    }
  },
  components: {},
  props: {
    mapId: {
      type: String,
      default: 'map-chaye'
    }
  },
  data() {
    return {
      mapLoadingArr: [],
      mapLoadingText: '地图加载中',
      MapRoot: {
        //地图共享数据集，子组件通过inject获取
        mapPlug: false, //地图组件
        areaCode: 100000,
        onLoading: this.onLoading,
        offLoading: this.offLoading
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let config = {
        id: this.mapId,
        ...this.Api.getMapConfig(),
        center: [103.5, 34],
        zoom: 4.35
      }
      let mapPlug = new initMap(config)
      this.MapRoot.mapPlug = mapPlug
      getAdminDivi()
    },
    onLoading(text) {
      this.mapLoadingArr.push(text)
      this.mapLoadingText = text
    },
    offLoading() {
      this.mapLoadingArr.splice(0, 1)
      this.mapLoadingText = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-wrap {
  height: 100%;
}
.cpn-map-inner {
  position: relative;
  height: 690px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .top-bar {
    flex-shrink: 0;
  }
  .map-container {
    height: 10px;
    flex-grow: 1;
    ::v-deep(.ol-viewport) {
      position: static !important;
    }
  }
}
</style>
