<template>
  <div></div>
</template>
<script>
import { InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import OMU from '@/plug/map/openLayer/utils.js'
import { toLonLat } from 'ol/proj'
export default {
  components: {},
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      coordObj: ''
    }
  },
  watch: {
    'MapRoot.mapPlug': {
      handler() {
        this.initMap()
      }
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
    },
    getData() {
      //获取外轮廓
      this.outerFeatures = []
      let areaCode = this.MapRoot.areaCode
      InfoMap_adminDiviOuter({ areaCode }).then((ret) => {
        let list = []
        if (ret?.features?.length) {
          let leng = 0
          for (let item of ret.features[0].geometry.coordinates) {
            if (item.length > leng) {
              leng = item.length
              list = item
            }
          }
        }
        this.outerFeatures = list
      })
    },
    setMapStoreCoord(ret) {
      // 设置当前点击的地图经纬度
      let coord = [ret[0]?.toFixed(3), ret[1]?.toFixed(3)]
      let coordObj
      let reCoord = toLonLat(coord)
      if (this.outerFeatures.length) {
        let bool = OMU.isPointInPolygon(reCoord, this.outerFeatures)
        coordObj = { coord: reCoord, inFeature: bool, show: true }
      } else {
        coordObj = { coord: reCoord }
      }
      this.coordObj = coordObj
      //this.$store.commit(this.StoreMap + '/setMapCoord', coordObj)
    }
  }
}
</script>
<style lang="scss" scoped>
.m-map-container {
  height: 100%;
  background: #eee;
}
</style>
