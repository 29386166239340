import { JsonAjax as DefAjax } from '../service/ajax.js'
import Api from '@/api/Api.js'
/**
 * @获取轮廓
 */
export function InfoMap_adminDivi(ret) {
  let areaCode = ret?.areaCode
  if (areaCode == Api.areaCode) {
    let areaJson = require('@/assets/json/areaJson/' + areaCode + '/area.json')
    return new Promise((suc) => {
      suc(areaJson)
    })
  } else {
    let url =
      'https://geo.datav.aliyun.com/areas_v3/bound/geojson?code=' +
      areaCode +
      '_full'
    return DefAjax({
      morePost: true,
      method: 'get',
      url: '/api/utils/forward/get',
      params: { url: url }
    })
  }
}

/**
 * @获取当前areaCode的外轮廓
 */
export function InfoMap_adminDiviOuter(ret) {
  let areaCode = ret?.areaCode
  if (areaCode == Api.areaCode) {
    let areaJson = require('@/assets/json/areaJson/' +
      areaCode +
      '/areaOuter.json')
    return new Promise((suc) => {
      suc(areaJson)
    })
  } else {
    let url =
      'https://geo.datav.aliyun.com/areas_v3/bound/' + areaCode + '.json'
    return DefAjax({
      morePost: true,
      method: 'get',
      url: '/api/utils/forward/get',
      params: { url: url }
    })
  }
}

/**/

export function InfoMap_marker(ret) {
  let def = { regionName: '中国', brandType: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: '/api/brand/map/data',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}
