import { ref } from 'vue'
import { InfoMap_marker } from '@/info/infoMap.js'
import { KOMap } from './initMap.js'
import { MapData } from './MapPlugAdminPolygon'
import MapWebGLPoints from '@/plug/map/openLayer/MapWebGLPoints.js'
import { InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import { isPointInGeojson } from '@/plug/map/openLayer/polygon/utilsPolygon.js'
import { clickPointPopup } from '../layer/mapPopup/js/mapPointPopup'
import { getYears } from '@/api/func/dateFn.js'
import { viewCurrentPointPopup } from './MapInteraction'
import { isCityLevel } from '@/api/func/DataPro.js'
let colors = [
  '#FF0094',
  '#5C7BD9',
  '#4BBE9E',
  '#FFB401',
  '#F68E42',
  '#23C4E0',
  '#71AB2C',
  '#c23531',
  '#2f4554',
  '#61a0a8',
  '#d48265',
  '#91c7ae',
  '#749f83',
  '#ca8622',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#c4ccd3'
]
const Expression = ['match', ['get', 'value']]
const legendYearDict = {},
  years = getYears(2016)
for (let i = 0; i < years.length; i++) {
  legendYearDict[years[i]] = {
    name: years[i] + '年',
    value: years[i],
    color: colors[i]
  }
  Expression.push(years[i], colors[i])
}
// 添加默认颜色到表达式
Expression.push('black')

const MapPlugGLPoint = ref(null)
export const visibleYears = ref([])
export const yearLegend = ref([])
let markerPoints = []
export function initMapPoint() {
  MapData.loading = true
  Promise.all([
    InfoMap_adminDiviOuter({ areaCode: MapData.areaCode }),
    InfoMap_marker()
  ]).then((rets) => {
    MapData.loading = false
    let list = [],
      dict = {}
    yearLegend.value = []
    for (let item of rets[1].data) {
      if (
        isPointInGeojson([item.lon, item.lat], rets[0]) &&
        item.brandType === 3
      ) {
        let year = item.createYear ?? parseInt(item.publishTime?.Format('YYYY'))
        list.push({
          ...item,
          value: year
        })
        if (typeof dict[year] == 'undefined') {
          dict[year] = legendYearDict[year]
          yearLegend.value.push(dict[year])
          visibleYears.value.push(year)
        }
      }
    }
    if (!MapPlugGLPoint.value) {
      MapPlugGLPoint.value = new MapWebGLPoints({
        map: KOMap.value,
        colorMatch: Expression
      })
    }
    markerPoints = list
    updateGLPoint()
  })
}

export function updateGLPoint() {
  clickPointPopup.value = null
  let list = []
  for (let item of markerPoints) {
    if (visibleYears.value.includes(item.value)) {
      list.push(item)
    }
  }
  MapPlugGLPoint.value?.update(list)
  console.log(isCityLevel(MapData.areaCode), MapData.areaCode)
  if (list?.length && isCityLevel(MapData.areaCode)) {
    viewCurrentPointPopup(list[0])
  }
}

export function clearMapPoint() {
  MapPlugGLPoint.value?.clear()
  clickPointPopup.value = null
  yearLegend.value = []
  visibleYears.value = []
}

export function destroyMapPoint() {
  clearMapPoint()
  MapPlugGLPoint.value?.destroy()
  MapPlugGLPoint.value = null
}
