<template>
  <div class="cpn-title">
    <i v-if="icon" :class="icon"></i>
    <div class="name"><slot></slot></div>
    <div class="more" v-if="more" @click="EventLink(more)">
      更多<i class="iconfont icon-iconright"></i>
    </div>
  </div>
</template>
<script>
import TypeIs from '@/api/TypeIs.js'
export default {
  log: '',
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    more: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventLink(ret) {
      if (TypeIs.String) {
        let item = this.Api.url2Obj(ret)
        this.Api.routerLink(item)
      } else {
        this.Api.routerLink({ path: ret })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-title {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  i {
    flex-shrink: 0;
    margin-right: 7px;
    width: 32px;
    height: 32px;
    background: left center/contain no-repeat;
    &.tt-1 {
      background-image: url('~@/assets/img/icon/icon-title-1.png');
    }
    &.tt-2 {
      background-image: url('~@/assets/img/icon/icon-title-2.png');
    }
    &.tt-3 {
      background-image: url('~@/assets/img/icon/icon-title-3.png');
    }
    &.tt-4 {
      background-image: url('~@/assets/img/icon/icon-title-4.png');
    }
    &.tt-5 {
      background-image: url('~@/assets/img/icon/icon-title-5.png');
    }
    &.tt-6 {
      background-image: url('~@/assets/img/icon/icon-title-6.png');
    }
    &.tt-7 {
      background-image: url('~@/assets/img/icon/icon-title-7.png');
    }
    &.tt-8 {
      background-image: url('~@/assets/img/icon/icon-title-8.png');
    }
    &.tt-9 {
      background-image: url('~@/assets/img/icon/icon-title-9.png');
    }
    &.tt-10 {
      background-image: url('~@/assets/img/icon/icon-title-10.png');
    }
  }
  .name {
    position: relative;
    flex-grow: 1;
    height: 36px;
    line-height: 36px;
    font-size: 22px;
    font-weight: bold;
    background: url('~@/assets/img/home/title-bg.png') 50px bottom/contain
      no-repeat;
  }
  .more {
    flex-shrink: 0;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
