export default {
  childState: {
    mapPlug: false,
    mapLoading: false,
    mapLoadingText: '',
    mapLoadingArr: [],
    viewPosition: [],
    mapView: {}
  },
  getters: {},
  mutations: {
    saveState(state, ret) {
      state[ret.key] = ret.value
    },
    setMapPlug(state, mapPlug) {
      state.mapPlug = mapPlug
    },
    onMapLoading(state, text) {
      state.mapLoading = true
      state.mapLoadingArr.push(text ?? '')
      if (text) {
        state.mapLoadingText = text
      } else {
        state.mapLoadingText = ''
      }
    },
    offMapLoading(state) {
      state.mapLoading = false
      if (state.mapLoadingArr?.length) {
        state.mapLoadingArr.splice(0, 1)
      }
    }
  },
  actions: {}
}
