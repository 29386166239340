<template>
  <div class="main-home">
    <AreaBanner class="mgb-20"></AreaBanner>
    <AreaNotice class="mgb-40"></AreaNotice>
    <div class="area-bg-1"><AreaMap class="mgb-40"></AreaMap></div>
    <div class="area-bg">
      <AreaOxySearch class="mgb-40"></AreaOxySearch>
      <AreaHistoreAreaData v-if="false" class="mgb-50"></AreaHistoreAreaData>
      <AreaOxyChart v-if="false" class="page-home"></AreaOxyChart>
    </div>
    <KOimg
      v-if="false"
      class="area-line"
      :src="require('@/assets/img/bg/line-bg-1.jpg')"
    ></KOimg>
    <AreaNews class="mgb-40"></AreaNews>
    <AreaShenbao v-if="false"></AreaShenbao>
    <AreaProjectColumn class="mgb-50"></AreaProjectColumn>
    <AreaOxyProject class="mgb-50"></AreaOxyProject>
    <AreaCityProject class="mgb-50"></AreaCityProject>
    <AreaTourProject class="mgb-50"></AreaTourProject>
    <div class="area-bg-end">
      <AreaPlaque class="mgb-50"></AreaPlaque>
      <FixDeclare></FixDeclare>
    </div>
  </div>
</template>
<script>
import AreaBanner from './home/AreaBanner.vue'
import AreaNotice from './home/AreaNotice.vue'
import AreaNews from './home/AreaNews.vue'
import AreaShenbao from './home/AreaShenbao.vue'
import AreaMap from './home/AreaMap.vue'
import AreaOxySearch from './home/areaOxySearch/AreaOxySearch.vue'
import AreaHistoreAreaData from './home/AreaHistoreAreaData.vue'
import AreaOxyChart from './home/AreaOxyChart.vue'

import AreaOxyProject from './home/AreaOxyProject.vue'
import AreaTourProject from './home/AreaTourProject.vue'
import AreaCityProject from './home/AreaCityProject.vue'
import AreaProjectColumn from './home/AreaProjectColumn.vue'
import AreaPlaque from './home/AreaPlaque.vue'
import FixDeclare from '@/components/view/other/FixDeclare.vue'
export default {
  components: {
    AreaBanner,
    AreaNotice,
    AreaNews,
    AreaShenbao,
    AreaMap,
    AreaOxySearch,
    AreaHistoreAreaData,
    AreaOxyChart,

    AreaOxyProject,
    AreaTourProject,
    AreaCityProject,
    AreaProjectColumn,
    AreaPlaque,
    FixDeclare
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style scoped lang="scss">
.main-home {
  background: #fff;
  .line {
    height: 40px;
    background: linear-gradient(90deg, #4cbe9d, #24c4df);
  }

  .area-line {
    opacity: 0.5;
  }
  .area-bg-1 {
    background: url('@/assets/img/bg/bg-home-map.jpg') center bottom no-repeat;
  }
  .area-bg-2 {
    padding-top: 50px;
    background: url('@/assets/img/bg/bg-4.jpg') center top no-repeat;
  }
  .area-bg-end {
    padding-bottom: 200px;
    background: url('@/assets/img/foot/end-bg-home.jpg') center bottom/100% auto
      no-repeat;
  }
}
</style>
