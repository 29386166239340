<template>
  <div class="area-news">
    <div class="column-container dispost-area">
      <div class="column-1">
        <CpnTitle icon="tt-1" more="/newsList">新闻</CpnTitle>
        <BoxHomeNews></BoxHomeNews>
      </div>
      <div class="column-2">
        <div class="left-lump">
          <CpnTitle icon="tt-2" more="/pdfList?type=3">政策</CpnTitle>
          <BoxHomeZhengCe class="mgb-20"></BoxHomeZhengCe>
        </div>
        <div class="right-lump">
          <CpnTitle icon="tt-3" more="/pdfList?type=4">标准规范</CpnTitle>
          <BoxHomeGuiFan></BoxHomeGuiFan>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import BoxHomeNews from '@/views/pages/pageGeneral/areaNews/BoxHomeNews.vue'
import BoxHomeZhengCe from '@/views/pages/pageGeneral/areaNews/BoxZhengCe.vue'
import BoxHomeGuiFan from '@/views/pages/pageGeneral/areaNews/BoxGuiFan.vue'
export default {
  log: '',
  components: { CpnTitle, BoxHomeNews, BoxHomeZhengCe, BoxHomeGuiFan },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.area-news {
  .column-container {
    .column-1 {
      margin-bottom: 30px;
    }
    .column-2 {
      display: flex;
      .left-lump {
        flex-grow: 1;
        width: 10px;
        margin-right: 20px;
      }
      .right-lump {
        flex-grow: 1;
        width: 10px;
      }
    }
  }
}
</style>
