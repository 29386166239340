<template>
  <div class="layer-marker-legend">
    <div
      class="all-view"
      v-if="legendDict.length != MapRoot.visibleYear?.length"
      @click="EventAllView"
    >
      全部显示
    </div>
    <ul v-if="MapRoot.visibleYear?.length">
      <template v-for="item in legendDict" :key="item.name">
        <li
          :label="item.name"
          :value="item.key"
          :class="getClass(item)"
          @click="EventSelect(item)"
        >
          <div class="icon" :style="{ 'background-color': item.color }"></div>
          <div class="name">{{ item.name }}年</div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    legendDict() {
      return this.MapRoot?.legendDict
    }
  },
  mounted() {},
  methods: {
    getClass(item) {
      let isShow = this.MapRoot.visibleYear.includes(item.key) ? 'view' : 'hide'
      let isCurrent = this.MapRoot.selectAreaYear.includes(item.key)
        ? 'current'
        : ''
      return isShow + ' ' + isCurrent
    },
    EventSelect(item) {
      this.MapRoot.visibleYear = [item.key]
    },
    EventAllView() {
      this.MapRoot.visibleYear = this.legendDict.map((e) => e.key)
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-marker-legend {
  position: absolute;
  left: 0;
  top: 40px;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  .all-view {
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }
  li {
    display: flex;
    align-items: center;
    line-height: 1.8;
    cursor: pointer;
    &:hover {
      color: #23c4e0;
    }
    &.hide {
      opacity: 0.3;
    }
    &.current {
      color: #23c4e0;
    }
  }
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
</style>
