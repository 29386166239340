import { getAreaCodeLevel } from '@/api/func/DataPro.js'
import { DataFlat } from '@/api/func/areaTreePro.js'
import { Info_areaTree } from '@/info/info.js'

let areaDict = {}
export function PromiseGetAreaName(areaCode) {
  return new Promise((resolve) => {
    Info_areaTree().then((ret) => {
      if (ret?.data?.length) {
        let treeJson = ret.data
        treeJson[0].id = '100000'
        treeJson[0].name = '全国'
        reData_tree(treeJson)
        let obj = DataFlat({
          list: treeJson,
          areaNameKey: 'name',
          areaCodeKey: 'id'
        })
        areaDict = obj.dict
        resolve(areaDict[areaCode])
      }
    })
  })
}

function reData_tree(ret) {
  for (let item of ret) {
    if (getAreaCodeLevel(item.id) == 2) {
      //去掉区县的数据
      delete item.children
    }
    if (item.children && item.children.length) {
      reData_tree(item.children)
    } else {
      delete item.children
    }
  }
}
