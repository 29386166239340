import { JsonAjax as DefAjax } from '../service/ajax.js'

/**
 * @获取_地新闻类别数据
 */
export function Info_newsTypes(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: 'api/news/type',
    params: opt
  })
}

/**
 * @获取_地新闻列表数据
 */
export function Info_newsLists(ret) {
  let def = {
    pageNum: 1,
    pageSize: 10,
    //typeCode: 'ZXDT',
    sortField: 'isTop DESC,isRecommend DESC,publishTime DESC',
    brandType: 3
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: 'api/news/page',
    params: opt
  })
}

/**
 * @获取_专题专栏列表数据
 */
export function Info_projectList(ret) {
  let def = {
    pageNum: 1,
    pageSize: 10,
    brandType: 3, //品牌类型
    typeId: 3, //类型
    year: '' //年份
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/sys/imageManager/page',
    params: opt
  })
}

/**
 * @获取_专题专栏详情数据
 */
export function Info_projectDetail(ret) {
  let def = {
    id: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: 'api/sys/imageManager/info/' + opt.id
  })
}

/**
 * @获取_地新闻详情数据
 */
export function Info_newsDetails(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: 'api/news/' + opt.id,
    params: opt
  })
}

/**
 * @获取_品牌详情数据
 */
export function Info_brandOxyDetails(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: 'api/oxygen/base/' + opt.id,
    params: opt
  })
}
