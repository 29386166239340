<template>
  <div class="other-data-view" :class="layerState">
    <div
      class="inner-view"
      v-loading="loading"
      element-loading-text="数据加载中..."
    >
      <div class="title">
        <div class="name">{{ PageRoot.brandName }}</div>
      </div>
      <CpnProductState
        stateType="default"
        :baseCount="totalCount"
        :productCount="applyCount"
        v-model="countIndex"
        @select="setChart"
      ></CpnProductState>
      <div class="line"></div>
      <CpnTitleA>地理区域分布</CpnTitleA>
      <CpnChartPieState
        ref="RCpnChartPieState"
        class="m-chart-pie-state"
      ></CpnChartPieState>
      <CpnTitleA>逐年获评数量</CpnTitleA>
      <CpnChartLineState
        ref="RCpnChartLineState"
        class="m-chart-line-state"
      ></CpnChartLineState>
      <div
        class="win-handler on-close"
        :class="layerState"
        @click="EventChange_layer"
      ></div>
    </div>
  </div>
</template>
<script>
import CpnTitleA from '@/components/view/title/CpnTitleA.vue'
import CpnProductState from './otherDataView/CpnProductState'
import CpnChartPieState from './otherDataView/CpnChartPieState'
import CpnChartLineState from './otherDataView/CpnChartLineState'
import { Inter_OtherDataView } from '@/inter/InterOtherDataView.js'
import { InfoMap_productCount } from '@/info/infoOxyMapData.js'
import { getPostDataByAreaCode } from '@/work/workCommon.js'
export default {
  log: '',
  inject: ['MapRoot', 'PageRoot'],
  components: {
    CpnTitleA,
    CpnProductState,
    CpnChartPieState,
    CpnChartLineState
  },
  props: {},
  data() {
    return {
      loading: false,
      layerState: 'close',
      brandData: { line: [], pie: [], totalCount: 0 },
      countIndex: 0,
      totalCount: 0,
      applyCount: 0
    }
  },
  watch: {
    'PageRoot.brandType': {
      handler(val) {
        if (val != 6) {
          this.initData()
          this.getData_productCount()
        }
      }
    },
    'MapRoot.areaCode': {
      handler() {
        this.initData()
        this.getData_productCount()
      }
    },
    'MapRoot.visibleYear': {
      handler() {
        this.initData()
        this.getData_productCount()
      }
    }
  },
  computed: {},
  mounted() {
    this.initData()
    this.getData_productCount()
  },
  methods: {
    initData() {
      //氧吧
      this.loading = true
      Inter_OtherDataView({
        brandType: this.PageRoot.brandType,
        areaCode: this.MapRoot.areaCode,
        years: this.MapRoot.visibleYear.join(',')
      })
        .then((ret) => {
          this.loading = false
          this.brandData = ret
          if (this.PageRoot.brandType != 3) {
            this.totalCount = this.brandData.totalCount
          }
          this.setChart()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getData_productCount() {
      if (this.PageRoot.brandType == 3) {
        InfoMap_productCount({
          ...getPostDataByAreaCode(this.MapRoot.areaCode),
          brandType: this.PageRoot.brandType,
          years: this.MapRoot.visibleYear.join(',')
        })
          .then((ret) => {
            this.totalCount = 0
            this.applyCount = 0
            if (ret?.success) {
              this.totalCount = ret.data.totalCount
              this.applyCount = ret.data.applyCount
            }
          })
          .catch(() => {
            this.totalCount = 0
            this.applyCount = 0
          })
      } else {
        this.totalCount = this.brandData.totalCount
      }
    },
    setChart() {
      this.$refs.RCpnChartLineState?.initData(
        this.brandData.line[this.countIndex]
      )
      this.$refs.RCpnChartPieState?.initData(
        this.brandData.pie[this.countIndex]
      )
    },
    EventChange_layer() {
      if (this.layerState == 'close') {
        this.layerState = 'open'
      } else {
        this.layerState = 'close'
      }
      setTimeout(() => {
        this.MapRoot.mapPlug.resizeZoom()
      }, 200)
    }
  }
}
</script>
<style lang="scss" scoped>
.other-data-view {
  position: relative;
  margin-top: 20px;
  width: 430px;
  height: 630px;
  padding: 5px;
  overflow: hidden;
  transition: 0.1s ease-out;
  .inner-view {
    position: absolute;
    width: 392px;
    left: 25px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    padding: 10px 10px 20px 10px;
  }
  &.close {
    width: 70px;
  }
  .title {
    padding: 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .name {
      padding: 8px 15px;
      font-size: 18px;
      border-radius: 20px;
      color: #fff;
      background: #23c4e0;
      font-weight: bold;
    }
  }
  .line {
    margin: 20px 10px;
    border-bottom: 1px solid #eee;
  }
  .m-chart-pie-state {
    height: 240px;
  }
  .m-chart-line-state {
    height: 160px;
  }
  .win-handler {
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background: #fff;
    width: 24px;
    text-align: center;
    padding: 10px 0;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &.close::after {
      content: '展开';
    }
    &:hover::after {
      color: #23c4e0;
    }
    &::after {
      content: '收起';
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
}
</style>
