import _ from 'lodash'
import { moduleObjectExtend } from '@/plug/module/moduleExtend'
const modulesFiles = require.context('../modulePlug/MapStore', true, /\.js$/)
let MapStoreModules = moduleObjectExtend(modulesFiles)
const Module = {
  namespaced: true,
  state() {
    return {
      ...MapStoreModules.childState,
      storeName: 'MapStore'
    }
  },
  getters: {},
  mutations: {
    initData() {
      //let StoreName = state.storeName
      //this.commit(state.storeName + '/initStore') //把storage中的KOStore取出来写到state中
    }
  },
  actions: {}
}
export default _.merge(MapStoreModules, Module)
