<!-- 地图右下角图标按钮-功能组件-->
<template>
  <div class="map-zoom-buttons" :class="'brand-' + PageRoot.brandType">
    <MapButton @EventClick="EventClick">
      <template v-slot:top>
        <div log="插槽:上部按钮" class="ui-button" @click="EventClick_home">
          <i class="jiachang icon-cb"></i>
        </div>
      </template>
      <template v-slot:bottom>
        <div
          v-if="MapRoot.areaLevel > 0"
          log="插槽:下部按钮"
          class="ui-button"
          @click="EventClick_back"
        >
          <i class="icon-chexiao iconfont"></i>
        </div>
      </template>
    </MapButton>
  </div>
</template>
<script>
import { InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import MapButton from '@/components/common/map/base/MapButton.vue'
import { getAreaCodeLevel, getParentAreaCode } from '@/api/func/DataPro.js'
export default {
  components: { MapButton },
  inject: ['MapRoot', 'PageRoot'],
  props: {},
  data() {
    return { adminDiviOuterJson: {} }
  },
  watch: {
    'MapRoot.mapPlug': {
      handler() {
        this.initMap()
      }
    }
  },
  computed: {},
  created() {
    this.initData()
  },
  mounted() {
    //DOM初始化完成进行地图初始化
  },

  methods: {
    initMap() {
      //初始化地图
    },
    initData() {
      let areaCode = this.MapRoot.areaCode
      InfoMap_adminDiviOuter({ areaCode }).then((ret) => {
        if (ret?.features?.length) {
          //外轮廓
          this.adminDiviOuterJson = ret
        }
      })
    },
    EventClick(ret) {
      if (this.MapRoot.mapPlug.zoomCtrl) {
        this.MapRoot.mapPlug.zoomCtrl(ret)
      }
    },
    EventClick_home() {
      if (this.MapRoot.mapPlug.resizeZoom) {
        this.MapRoot.mapPlug.resizeZoom()
      }
    },
    EventClick_back() {
      let parentAreaCode = getParentAreaCode(this.MapRoot.areaCode)
      this.MapRoot.areaCode = parentAreaCode
      this.MapRoot.areaName = this.MapRoot.areaDict[this.MapRoot.areaCode]
      this.MapRoot.selectAreaCode = parentAreaCode
      this.MapRoot.areaLevel = getAreaCodeLevel(parentAreaCode)
    }
  }
}
</script>
<style lang="scss" scoped>
.map-zoom-buttons {
  position: absolute;
  bottom: 30px;
  right: 0px;
}
</style>
