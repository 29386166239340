export const adminLineOptions = {
  150000: {
    //内蒙
    points: [
      [110, 51], //文本位置
      [114.01014492753623, 51],
      [114.01014492753623, 43.993410326086966] //地图点位
    ],
    label: {
      offsetX: -90
    }
  },
  140000: {
    //山西
    points: [
      [110, 49.5], //文本位置
      [112.40144927536232, 49.5],
      [112.40144927536232, 38.952830615942034] //地图点位
    ],
    label: {
      offsetX: -90
    }
  },
  610000: {
    //陕西
    points: [
      [110, 48], //文本位置
      [110.9536231884058, 48],
      [110.9536231884058, 36.75427989130436],
      [109.61304347826088, 36.75427989130436] //地图点位
    ],
    label: {
      offsetX: -90
    }
  },
  640000: {
    //宁夏
    points: [
      [101.8, 46.5], //文本位置
      [99, 46.5],
      [99, 36.48616394927536],
      [105.91304347826086, 36.48616394927536] //地图点位
    ],
    label: {
      offsetX: 22
    }
  },
  620000: {
    //甘肃
    points: [
      [101.8, 45], //文本位置
      [101, 45],
      [101, 38.57746829710146],
      [102.96376811594203, 38.57746829710146] //地图点位
    ],
    label: {
      offsetX: 22
    }
  },
  110000: {
    //北京
    points: [
      [110, 52.5], //文本位置
      [116.36956521739131, 52.5],
      [116.36956521739131, 40.347033514492765] //地图点位
    ],
    label: {
      offsetX: -90
    }
  },
  230000: {
    //黑龙江
    points: [
      [130, 52.5], //文本位置
      [128.54202898550727, 52.5],
      [128.54202898550727, 47.74703351449276] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  220000: {
    //吉林
    points: [
      [131.3, 42.974569746376815], //文本位置
      [126.23623188405797, 42.974569746376815] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  210000: {
    //辽宁
    points: [
      [131.3, 41.25862771739131], //文本位置
      [123.82318840579711, 41.25862771739131] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  130000: {
    //河北
    points: [
      [131.3, 37.7], //文本位置
      [115.59202898550726, 37.7], //地图点位
      [115.59202898550726, 38.5] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  120000: {
    //天津
    points: [
      [131.3, 39.11370018115943], //文本位置
      [117.30797101449275, 39.11370018115943] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  370000: {
    //山东
    points: [
      [131.3, 36.486163949275365], //文本位置
      [116.6644927536232, 36.486163949275365] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  320000: {
    //江苏
    points: [
      [131.3, 34.019497282608704], //文本位置
      [119.02391304347827, 34.019497282608704] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  310000: {
    //上海
    points: [
      [131.3, 31.49920742753624], //文本位置
      [121.59782608695653, 31.49920742753624] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  340000: {
    //安徽
    points: [
      [125, 32.73254076086957], //文本位置
      [117.09347826086957, 32.73254076086957] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  410000: {
    //河南
    points: [
      [125, 35.19920742753624], //文本位置
      [113.17898550724638, 35.19920742753624], //地图点位
      [113.17898550724638, 34.66] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  420000: {
    //湖北
    points: [
      [125, 30.53399003623189], //文本位置
      [112.8572463768116, 30.53399003623189] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  330000: {
    //浙江
    points: [
      [131.3, 29.890511775362327], //文本位置
      [119.88188405797102, 29.890511775362327] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  360000: {
    //江西
    points: [
      [131.3, 28.281816123188413], //文本位置
      [115.43115942028987, 28.281816123188413] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  350000: {
    //福建
    points: [
      [131.3, 26.780366847826095], //文本位置
      [118, 26.780366847826095] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  710000: {
    //台湾
    points: [
      [125, 24.367323369565227], //文本位置
      [121.00797101449277, 24.367323369565227] //地图点位
    ],
    label: {
      offsetX: 18
    }
  },
  440000: {
    //广东
    points: [
      [123.67096774193548, 22.468876008064512], //文本位置
      [114.95806451612904, 22.468876008064512],
      [114.95806451612904, 23.647505040322574] //地图点位
    ],
    label: {
      offsetX: 20
    }
  },
  810000: {
    //香港
    points: [
      [120, 20.5], //文本位置
      [114.11512096774196, 20.5], //地图点位
      [114.11512096774196, 22.468876008064512] //地图点位
    ],
    label: {
      offsetX: 20
    }
  },
  820000: {
    //澳门
    points: [
      [113.56717062748028, 20], //文本位置
      [113.56717062748028, 22.120948712819647] //地图点位
    ],
    label: {
      offsetX: -20,
      offsetY: 20
    }
  },
  460000: {
    //海南
    points: [
      [107.5, 18.45556955645163], //文本位置
      [107.5, 19.887827620967755],
      [109.70645161290321, 19.887827620967755] //地图点位
    ],
    label: {
      offsetX: -20,
      offsetY: 20
    }
  },
  450000: {
    //广西
    points: [
      [104, 20.6039566532258], //文本位置
      [104, 22.812021169354832],
      [108.87096774193549, 22.812021169354832] //地图点位
    ],
    label: {
      offsetX: -40,
      offsetY: 20
    }
  },
  530000: {
    //云南
    points: [
      [101.23225806451613, 18.45556955645163], //文本位置
      [101.23225806451613, 24.244279233870962] //地图点位
    ],
    label: {
      offsetX: -40,
      offsetY: 20
    }
  },
  430000: {
    //湖南
    points: [
      [95.38387096774194, 25.139440524193542], //文本位置
      [111.37741935483871, 25.139440524193542],
      [111.37741935483871, 28.30234374999999] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  },
  520000: {
    //贵州
    points: [
      [89, 26.332988911290315], //文本位置
      [106.60322580645162, 26.332988911290315] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  },
  500000: {
    //重庆
    points: [
      [81, 29.674924395161284], //文本位置
      [107.91612903225807, 29.674924395161284] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  },
  510000: {
    //四川
    points: [
      [78, 31.64427923387096], //文本位置
      [102.12741935483871, 31.64427923387096] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  },
  540000: {
    //西藏
    points: [
      [78, 33.19589213709676], //文本位置
      [87.50645161290323, 33.19589213709676] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  },
  630000: {
    //青海
    points: [
      [75, 36.77653729838709], //文本位置
      [95.74193548387098, 36.77653729838709] //地图点位
    ],
    label: {
      offsetX: -20,
      textAlign: 'right'
    }
  },
  650000: {
    //新疆
    points: [
      [79, 43.46040826612902], //文本位置
      [87.02903225806452, 43.46040826612902],
      [87.02903225806452, 42.8] //地图点位
    ],
    label: {
      offsetX: -60,
      textAlign: 'right'
    }
  }
}
