<template>
  <div class="area-notice" v-loading="loading">
    <div class="column-container dispost-area" v-if="list.length">
      <div
        class="recommended-notice"
        @click="EventOpen(recommendList[0])"
        :class="getNewStyle(recommendList[0]?.publishTime)"
      >
        <div class="title">{{ recommendList[0]?.title }}</div>
      </div>
      <div class="m-notice">
        <ul class="notice-list">
          <li
            v-for="item in list"
            :key="item"
            :class="getNewStyle(item.publishTime)"
            @click="EventOpen(item)"
            :title="item.title"
          >
            <div class="title">
              {{ item.title }}
            </div>
          </li>
        </ul>
        <div class="more" @click="EventLink">
          更多<i class="icon iconfont icon-iconright"></i>
        </div>
      </div>
    </div>
    <div v-else class="ui-no-data">暂无新闻</div>
  </div>
</template>
<script>
import { Info_homeNewsList } from '@/info/infoHome.js'
export default {
  inject: [],
  components: {},
  props: {
    brandType: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      recommendList: []
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      Info_homeNewsList({
        pageSize: 4,
        pageNum: 1,
        typeCode: 'TZ',
        sortField: 'isTop DESC,isRecommend DESC,publishTime DESC',
        isRecommend: 1,
        brandType: this.brandType
      }).then((ret) => {
        this.loading = false
        if (ret?.list?.length) {
          this.recommendList = ret.list.splice(0, 1)
          this.list = this.Api.reData_warning({
            list: ret.list,
            dateKey: 'publishTime'
          })
        }
      })
    },
    getNewStyle(time) {
      return this.Api.isNew(time) ? 'new' : ''
    },
    EventOpen(item) {
      this.Api.openNewsDetail(item)
    },
    EventLink() {
      this.Api.routerLink({ path: '/newsList', query: { typeCode: 'TZ' } })
    }
  }
}
</script>
<style lang="scss" scoped>
.area-notice {
  padding: 20px 0;
  .recommended-notice {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    .title:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
    &.new::before {
      margin-right: 6px;
      display: block;
      width: 27px;
      height: 14px;
      content: '';
      flex-shrink: 0;
      background: url('~@/assets/img/icon/icon-new.png') left center/contain
        no-repeat;
    }
  }
  .m-notice {
    display: flex;
    align-items: center;
    .notice-list {
      display: flex;
      flex-grow: 1;
      li {
        display: flex;
        align-items: center;
        width: 10px;
        flex-grow: 1;
        .title {
          margin-right: 20px;
          flex-grow: 1;
          width: 0;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          &:hover {
            color: var(--theme-color);
            cursor: pointer;
          }
        }
        &.new::before {
          margin-right: 6px;
          display: block;
          width: 27px;
          height: 14px;
          content: '';
          flex-shrink: 0;
          background: url('~@/assets/img/icon/icon-new.png') left center/contain
            no-repeat;
        }
      }
    }
    .more {
      flex-shrink: 0;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #00c6bf;
      }
    }
  }
}
</style>
