<template>
  <div class="cpn-chart-line-oxy-month">
    <div class="sub-title">{{ subTitle }}</div>
    <ChartCore
      class="m-chart-core"
      ref="RChartView"
      v-loading="loading"
    ></ChartCore>
  </div>
</template>
<script>
import ChartCore from '@/components/content/chart/ChartCore.vue'
import { Info_chartNegativeO2MonthList } from '@/info/infoHome.js'
export default {
  log: '',
  components: { ChartCore },
  props: {
    fmt: {
      type: String,
      default: 'YYYY'
    },
    areaCode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      listData: [],
      xAxisData: [],
      title: '',
      option: {},
      subTitle: '',
      loading: false
    }
  },
  watch: {
    areaCode: {
      handler() {
        this.getData()
      }
    }
  },
  computed: {},
  mounted() {
    this.getData()
    this.initData()
    this.initEvent()
  },
  methods: {
    initEvent() {
      window.addEventListener('resize', () => {
        this.initData()
      })
    },
    getData() {
      this.listData = []
      this.xAxisData = []
      this.loading = true
      //let startDate = new Date().DateAdd('m', -13).Format('YYYY-MM-DD')
      //let endDate = new Date().DateAdd('m', -1).Format('YYYY-MM-DD')
      Info_chartNegativeO2MonthList({
        regionProvincialId: this.areaCode
        //startDate: startDate,
        //endDate: endDate
      }).then((ret) => {
        this.loading = false
        if (ret.data?.length) {
          let list = []
          for (let item of ret.data) {
            let month = item.month < 10 ? '0' + item.month : item.month
            list.push({
              ...item,
              date: item.year + '-' + month,
              time: (item.year + '-' + month + '-01').Format('time')
            })
          }
          list.sort((a, b) => {
            return a.time - b.time
          })
          this.subTitle =
            list[0]?.time?.Format('YYYY年MM月') +
            '~' +
            list[list.length - 1]?.time?.Format('YYYY年MM月')
          for (let item of list) {
            this.listData.push(item.negativeO2)
            this.xAxisData.push(item.date)
          }
          this.initData()
        }
      })
    },
    getTestData() {
      this.listData = []
      this.xAxisData = []
      for (let i = 1; i <= 12; i++) {
        this.listData.push(parseInt(Math.random() * 2000) + 3000)
        this.xAxisData.push(i + '月')
      }
    },
    initData() {
      let option = this.getOptions()
      if (this.$refs.RChartView) {
        if (option) {
          this.$refs.RChartView.initData(option)
        } else {
          this.$refs.RChartView.initData(option)
        }
      }
    },
    getOptions() {
      let opt = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          //调整图表上下左右位置
          top: 35,
          left: 10,
          right: 20,
          bottom: 0,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: this.xAxisData,
            axisLabel: {
              // fontSize: nowSize(16)
              formatter: (e) => {
                return e.Format('YYYY-MM')
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '个',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true
            },
            splitLine: {},
            axisLabel: {
              formatter: '{value}'
              // fontSize: nowSize(16)
            }
          }
        ],
        series: [
          {
            type: 'line',
            data: this.listData,
            smooth: true,
            lineStyle: {
              color: '#00bfbf'
            },
            itemStyle: {
              color: '#00bfbf'
            }
          }
        ],
        dataZoom: [
          {
            show: false,
            id: 'dataZoomX',
            type: 'inside',
            filterMode: 'filter', // 设定为 'filter' 从而 X 的窗口变化会影响 Y 的范围。
            start: 0,
            end: 100,
            textStyle: {
              color: '#fff'
            },
            zoomLock: false
          }
        ]
      }
      return opt
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-chart-line-oxy-month {
  .sub-title {
    text-align: center;
    color: #999;
    margin-bottom: 10px;
  }
  .m-chart-core {
    height: 200px;
  }
  @media screen and (max-width: 1200px) {
    .sub-title {
      font-size: 12px;
    }
  }
}
</style>
