<template>
  <div class="other-data-view oxy-data-view" :class="layerState">
    <div
      class="inner-view"
      v-loading="loading"
      element-loading-text="数据加载中..."
    >
      <div class="title">
        <div class="name">中国天然氧吧</div>
      </div>
      <CpnProductState
        :stateType="stateType"
        :baseCount="baseCount"
        :productCount="productCount"
        v-model="countIndex"
      ></CpnProductState>
      <div class="line"></div>
      <CpnSwiperC
        class="m-city-list"
        nameKey="name"
        :list="areaList"
        v-model:index="cityIndex"
        @select="getData_oxyDatas"
      ></CpnSwiperC>
      <CpnTitleA>{{ cityItem?.name ?? '' }}24小时负氧离子浓度</CpnTitleA>
      <CpnChartLineState
        v-loading="chartLoading"
        :datas="oxyDatas"
        class="m-chart-line-state"
      ></CpnChartLineState>
      <div
        class="win-handler on-close"
        :class="layerState"
        @click="
          () => {
            if (this.layerState == 'close') {
              this.layerState = 'open'
            } else {
              this.layerState = 'close'
            }
          }
        "
      ></div>
    </div>
  </div>
</template>
<script>
import CpnTitleA from '@/components/view/title/CpnTitleA.vue'
import CpnSwiperC from '@/components/content/swiper/CpnSwiperC.vue'
import CpnProductState from './otherDataView/CpnProductState'
import CpnChartLineState from './otherDataView/CpnChartLineState'
import {
  InfoMap_areaList,
  InfoMap_areaOxyDataList
} from '@/info/infoOxyMapData.js'
export default {
  log: '',
  inject: ['MapRoot', 'PageRoot'],
  components: {
    CpnTitleA,
    CpnSwiperC,
    CpnProductState,
    CpnChartLineState
  },
  props: {},
  data() {
    return {
      loading: false,
      chartLoading: false,
      layerState: 'close',
      baseCount: 0,
      productCount: 0,
      countIndex: 1,

      stateType: 'default',
      areaList: [],
      oxyDatas: [],
      cityIndex: 0
    }
  },
  watch: {
    'MapRoot.areaCode': {
      handler() {
        this.initData()
      }
    },
    'PageRoot.pointView': {
      handler() {
        this.initData()
      }
    },
    'MapRoot.currentOverlay': {
      handler() {
        for (let i in this.areaList) {
          if (this.areaList[i].value == this.MapRoot.currentOverlay.region) {
            this.cityIndex = i
            this.getData_oxyDatas()
            break
          }
        }
      }
    }
  },
  computed: {
    cityItem() {
      return this.areaList[this.cityIndex]
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.getData_area()
    },
    getData_area() {
      let postData = this.Api.classifyArea(this.MapRoot.areaCode)
      this.loading = true
      InfoMap_areaList(postData).then((ret) => {
        this.loading = false
        this.productCount = ret?.totalCount?.totalCount ?? 0
        this.areaList = []
        this.oxyDatas = []
        if (ret.regionList?.length) {
          for (let item of ret.regionList) {
            this.areaList.push({
              name: item.regionName,
              value: item.region
            })
          }
        }
        this.getData_oxyDatas()
      })
    },
    getData_oxyDatas() {
      if (this.cityItem?.value) {
        let postData = this.Api.classifyArea(this.cityItem.value)
        this.chartLoading = true
        InfoMap_areaOxyDataList(postData).then((ret) => {
          this.chartLoading = false
          let list = []
          for (let item of ret.data) {
            list.push({
              dateTime: item.dateTime,
              value: item.negative_o2
            })
          }
          this.oxyDatas = list
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.other-data-view {
  position: relative;
  margin-top: 20px;
  width: 430px;
  height: 630px;
  padding: 5px;
  overflow: hidden;
  transition: 0.1s ease-out;
  .inner-view {
    position: absolute;
    width: 392px;
    left: 25px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    padding: 10px 10px 20px 10px;
  }
  &.close {
    width: 70px;
  }
  .title {
    padding: 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .name {
      padding: 8px 15px;
      font-size: 18px;
      border-radius: 20px;
      color: #fff;
      background: #23c4e0;
      font-weight: bold;
    }
  }
  .line {
    margin: 20px 10px 10px;
    border-bottom: 1px solid #eee;
  }
  .m-chart-pie-state {
    height: 240px;
  }
  .m-chart-line-state {
    height: 160px;
  }
  .m-city-list {
    margin-bottom: 20px;
  }
  .win-handler {
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background: #fff;
    width: 24px;
    text-align: center;
    padding: 10px 0;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &.close::after {
      content: '展开';
    }
    &:hover::after {
      color: #23c4e0;
    }
    &::after {
      content: '收起';
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
}
</style>
