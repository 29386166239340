import _ from 'lodash'
import 'ol/ol.css'
import { Map, View } from 'ol'
import { fromLonLat, toLonLat } from 'ol/proj'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import ResizeListener from 'element-resize-detector'
import TypeIs from '@/api/TypeIs'
import { defaults as defaultInteractions } from 'ol/interaction.js'
import OMU from '@/plug/map/openLayer/utils/utils.js'
import { ref } from 'vue'
export const KOMap = ref(null)

class KOClass {
  center = []
  id = 'ol-map'
  zoom = 9
  minZoom = 4
  baseMap = false
  mouseWheelZoom = true // 滚动鼠标中间的滑轮交互
  projection = 'EPSG:3857' //国标：4326 摩卡托：3857
  padding
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    let baseLayer
    if (this.baseMap) {
      baseLayer = this.getLayer_OSM()
    } else {
      baseLayer = []
    }
    if (!this.view) {
      this.view = new View({
        projection: this.projection, //使用摩卡托坐标系//EPSG:3857
        zoom: this.zoom,
        center: this.reCoord(this.center),
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        zoomFactor: 1.5
      })
    }
    if (!this.map) {
      let defInter = defaultInteractions()
      if (this.mouseWheelZoom == false) {
        // 取消滚动鼠标中间的滑轮交互
        defInter = defInter.getArray()
        defInter.splice(7, 1)
      }
      this.map = new Map({
        interactions: defInter,
        controls: [], //隐藏默认的放大缩小按钮
        target: this.id,
        layers: baseLayer,
        view: this.view
      })
      KOMap.value = this.map
      this.$mapView = this.map.getViewport()
      this.initEvent()
    }
  }
  getName(fun) {
    return typeof fun === 'function'
      ? undefined
      : fun.name || /function (.+)\(/.exec(fun + '')[1]
  }
  initEvent() {
    ResizeListener().listenTo(this.$mapView, () => {
      this.map.updateSize()
    })
  }
  getLayer_OSM() {
    //获取openlayer默认底图服务
    return [
      new TileLayer({
        opacity: 1,
        source: new OSM()
      })
    ]
  }
  getZoom() {
    return this.view.getZoom()
  }
  zoomCtrl(ret) {
    //改变地图大小，放大缩小
    let zoom = this.getZoom()
    if (ret === 'add') {
      this.view.animate({ zoom: zoom + 1, duration: 200 })
    } else if (ret === 'sub') {
      this.view.animate({ zoom: zoom - 1, duration: 200 })
    } else if (ret === 'resize') {
      this.view.animate({
        zoom: zoom,
        center: this.reCoord(this.center),
        duration: 200
      })
    }
  }
  panTo(ret) {
    let coord = []
    if (TypeIs.Array(ret)) {
      coord = ret
    } else if (TypeIs.Object(ret)) {
      coord = [ret.lon || ret.lng, ret.lat]
    }
    if (coord[0] && coord[1]) {
      this.view.animate({
        center: this.reCoord(coord),
        duration: 200
      })
    } else {
      console.error('无法执行panTo指令，传入的经纬度未定义：' + coord)
    }
  }
  defPanTo(ret) {
    let coord = []
    if (TypeIs.Array(ret)) {
      coord = ret
    } else if (TypeIs.Object(ret)) {
      coord = [ret.lon || ret.lng, ret.lat]
    }
    if (coord[0] && coord[1]) {
      this.view.animate({
        center: this.defReCoord(coord),
        duration: 200
      })
    } else {
      console.error('无法执行panTo指令，传入的经纬度未定义：' + coord)
    }
  }
  /**
   * 按照geojson显示当前地图视图区域
   * @param {Array} ret.padding //[0,0,0,0]
   * @param {Object} ret.geoJson //geoJson:{features:[]}
   */
  viewFeature(ret) {
    //geoJson:{features:[]}
    if (ret.geoJson?.features?.length) {
      const feat = OMU.getData_features({
        projection: this.projection,
        geoJson: ret.geoJson
      })
      if (feat && feat[0]) {
        const polygon = feat[0].getGeometry()
        let pd = this.padding
        if (ret.padding?.length == 4) {
          pd = ret.padding
        }
        this.view.fit(polygon, {
          padding: pd
        })
      }
    } else {
      console.error('地图方法viewFeature未传入正确的geoJson或padding:', ret)
    }
  }
  reCoord(coord) {
    let reArr = this.reArr
    if (reArr?.length == 2) {
      coord = [parseFloat(coord[0]) + reArr[0], parseFloat(coord[1]) + reArr[1]]
    }
    return this.fromLonLat(coord)
  }
  defReCoord(coord) {
    let reArr = this.reArr
    if (reArr?.length == 2) {
      coord = [parseFloat(coord[0]) + reArr[0], parseFloat(coord[1]) + reArr[1]]
    }
    return this.fromLonLat(coord)
  }
  fromLonLat(coord) {
    if (this.projection == 'EPSG:4326') {
      return coord
    } else {
      return fromLonLat(coord)
    }
  }
  toLonLat(coord) {
    if (this.projection == 'EPSG:4326') {
      return coord
    } else {
      return toLonLat(coord)
    }
  }
  setOption(opt) {
    for (let i in opt) {
      this[i] = opt[i]
    }
  }
}

export default KOClass
