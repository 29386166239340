<template>
  <div class="carea-banner">
    <swiper
      effect="fade"
      :modules="modules"
      :autoplay="true"
      :slides-per-view="1"
      :space-between="0"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="item in banner" :key="item"
        ><img :src="item.url" alt="" /><el-button
          round
          class="btn"
          @click="EventClick(item)"
          v-if="showButton"
          >立即查看</el-button
        ></swiper-slide
      >
    </swiper>
  </div>
</template>
<script>
import { Pagination, EffectFade, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import { Info_bannerList } from '@/info/infoHome.js'
export default {
  log: '',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    bannerId: {
      type: Array,
      default: () => ['1', '3', '4']
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modules: [Pagination, EffectFade, Autoplay],
      banner: []
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      Info_bannerList().then((ret) => {
        let banner = []
        if (ret.data?.length) {
          for (let item of ret.data) {
            let banUrl = this.Api.jsonData(item.banner)
            if (banUrl && this.bannerId.includes(item.id)) {
              banner.push({
                url: this.Api.getImgPath() + banUrl[0].url,
                id: item.id
              })
            }
          }
        }
        this.banner = banner
      })
    },
    EventClick(ret) {
      this.Api.routerLink({
        path: '/brandDetail',
        query: {
          id: ret.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.carea-banner {
  ::v-deep(.swiper) {
    img {
      width: 100%;
      max-height: 290px;
      object-fit: cover;
      background: #d4fbfb;
    }
    .btn {
      position: absolute;
      bottom: 40px;
      left: 50%;
      padding: 8px 20px;
      color: #4abe9d;
      font-size: 16px;
      transform: translate(-50%, 0);
    }
    .swiper-pagination {
      position: absolute;
      bottom: 20px;
      right: 10%;
      left: auto;
      width: auto;
      --swiper-pagination-bullet-inactive-opacity: 1;
      .swiper-pagination-bullet {
        width: 30px;
        background: #dfeaec;
        border-radius: 5px;

        &.swiper-pagination-bullet-active {
          background: #23c4e0;
        }
      }
    }
  }
}
</style>
