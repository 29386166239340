<template>
  <div class="m-ko-select">
    <el-select v-bind="$attrs" class="m-select" @change="EventSelect">
      <el-option
        v-for="item in $attrs.options"
        :key="item.name"
        :label="item.label || item.name"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import TypeIs from '@/api/TypeIs.js'
export default {
  log: '',
  components: {},
  emits: ['change', 'update:currentItem'],
  props: {
    currentItem: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {}
  },
  watch: {
    '$attrs.modelValue': {
      handler(val) {
        this.updateItem(val)
      },
      immediate: true
    },
    '$attrs.options': {
      handler() {
        this.updateItem(this.$attrs.modelValue)
      },
      immediate: true
    }
  },
  computed: {},
  mounted() {},
  methods: {
    updateItem(ret) {
      let item = {}
      if (TypeIs.String(ret) || TypeIs.Number(ret)) {
        if (ret !== '') {
          item = this.getItem(ret)
        }
      } else if (TypeIs.Object(ret)) {
        item = this.getItem(ret.value)
      }
      this.$emit('update:currentItem', item)
    },
    getItem(value) {
      return this.Api.getItem({
        list: this.$attrs.options,
        value: value ?? this.$attrs.modelValue
      })
    },
    EventSelect(ret) {
      let item = this.getItem(ret)
      this.$emit('change', item)
    }
  }
}
</script>
<style lang="scss">
.m-select {
  width: 100%;
}
</style>
