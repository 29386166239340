import TypeIs from '@/api/TypeIs.js'
export function encode(t) {
  if (t) {
    return encodeURI(t)
  } else {
    return t
  }
}
export function decode(t) {
  if (t) {
    return decodeURI(t)
  } else {
    return t
  }
}
export function obj2para(json, slice = '&') {
  return Object.keys(json)
    .reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice
    }, '')
    .slice(0, -1)
}
export function isNumber(str) {
  if (!isNaN(parseFloat(str))) {
    return true
  } else {
    return false
  }
}
export function base64(url) {
  if (url) {
    return 'data:image/png;base64,' + url
  } else {
    console.error('注意：图片路径为空：', url)
    return ''
  }
}
export function getBase64File(fileBase64) {
  let fileHeader = new Map()
  //获取不同文件的文件头前3个字作为判断依据
  fileHeader.set('/9j', 'JPG')
  fileHeader.set('iVB', 'PNG')
  fileHeader.set('Qk0', 'BMP')
  fileHeader.set('SUk', 'TIFF')
  fileHeader.set('JVB', 'PDF')
  fileHeader.set('UEs', 'OFD')
  let res = ''
  //遍历map中所提及的文件头特征
  fileHeader.forEach((v, k) => {
    if (k == fileBase64.substr(0, 3)) {
      res = v
    }
  })
  //如果不在map中返回unknown file
  if (res == '') {
    res = false
  }
  //否则返回map中的value值
  return res
}
/**
 * 保留2位小数
 * @param {String或Number} input //字符或数字
 * @param {Number} decimalPlaces //保留几位小数
 * @param {Boolean} shouldRound //是否需要四舍五入
 * @returns
 */
export function keep2(input, decimalPlaces = 2, shouldRound) {
  if (typeof decimalPlaces !== 'number' || decimalPlaces < 0) {
    //console.error('参数2：小数位数无效')
    return input
  }
  const number = parseFloat(input)
  if (isNaN(number)) {
    //console.error('参数1：请传入正确的数字')
    return input
  }
  let roundedNumber
  if (shouldRound) {
    roundedNumber = parseFloat(number.toFixed(decimalPlaces))
  } else {
    const factor = 10 ** decimalPlaces
    roundedNumber = Math.floor(number * factor) / factor
  }
  return roundedNumber
}
export function removeZero(ret) {
  var areaCode
  if (ret && TypeIs.Number(ret)) {
    areaCode = ret.toString()
    return areaCode.replace(/(00$)|(0000$)/g, '')
  } else if (TypeIs.String(ret)) {
    areaCode = ret
    return areaCode.replace(/(00$)|(0000$)/g, '')
  } else {
    console.log('areaCode未定义或不是有效行政编码格式:', areaCode)
    return ret
  }
}
export function jsonData(ret) {
  if (ret) {
    if (TypeIs.String(ret)) {
      if (isJSON(ret)) {
        return new Function('return ' + ret)()
      } else {
        return ret
      }
    }
  }
  return ret
}
export function randomString(len) {
  let rdmString = ''
  for (
    ;
    rdmString.length < len;
    rdmString += Math.random().toString(36).substring(2).toUpperCase()
  );
  return rdmString.substring(0, len)
}
export function isJSON(str) {
  if (TypeIs.Object(str) || TypeIs.Array(str)) {
    return true
  } else {
    let obj = null
    try {
      obj = eval('(' + str + ')')
    } catch (e) {
      return false
    }
    if (TypeIs.Object(obj) || TypeIs.Array(obj)) {
      return true
    } else {
      return false
    }
  }
}

export function copy(str) {
  if (
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === 'function'
  ) {
    // 使用 Clipboard API
    return navigator.clipboard.writeText(str)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    // 使用 document.execCommand('copy')
    const textarea = document.createElement('textarea')
    textarea.value = str
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    return true
  } else {
    console.warn('此浏览器不支持复制功能')
    return false
  }
}

/**
 * Debouncer 类用于防止函数在短时间内被频繁调用。
 *
 * @param {Function} func - 需要防抖的函数。
 * @param {number} [wait=300] - 防抖的等待时间，默认为300毫秒。
 * @param {boolean} [leading=false] - 是否在防抖开始时立即执行函数，默认为false。
 */
export class Debouncer {
  /**
   * 构造函数
   *
   * @param {Function} func - 需要防抖的函数。
   * @param {number} [wait=300] - 防抖的等待时间，默认为300毫秒。
   * @param {boolean} [leading=false] - 是否在防抖开始时立即执行函数，默认为false。
   */
  constructor(func, leading = false, wait = 100) {
    this.func = func
    this.wait = wait
    this.timeout = null
    this.leading = leading
    this.immediate = leading
  }
  /**
   * 调用需要防抖的函数。
   *
   * @param {...any} args - 传递给需要防抖函数的参数。
   */
  call(...args) {
    const callNow = this.leading && this.immediate
    const later = () => {
      this.timeout = null
      if (!this.leading) {
        this.func.apply(this, args)
      }
      this.immediate = this.leading
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(later, this.wait)
    if (callNow) {
      this.func.apply(this, args)
      this.immediate = false
    }
  }
}

export function getKeyByItem(keys = [], item) {
  for (let key of keys) {
    if (item[key] || item[key] === 0) {
      return item[key]
    }
  }
  console.error('item中未找到keys中的字段', keys, item)
}

/**
 * 对包含对象的数组进行排序，依据对象中指定键的字符串值。
 *
 * @param {Object[]} arr - 要排序的对象数组。
 * @param {string} key - 用于排序的对象键。
 * @returns {Object[]} - 排序后的对象数组。
 */
export function sortArrayByKey(arr, key) {
  return arr.sort((a, b) => {
    if (a[key] > b[key]) {
      return -1
    }
    if (a[key] < b[key]) {
      return 1
    }
    return 0
  })
}

/**
 * 创建一个函数，确保给定的异步操作在相同参数下只执行一次。
 * 使用 asyncOnce 包装 fetchData 函数
 * const fetchOnce = asyncOnce(fetchData);
 * @param {Function} fn - 一个异步回调函数，接受任意数量的参数，并返回一个 Promise。
 * @returns {Function} 返回一个新的函数，接受任意数量的参数。
 */
export function asyncOnce(fn) {
  // 创建一个对象，存储每个参数组合的 Promise 状态
  const map = {}
  /**
   * 返回一个新的函数，接受任意数量的参数。
   *
   * @param {...any} args - 传递给回调函数的参数。
   * @returns {Promise<any>} 返回一个 Promise，表示异步操作的结果。
   */
  return (...args) => {
    // 将参数序列化为字符串，作为唯一的键
    const key = JSON.stringify(args)

    // 如果 map 中没有这个键，则创建一个新的 Promise
    return (map[key] ||= new Promise((resolve, reject) => {
      // 调用回调函数 fn，传入参数
      fn(...args)
        .then(resolve) // 如果成功，调用 resolve
        .catch(reject) // 如果失败，调用 reject
        .finally(() => {
          // 无论成功或失败，最终将 map 中的该键设置为 null
          map[key] = null
        })
    }))
  }
}

//排查数据用
export function readDictByKey(list, key) {
  let dict = {}
  for (let item of list) {
    let el = item[key]
    if (typeof dict[el] == 'undefined') {
      dict[el] = []
    }
    dict[el].push(item)
  }
}

/**
 * 生成拼接的 URL 列表
 *
 * @param {string} baseUrl - 基础 URL，例如 'http://example.com/resource/'
 * @param {string} leng - 长度
 * @returns {string[]} 返回拼接后的 URL 数组，从 '001' 到 '030'
 */
export function generateUrls(baseUrl, leng = 30) {
  const urls = []
  for (let i = 1; i <= leng; i++) {
    // 格式化数字为三位数
    const formattedIndex = String(i).padStart(3, '0')
    // 拼接 URL
    urls.push(`${baseUrl}${formattedIndex}.jpg`)
  }
  return urls
}
