import Api from '@/api/Api.js'
import TypeIs from '@/api/TypeIs.js'
import DictLive from '@/api/dict/DictLive.js'
/**
 * @数据验证
 */
export function DataVer(data, key) {
  if (key) {
    for (let i in key) {
      if (typeof data[i] === 'undefined' || data[i] === '') {
        Api.msg(data[i] + '为必填(选)项')
        return false
      }
    }
  } else {
    for (let i in data) {
      if (data[i] === '') {
        Api.msg('表单未填写(选择)完整，请完善必填(选)项')
        return false
      }
    }
  }
  return true
}
/**
 * @时间纠错
 */
export function DataCorr(key) {
  let types = {
    datetime: '4',
    date: '3',
    xun: '5',
    month: '1',
    ji: '6',
    year: '2'
  }
  return types[key] ?? ''
}

/**
 * @时间查找
 */
export function DataFind(ret) {
  let def = {
    labels: [],
    data: {}
  }
  let opt = Object.assign({}, def, ret)
  let arr = []
  for (let label of opt.labels) {
    for (let item in opt.data) {
      if (label?.key?.indexOf(item) >= 0) {
        let obj = {
          prop: item,
          label: label.name,
          name: label.name
        }
        if (label.slotName) {
          obj.slotName = label.slotName
        }
        if (label.width) {
          obj.width = label.width
        }
        arr.push(obj)
      }
    }
    if (label.slotKey) {
      arr.push({
        ...label,
        label: label.name || label.label
      })
    }
  }
  return arr
}

/**
 * @时间查找
 */
export function reList_table(ret) {
  let def = {
    list: []
  }
  let opt = Object.assign({}, def, ret)
  let dataList = opt.list
  let list = []
  if (dataList?.length) {
    for (let item of dataList) {
      if (item.lat) {
        item.lat = Math.round(item.lat * 10000) / 10000
      } else if (item.lon) {
        item.lon = Math.round(item.lon * 10000) / 10000
      }
      list.push(item)
    }
  }
  return list
}

/**
 * @表数据head改为中文
 * [{key:'',name:''}]
 */
export function reHead_table(heads, list) {
  let reTable = []
  for (let item of list) {
    let obj = {}
    for (let head of heads) {
      if (typeof item[head.prop] !== 'undefined') {
        obj[head.name] = item[head.prop]
      }
    }
    reTable.push(obj)
  }
  return reTable
}

/**
 * @获取表数据
 */
export function getTableView(ret) {
  let def = {
    head: [],
    list: [],
    sortKey: 'stationNum'
  }
  let opt = Object.assign({}, def, ret)
  let list = []
  let head = []
  if (opt.list?.length) {
    list = reList_table({ list: opt.list })
    head = DataFind({
      labels: opt.head,
      data: opt.list[0]
    })
  }
  if (opt.sortKey) {
    list = list.sort((a, b) => {
      return a[opt.sortKey] - b[opt.sortKey]
    })
  }
  return { list, head }
}

/**
 * @数据纠错
 */
export function dataCorrection(item, defaultStr) {
  let defStr = defaultStr ?? '缺测'
  let obj = {}
  for (let key in item) {
    if (TypeIs.Number(item[key])) {
      let name = DictLive.nameDict(key)
      if (name) {
        if (item[key] > 9999 || item[key] < -9999) {
          obj[key] = defStr
        } else {
          obj[key] = item[key]
        }
      } else {
        obj[key] = item[key]
      }
    } else if (TypeIs.Object(item[key])) {
      if (TypeIs.Number(item[key].value)) {
        let name = DictLive.nameDict(key)
        if (name) {
          if (item[key].value > 9999 || item[key].value < -9999) {
            obj[key] = item[key]
            obj[key].value = defStr
          } else {
            obj[key] = item[key]
          }
        } else {
          obj[key] = item[key]
        }
      }
    } else {
      obj[key] = item[key]
    }
  }
  return obj
}

/**
 * 把列表数据中指定key的数据拉出来，形成父级数组。
 * @param {Array} list //数据列表
 * @param {String} key //待拉出来的key
 * @param {Object} dict //字典对象，key用于匹配list中的key值
 * @returns //返回新的父级数组，一级数组是用key生成的数组
 */
export function assembleList(ret) {
  let def = {
    list: [],
    key: '',
    dict: {}
  }
  let opt = Object.assign({}, def, ret)
  let dict = {}
  for (let item of opt.list) {
    if (opt.dict[item[opt.key]]) {
      if (typeof dict[item[opt.key]] == 'undefined') {
        dict[item[opt.key]] = {
          name: opt.dict[item[opt.key]],
          value: item[opt.key],
          list: []
        }
      }
      dict[item[opt.key]].list.push(item)
    }
  }
  let list = []
  for (let i in dict) {
    list.push(dict[i])
  }
  return list
}

export function getAreaCodeLevel(areaCode) {
  if (areaCode == 100000) {
    return 0
  } else if (areaCode?.toString()?.substr(2, 4) == '0000') {
    return 1
  } else if (areaCode?.toString()?.substr(4, 2) == '00') {
    return 2
  } else {
    return 3
  }
}

export function isCityLevel(areaCode) {
  const directMunicipalities = [110000, 120000, 310000, 500000]
  return (
    getAreaCodeLevel(areaCode) == 2 || directMunicipalities.includes(areaCode)
  )
}
export function getParentAreaCode(ret) {
  let areaCode = ret.toString()
  let level = getAreaCodeLevel(areaCode)
  if (level == 3) {
    return areaCode.substr(0, 4) + '00'
  } else if (level == 2) {
    return areaCode.substr(0, 2) + '0000'
  } else if (level <= 1) {
    return '100000'
  }
}

export function setHeaderMeta(to) {
  const { title, keywords, description } = to.meta
  // 详情页标题
  const detailTitle = to.params?.title
  if (detailTitle) {
    document.title = title ? `${title}${detailTitle}` : ''
  } else {
    document.title = title ? `${title}` : ''
  }
  const keywordsMeta = document.querySelector('meta[name="keywords"]')
  keywordsMeta?.setAttribute('content', `${keywords}`)
  const descriptionMeta = document.querySelector('meta[name="description"]')
  descriptionMeta?.setAttribute('content', `${description}`)
}
