/**
 * 将折线的直角转换为圆角
 * @param {Array<Array<number>>} polyline - 折线的点数组，每个点是一个包含两个数值的数组，表示点的x和y坐标
 * @param {number} radius - 圆角的半径
 * @return {Array<Array<number>>} - 处理后的折线数组，直角已被替换为圆角
 */
export function roundCorners(polyline, radius = 0.6) {
  var result = []
  // 遍历折线的每个点（除了第一个和最后一个）
  for (var i = 1; i < polyline.length - 1; i++) {
    var p0 = polyline[i - 1] // 前一个点
    var p1 = polyline[i] // 当前点（折点）
    var p2 = polyline[i + 1] // 后一个点
    // 计算从前一个点到当前点，以及从后一个点到当前点的向量
    var v0 = [p1[0] - p0[0], p1[1] - p0[1]]
    var v2 = [p1[0] - p2[0], p1[1] - p2[1]]
    // 计算这两个向量的长度
    var len0 = Math.sqrt(v0[0] * v0[0] + v0[1] * v0[1])
    var len2 = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1])
    // 将这两个向量标准化（使它们的长度为1）
    v0[0] /= len0
    v0[1] /= len0
    v2[0] /= len2
    v2[1] /= len2
    // 计算这两个向量之间的角度
    var cosTheta = v0[0] * v2[0] + v0[1] * v2[1]
    var theta = Math.acos(cosTheta)
    var dist = radius / Math.tan(theta / 2.0)
    // 计算圆弧的两个端点
    var pA = [p1[0] - v0[0] * dist, p1[1] - v0[1] * dist]
    var pB = [p1[0] - v2[0] * dist, p1[1] - v2[1] * dist]
    result.push(pA)
    // 在圆弧的两个端点之间插入一段圆弧
    var segments = 8 // 圆弧的段数
    for (var j = 1; j < segments; j++) {
      var t = j / segments
      var a = (1 - t) * (1 - t)
      var b = 2 * (1 - t) * t
      var c = t * t
      var p = [
        a * pA[0] + b * p1[0] + c * pB[0],
        a * pA[1] + b * p1[1] + c * pB[1]
      ]
      result.push(p)
    }

    result.push(pB)
  }

  // 添加第一个和最后一个点
  result.unshift(polyline[0])
  result.push(polyline[polyline.length - 1])

  return result
}
