import { Info_brandCity, Info_brandTravel } from '@/info/infoHome.js'
import {
  InfoMap_pieProductList,
  InfoMap_pieApplyProductList,
  InfoMap_lineProductList
} from '@/info/infoOxyMapData.js'
import { getPostDataByAreaCode } from '@/work/workCommon.js'
import { getAreaCodeLevel } from '@/api/func/DataPro.js'
export function Inter_OtherDataView(ret) {
  let postData = getPostDataByAreaCode(ret.areaCode)
  postData.brandType = ret.brandType
  postData.areaCode = ret.areaCode
  postData.years = ret.years
  if (ret.brandType == 3) {
    return Promise_brandOxy(postData)
  } else if (ret.brandType == 4) {
    return Promise_brandCity(postData)
  } else if (ret.brandType == 5) {
    return Promise_brandTravel(postData)
  }
  return Promise.reject()
}

//返回的数据
//{bar:[{name:'',value:''}]}
//{line:[{name:'',value:''}]}
function Promise_brandOxy(postData) {
  return new Promise((res) => {
    let level = getAreaCodeLevel(postData.areaCode)
    let params = {
      brandType: postData.brandType,
      years: postData.years,
      province: postData.province,
      city: postData.city,
      region: postData.region
    }
    Promise.all([
      InfoMap_pieProductList(params),
      InfoMap_pieApplyProductList(params),
      InfoMap_lineProductList(params)
    ]).then((rets) => {
      let pie = [[], []],
        line = [[], []]
      if (level == 0) {
        pie[0] = getListByData(rets[0].data.zoneList)
        line[0] = getListByData(rets[0].data.provinceList)
        pie[1] = getListByData(rets[1].data.zoneList)
        line[1] = getListByData(rets[1].data.provinceList)
      } else if (level >= 1) {
        let yearList = rets[2].data.sort((a, b) => {
          return a.buildDate - b.buildDate
        })
        pie[0] = getListByData(rets[0].data.cityList)
        line[0] = getListByData(yearList)
        pie[1] = getListByData(rets[1].data.cityList)
        line[1] = getListByData(yearList)
      }
      res({
        pie,
        line
      })
    })
  })
}

function getListByData(data) {
  let list = []
  for (let item of data) {
    list.push({
      name:
        item.name ||
        item.provinceName ||
        item.cityName ||
        item.regionName ||
        item.buildDate,
      value: item.total
    })
  }
  return list
}

function Promise_brandCity(postData) {
  return new Promise((res) => {
    Info_brandCity(postData)
      .then((ret) => {
        console.log(ret)
        let pie = [[], []],
          line = [[], []],
          totalCount = ret.publish
        //全国现有总数
        for (let item of ret.chartPublish) {
          pie[0].push({
            name: item[0],
            value: item[1]
          })
          line[0].push({
            name: item[0],
            value: item[1]
          })
        }
        //今年申报总数
        for (let item of ret.chartApplying) {
          pie[1].push({
            name: item[0],
            value: item[1]
          })
          line[1].push({
            name: item[0],
            value: item[1]
          })
        }
        res({
          pie,
          line,
          totalCount
        })
      })
      .catch((err) => {
        console.log(err)
      })
  })
}

function Promise_brandTravel(postData) {
  return new Promise((res) => {
    Info_brandTravel(postData)
      .then((ret) => {
        let pie = [[], []],
          line = [[], []],
          totalCount = ret.publish
        //全国现有总数
        for (let item of ret.chartPublish) {
          pie[0].push({
            name: item[0],
            value: item[1]
          })
          line[0].push({
            name: item[0],
            value: item[1]
          })
        }
        //今年申报总数
        for (let item of ret.chartApplying) {
          pie[1].push({
            name: item[0],
            value: item[1]
          })
          line[1].push({
            name: item[0],
            value: item[1]
          })
        }
        res({
          pie,
          line,
          totalCount
        })
      })
      .catch((err) => {
        console.log(err)
      })
  })
}
