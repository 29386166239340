import { ref } from 'vue'
import { InfoMap_adminDivi, InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import FeaturePolygonLabel from '@/plug/map/openLayer/polygon/FeaturePolygonLabel.js'
import { KOMap } from '@/plug/map/openLayer/initMap.js'
import { viewBounds } from '@/plug/map/openLayer/utils/utilsFn.js'
const AdminPlug = ref(null)
const geojsonInner = ref(null)
const geojsonOuter = ref(null)

export function getAdminDivi() {
  let areaCode = '100000'
  Promise.all([
    InfoMap_adminDivi({ areaCode }),
    InfoMap_adminDiviOuter({ areaCode })
  ])
    .then((art) => {
      if (art[0]?.features?.length) {
        geojsonInner.value = art[0]
      }
      if (art[1]?.features?.length) {
        //外轮廓
        geojsonOuter.value = art[1]
      }
      setMap_geojson()
    })
    .catch((err) => {
      console.log(err)
    })
}

function setMap_geojson() {
  console.log(geojsonInner.value)
  viewBounds(KOMap.value, [72, 17, 135, 54])
  if (!AdminPlug.value) {
    AdminPlug.value = new FeaturePolygonLabel({
      map: KOMap.value,
      geojsonData: geojsonInner.value,
      showText: true,
      zIndex: 45
    })
  }
}
