<template>
  <div class="cpn-tab-map">
    <ul class="m-tabs">
      <li
        :class="modelValue == index ? 'current' : ''"
        v-for="(item, index) in tabs"
        :key="item"
        @click="EventSelect(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  emits: ['update:modelValue', 'select'],
  props: {
    modelValue: {
      type: [String, Number],
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSelect(item, index) {
      this.$emit('update:modelValue', index)
      this.$emit('select', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-tab-map {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  .m-tabs {
    display: flex;
    font-size: 20px;
    li {
      position: relative;
      flex-grow: 1;
      cursor: pointer;
      padding: 5px 0 15px;
      text-align: center;
      &.current {
        font-weight: bold;
        &:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          content: '';
          display: block;
          width: 60%;
          max-width: 100px;
          transform: translate(-50%, 0);
          border-bottom: 3px solid #23c4e0;
        }
      }
      &:hover {
        color: var(--theme-color);
      }
    }
  }
}
</style>
