// 2.根据process.env.NODE_ENV区分
// 开发环境: development
// 生成环境: production
// 测试环境: test

let BASE_URL
let MOCK_URL =
  'http://112.126.94.55:7300/mock/62ce3c7307c7d611d802c874/scnongqi'
const TIME_OUT = 60000

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://www.defengenong.cn:8020/'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'http://www.defengenong.cn:8020/'
}

export { BASE_URL, MOCK_URL, TIME_OUT }
