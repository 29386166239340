import { JsonAjax as DefAjax } from '../service/ajax.js'
/**
 * @负氧离子地图数据
 */
export function InfoMap_oxyMapMarkers(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: '/api/brand/oxygen/negative/map/statistics',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @区域列表数据
 */
export function InfoMap_areaList(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/brand/oxygen/negative/statistics/region',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @城市负氧离子小时数据列表
 */
export function InfoMap_areaOxyDataList(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/brand/oxygen/negative/region/hour/data',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @产品数量统计
 */
export function InfoMap_productCount(ret) {
  let def = { brandType: '', years: '', province: '', city: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/project/product/publish/count',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @产品数量统计_饼图
 */
export function InfoMap_pieProductList(ret) {
  let def = { brandType: '', years: '', province: '', city: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/project/product/publish/region/statistics',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @产品申报数量统计_饼图
 */
export function InfoMap_pieApplyProductList(ret) {
  let def = { brandType: '', province: '', city: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/project/product/apply/region/statistics',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @产品数量统计_线图
 */
export function InfoMap_lineProductList(ret) {
  let def = { brandType: '', years: '', province: '', city: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/project/product/publish/time/statistics',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}
