<template>
  <div class="cpn-tab-shenbao">
    <ul class="m-tabs">
      <li
        :class="modelValue == index ? 'current' : ''"
        v-for="(item, index) in tabs"
        :key="item"
        @click="EventSelect(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  emits: ['update:modelValue', 'select'],
  props: {
    modelValue: {
      type: [String, Number],
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSelect(item, index) {
      this.$emit('update:modelValue', index)
      this.$emit('select', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-tab-shenbao {
  .m-tabs {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    li {
      position: relative;
      flex-grow: 1;
      cursor: pointer;
      padding: 10px 20px 15px;
      text-align: center;
      font-size: 20px;
      background: #e0f5f8;
      &:first-child {
        border-top-left-radius: 30px;
        &:after {
          display: none;
        }
      }
      &:last-child {
        border-bottom-right-radius: 30px;
        &:after {
          display: none;
        }
      }
      &.current {
        background: #23c4e0;
        font-weight: bold;
        color: #fff;
        &:after {
          display: none;
        }
      }
      &:after {
        position: absolute;
        right: 0;
        top: 15px;
        bottom: 15px;
        content: '';
        display: block;
      }
    }
  }
}
</style>
