import { JsonAjax as DefAjax } from '../service/ajax.js'
import { Base64 } from 'js-base64'
/**
 * @获取用户信息
 */
export function Info_userInfo() {
  return DefAjax({
    save: false,
    url: '/henan/sys/user/info',
    method: 'get'
    //load: 'mock'
  })
}

/**
 * @获取登录token
 */
export function Info_login(ret) {
  let def = { userName: '', password: '' }
  let opt = Object.assign({}, def, ret)
  opt.password = Base64.encode(opt.password)
  return DefAjax({
    save: false,
    postType: 'formData',
    url: '/henan/sys/login',
    method: 'post',
    data: opt
    //load: 'mock'
  })
}

/**
 * @退出登录
 */
export function Info_logout(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    save: false,
    url: '/henan/sys/logout',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}
