/**
 * 将颜色转换为不同格式的表示形式
 * @param {string|array} color - 输入的颜色，可以是字符串（hex、rgb、rgba）或数组
 * @param {string} format - 目标格式，可以是 'hex', 'rgb', 'rgba', 'arr'
 * @returns {string|array} - 转换后的颜色
 * @throws {Error} - 如果提供的格式无效或颜色无法解析
 */
export function colorFormat(color, format = 'hex') {
  if (format === 'hex') {
    return color2hex(color)
  } else if (format === 'rgb') {
    return color2rgb(color)
  } else if (format === 'rgba') {
    return color2rgba(color)
  } else if (format === 'arr') {
    return color2arr(color)
  } else {
    console.error('不支持的格式', color)
  }
}

/**
 * 将颜色转换为 HEX 格式
 * @param {string|array} color - 输入的颜色，可以是字符串（hex、rgb、rgba）或数组
 * @returns {string} - 转换后的 HEX 格式颜色
 * @throws {Error} - 如果颜色无法解析
 */
export function color2hex(color) {
  return rgb2hex(color2arr(color))
}

/**
 * 将颜色转换为 RGB 格式
 * @param {string|array} color - 输入的颜色，可以是字符串（hex、rgb、rgba）或数组
 * @returns {string} - 转换后的 RGB 格式颜色
 * @throws {Error} - 如果颜色无法解析
 */
export function color2rgb(color) {
  const rgbArray = color2arr(color)
  return `rgb(${rgbArray.join(', ')})`
}

/**
 * 将颜色转换为 RGBA 格式
 * @param {string|array} color - 输入的颜色，可以是字符串（hex、rgb、rgba）或数组
 * @returns {string} - 转换后的 RGBA 格式颜色
 * @throws {Error} - 如果颜色无法解析
 */
export function color2rgba(color) {
  if (color) {
    const rgbaArray = color2arr(color)
    return `rgba(${rgbaArray.join(', ')})`
  } else {
    return color
  }
}

/**
 * 将颜色转换为 RGB 格式的数组
 * @param {string|array} color - 输入的颜色，可以是字符串（hex、rgb、rgba）或数组
 * @returns {array} - 转换后的 RGB 格式颜色数组
 * @throws {Error} - 如果颜色无法解析
 */
export function color2arr(color) {
  if (typeof color === 'string') {
    if (color.startsWith('#')) {
      return hex2rgb(color)
    } else if (color.startsWith('rgb')) {
      return color.match(/\d+/g).map(Number).slice(0, 3)
    } else if (color.startsWith('rgba')) {
      return color.match(/\d+/g).map(Number).slice(0, 3)
    }
  } else if (Array.isArray(color)) {
    return color.slice(0, 3)
  }
  return color
}

/**
 * 将 HEX 格式颜色转换为 RGB 数组
 * @param {string} hex - 输入的 HEX 格式颜色
 * @returns {array} - 转换后的 RGB 格式颜色数组
 */
export function hex2rgb(hex) {
  hex = hex.replace(/^#/, '')
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return [r, g, b]
}

/**
 * 将 RGB 格式颜色转换为 HEX 格式
 * @param {string|array} rgb - 输入的 RGB 格式颜色
 * @returns {string} - 转换后的 HEX 格式颜色
 * @throws {Error} - 如果颜色无法解析
 */
function rgb2hex(rgb) {
  if (typeof rgb === 'string') {
    const match = rgb.match(/\d+/g)
    if (match) {
      const r = parseInt(match[0])
      const g = parseInt(match[1])
      const b = parseInt(match[2])
      return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`
    }
  } else if (Array.isArray(rgb) && rgb.length >= 3) {
    const r = rgb[0]
    const g = rgb[1]
    const b = rgb[2]
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`
  }

  throw new Error('无法解析的颜色值')
}
