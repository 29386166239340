import _ from 'lodash'
import '@/api/protoFn/Color.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Collection from 'ol/Collection.js'
import GeoJSON from 'ol/format/GeoJSON'
import { Group as LayerGroup } from 'ol/layer'
import { Fill, Stroke, Style } from 'ol/style'
import Select from 'ol/interaction/Select'
import OMU from '@/plug/map/openLayer/utils/utils.js'
class KOClass {
  mapPlug
  layerId = 'featurePolygon'
  data = {
    features: [
      {
        geometry: { coordinates: [], type: 'Polygon' },
        properties: { name: 'EPSG:3857' },
        type: 'Feature'
      }
    ],
    type: 'FeatureCollection'
  }
  defaultStyle = {
    stroke: new Stroke({
      color: 'rgba(22, 127, 230, 1)',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(22, 127, 230, 0.2)'
    })
  }
  selectStyle = {
    stroke: new Stroke({
      color: 'rgba(200, 240, 255, 0.8)',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(0, 146, 229, 0.1)'
    })
  }
  zIndex = 20
  hoverEvent = true
  constructor(config) {
    _.merge(this, config)
    this.map = this.mapPlug.map
    this.init()
  }
  init() {
    this.initFeatureStyle(this.defaultStyle, this.selectStyle)
    this.addLayerPolygon(this.data)
    if (this.hoverEvent) {
      this.initMouseHoverEvent()
    }
  }
  update(ret) {
    if (ret.defaultStyle && ret.selectStyle) {
      this.initFeatureStyle(ret.defaultStyle, ret.selectStyle)
    }
    let coll = this.source.getFeaturesCollection()
    coll.clear()
    if (ret.data?.features?.length) {
      let features = this.getFeaturesByGeoJson(ret.data)
      if (features?.length) {
        coll.extend(features)
      }
    }
  }
  initFeatureStyle(defaultStyle, selectStyle) {
    if (defaultStyle) {
      this.defaultStyle = defaultStyle
    }
    if (selectStyle) {
      this.selectStyle = selectStyle
    }
    this.polygonSelected = [new Style(this.selectStyle)]
    this.polygonDefault = [new Style(this.defaultStyle)]
  }
  /**
   * 外部单独设置区划特征的颜色
   * @param {String} ret.stroke //边框色
   * @param {String} ret.fill //填充色
   * @param {String,Number} ret.border //边框大小
   */
  setFeatureStyle(ret) {
    let style = getPolygonStyle(ret)
    this.layer.setStyle(new Style(style))
  }
  /**
   * 事件：移上多边形变更多边形的颜色
   */
  initMouseHoverEvent() {
    this.selectHover = new Select({
      condition: (ret) => {
        if (ret.type == 'pointermove') {
          return true
        }
      },
      layers: (ret) => {
        let proper = ret.getProperties()
        if (proper.layerId && proper.layerId == this.layerId) {
          return true
        }
      },
      style: (feature) => {
        if (feature.selectEvent) {
          return feature.selectStyle
        } else {
          return feature.defaultStyle
        }
      }
    })
    //使用此方法捕获事件对应的feature
    this.mapPlug.map.addInteraction(this.selectHover)
  }
  getFeaturesByGeoJson(ret) {
    let geoJson
    if (this.mapPlug.projection == 'EPSG:4326') {
      //如果地图是国标，则不转换直接输出
      geoJson = OMU.getGeojson_4326(ret)
    } else {
      //如果地图是摩卡托，则需要把国标转换为摩卡托再输出
      geoJson = OMU.getGeojson_3857(ret)
    }
    return this.getGeoFeatures(geoJson)
  }
  addLayerPolygon(geoJson) {
    let feats = this.getFeaturesByGeoJson(geoJson)
    this.source = new VectorSource({
      features: new Collection(feats)
    })
    this.layer = new VectorLayer({
      properties: { layerId: this.layerId },
      source: this.source,
      style: this.polygonDefault,
      zIndex: this.zIndex
    })
    this.layerGroup = new LayerGroup({
      layers: [this.layer],
      zIndex: this.zIndex
    })
    this.mapPlug.map.addLayer(this.layerGroup)
  }
  getGeoFeatures(jsonObj) {
    let feats = new GeoJSON().readFeatures(jsonObj)
    for (let i = 0; i < feats.length; i++) {
      feats[i].index = i
      feats[i].selectEvent = true
      feats[i].defaultStyle = this.polygonDefault
      feats[i].selectStyle = this.polygonSelected
      if (jsonObj.features[i].properties) {
        feats[i].data = jsonObj.features[i].properties
      }
      feats[i].featureType = this.layerId
    }
    return feats
  }
  remove(layerGroup) {
    if (layerGroup) {
      let layers = layerGroup.getLayers()
      if (layers) {
        layers.clear()
      }
    }
  }
}

export default KOClass

export function getPolygonStyle(ret) {
  return {
    stroke: new Stroke({
      color: ret.stroke,
      width: ret.border ?? 1
    }),
    fill: new Fill({
      color: ret.fill
    })
  }
}
