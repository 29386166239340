<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-10-08 14:01:44
-->
<template>
  <div class="cpn-oxy-map-legend">
    <div class="name">数量统计</div>
    <ul class="m-legend">
      <li class="legend-item" v-for="item in oxyLegend" :key="item">
        <div class="legend-color" :style="{ background: item.color }"></div>
        <div class="legend-text">{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { oxyLegend } from '../js/MapPlugAdminPolygon.js'
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      oxyLegend
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-oxy-map-legend {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .name {
    margin-right: 10px;
    color: #0f7e8e;
    font-size: 16px;
  }
  .m-legend {
    display: flex;
    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .legend-color {
      width: 28px;
      height: 13px;
      margin-right: 5px;
      border: 1px solid #b3c7c9;
    }
    .legend-text {
      font-size: 12px;
    }
  }
}
</style>
