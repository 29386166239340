<!--marker组件：markerOverlay-->
<template>
  <div class="cn-map-marker-over-list">
    <div
      class="module-marker-overlay"
      v-for="item in list"
      :key="item"
      ref="RMarkerOverlay"
    >
      <slot name="markerOverlay" :prop="item"></slot>
    </div>
  </div>
</template>
<script>
import { Overlay } from 'ol'
export default {
  inject: ['MapRoot'],
  components: {},
  props: {
    mapPlug: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      customOverlays: []
    }
  },
  watch: {
    list: {
      handler() {
        this.addCustomPointOverlay()
      }
    }
  },
  computed: {},
  mounted() {
    this.addCustomPointOverlay()
    this.resolutionChangeListener = this.mapPlug.map
      .getView()
      .on('change:center', this.updateOverlays)
  },
  beforeUnmount() {
    // 移除地图视图变化事件监听器
    if (this.resolutionChangeListener) {
      this.mapPlug.map.getView().un('change:center', this.updateOverlays)
    }
    // 清除所有自定义点Overlay
    this.clearCustomPointOverlays()
  },
  methods: {
    addCustomPointOverlay() {
      // 先清除之前的自定义点Overlay
      if (this.list?.length) {
        this.$nextTick(() => {
          this.clearCustomPointOverlays()
          this.list.forEach((point, index) => {
            let coordinate = [point.lon, point.lat] // 将经纬度坐标转换为地图坐标
            // 创建一个包含自定义HTML内容的Overlay
            let overlay = new Overlay({
              position: this.mapPlug.fromLonLat(coordinate),
              element: this.$refs.RMarkerOverlay[index], // 创建自定义HTML内容的容器
              stopEvent: true,
              positioning: 'center-center',
              offset: [0, 0]
            })
            // 将自定义点Overlay添加到地图中
            this.mapPlug.map.addOverlay(overlay)
            overlay.getElement().addEventListener('click', () => {
              var pixel = this.mapPlug.map.getPixelFromCoordinate(
                this.mapPlug.fromLonLat([point.lon, point.lat])
              )
              this.$emit('clickOverlay', {
                ...point,
                left: pixel[0],
                top: pixel[1]
              })
            })
            this.customOverlays.push(overlay)
          })
        })
      } else {
        this.clearCustomPointOverlays()
      }
    },
    updateOverlays() {
      const extent = this.mapPlug.map.getView().calculateExtent()
      this.customOverlays.forEach((overlay, index) => {
        const coordinate = this.mapPlug.fromLonLat([
          this.list[index].lon,
          this.list[index].lat
        ])
        if (this.isCoordinateInExtent(coordinate, extent)) {
          overlay.getElement().style.display = 'block'
        } else {
          overlay.getElement().style.display = 'none'
        }
      })
    },
    isCoordinateInExtent(coordinate, extent) {
      return (
        coordinate[0] >= extent[0] &&
        coordinate[0] <= extent[2] &&
        coordinate[1] >= extent[1] &&
        coordinate[1] <= extent[3]
      )
    },
    clearCustomPointOverlays() {
      this.customOverlays.forEach((overlay) => {
        this.mapPlug.map.removeOverlay(overlay)
      })
      this.customOverlays = [] // 清空存储数组
    }
  }
}
</script>
<style lang="scss" scoped></style>
