<template>
  <div class="cpn-mpopup">
    <div class="address">{{ data.productName }}</div>
    <div
      class="main"
      v-loading="loading"
      element-loading-background="rgba(255, 255, 255, 0.5)"
    >
      <div class="main-row">
        <div class="o2" v-if="data.dateTime && verData">
          {{ data.dateTime?.Format('MM月DD日') }}平均负氧离子浓度：{{
            verData ? data.negativeO2 + '个/cm³' : '缺测'
          }}
        </div>
        <div class="year">创建年份：{{ data.createYear }}年</div>
        <div class="content">
          {{ data.description }}
        </div>
        <div class="other" v-if="data.brandType == 3">
          <div class="m-button" @click="EventClick(data)">详情</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  log: '',
  inject: ['MapRoot'],
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      elements: [],
      picker: {},
      currentTab: 'hour',
      loading: false,
      postDate: ''
    }
  },
  watch: {
    data: {
      handler() {
        this.getData()
      }
    }
  },
  computed: {
    verData() {
      return this.data.negativeO2 > 0 && this.data.negativeO2 != 999999
    }
  },
  created() {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {},
    EventClick(item) {
      this.Api.routerLink({
        path: '/cnozDetail',
        query: { id: item.id, hasCheck: 1 }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-mpopup {
  width: 340px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  line-height: 1.8;
  .address {
    padding: 8px 30px 8px 10px;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    background: #23c4e0;
    color: #fff;
  }
  .main {
    .main-row {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 15px;
      .o2 {
        color: #009cb8;
      }
      .content {
        flex-shrink: 0;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: justify;
        overflow: hidden;
      }
      .other {
        .m-button {
          padding: 3px 0;
          width: 80px;
          margin-left: auto;
          margin-right: auto;
          background: #23c4e0;
          color: #fff;
          text-align: center;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
