import { JsonAjax as DefAjax } from '../service/ajax.js'

/**
 * @获取_地图右侧氧吧数据
 */
export function Info_brandOxy(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/brand/oxygen/data',
    params: opt
  })
}

/**
 * @获取_地图右侧_宜居城市数据
 */
export function Info_brandCity(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/brand/city/data',
    params: opt
  })
}

/**
 * @获取_地图右侧_避暑旅游数据
 */
export function Info_brandTravel(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: 'api/brand/travel/destination/data',
    params: opt
  })
}

/**
 * @获取_新闻数据
 */
export function Info_homeNewsList(ret) {
  let def = {
    pageSize: 5,
    pageNum: 1,
    typeCode: 'TZ'
    //sortField: 'isRecommend DESC,publishTime DESC'
    //brandType: 3
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/news/page',
    params: opt
  })
}

/**
 * @获取_政策文件数据
 */
export function Info_zcList(ret) {
  let def = {
    pageSize: 5,
    pageNum: 1,
    type: '',
    sortField: 'isRecommend DESC,publishTime DESC',
    brandType: 3
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/file/list',
    params: opt
  })
}

/**
 * @获取_品牌数据
 */
export function Info_brandProductList(ret) {
  let def = {
    brandType: '3',
    pageNum: 1,
    pageSize: 5
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/brand/product/page',
    params: opt
  })
}

/**
 * @获取_负氧离子日平均
 */
export function Info_oxyo2List(ret) {
  let def = {
    brandType: '3',
    pageNo: 1
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/oxygen/negativeO2/day/list',
    params: opt
  })
}

/**
 * @站点数据省列表
 * @param {Number} pageNum //分页-当前页
 * @param {Number} pageSize //分页-展示条数
 * @returns
 */
export function Info_provinceList(ret) {
  let def = {
    pageNum: 1,
    pageSize: 10
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'post',
    url: 'api/oxygen/station/negativeO2/provinceList',
    data: opt
  })
}

/**
 * @chart负氧离子年数据
 * @param {Number} regionProvincialId //区域code
 * @returns
 */
export function Info_chartNegativeO2YearList(ret) {
  let def = {
    regionProvincialId: 230000
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'post',
    url: 'api/oxygen/station/year/negativeO2',
    data: opt
  })
}

/**
 * @chart负氧离子月数据
 * @param {Number} regionProvincialId //区域code
 * @returns
 */
export function Info_chartNegativeO2MonthList(ret) {
  let def = {
    regionProvincialId: 230000
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'post',
    url: 'api/oxygen/station/month/negativeO2',
    data: opt
  })
}

/**
 * @chart负氧离子日数据
 * @param {Number} regionProvincialId //区域code
 * @param {Number} pageNum //分页-当前页
 * @param {Number} pageSize //分页-展示条数
 * @returns
 */
export function Info_chartNegativeO2DayList(ret) {
  let def = {
    regionProvincialId: 230000,
    pageNum: 1,
    pageSize: 10
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'post',
    url: 'api/oxygen/station/day/negativeO2',
    data: opt
  })
}

/**
 * @banner数据
 * @returns
 */
export function Info_bannerList(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: 'api/brand/list',
    params: opt
  })
}

/**
 * @获取品牌产品列表的年份
 * @param {Number} brandType //品牌类别
 * @returns
 */
export function Info_brandYear(ret) {
  let def = {
    brandType: 3
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: 'api/brand/product/dateLine',
    params: opt
  })
}

/**
 * @获取历年中国天然氧吧创建区域
 * @param {Number} brandType //品牌类别
 * @returns
 */
export function Info_historeOxyArea(ret) {
  let def = {
    year: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/api/oxygen/province/year/data',
    params: opt
  })
}

/**
 * @获取产品牌匾
 * @param {Number} brandType //品牌类别
 * @returns
 */
export function Info_brandPlaque(ret) {
  let def = {
    brandType: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/api/plaque/list',
    params: opt
  })
}
