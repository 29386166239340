<template>
  <div class="area-tour-project">
    <div class="column-container">
      <CpnTitle icon="tt-8" more="">专题专栏 </CpnTitle>
      <div class="pro-list">
        <div class="lump"><CpnProList :list="list1"></CpnProList></div>
        <div class="lump"><CpnProList :list="list2"></CpnProList></div>
      </div>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import CpnProList from '@/components/view/project/CpnProList.vue'
import { Info_projectList } from '@/info/infoDetails.js'
export default {
  inject: [],
  components: { CpnTitle, CpnProList },
  props: {},
  data() {
    return {
      list1: [],
      list2: []
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      Promise.all([
        Info_projectList({
          typeId: 3,
          pageNum: 1,
          pageSize: 10
        }),
        Info_projectList({
          typeId: 4,
          pageNum: 1,
          pageSize: 10
        })
      ]).then((rets) => {
        this.list1 = this.getList(rets[0]?.data?.page?.list)
        this.list2 = this.getList(rets[1]?.data?.page?.list)
      })
    },
    getList(data) {
      let list = []
      for (let item of data) {
        list.push({
          ...item,
          img: this.Api.getImgPath() + item.img
        })
      }
      return list
    },
    EventChange() {}
  }
}
</script>
<style lang="scss" scoped>
.area-tour-project {
  .other {
    position: absolute;
    right: 50px;
    top: 0;
    display: flex;
    align-items: center;
    width: 100px;
  }
  .pro-list {
    display: flex;
    .lump {
      width: 10px;
      flex-grow: 1;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
