<template>
  <div class="cpn-tab-swiper-y-a">
    <Swiper
      :slides-per-view="per"
      :slides-per-group="3"
      direction="vertical"
      :mousewheel="{
        releaseOnEdges: true
      }"
      @swiper="initSwiper"
      :modules="modules"
      :scrollbar="{ draggable: true }"
    >
      <SwiperSlide
        v-for="(item, ind) in list"
        :class="ind == index ? 'current' : ''"
        :key="item"
        @click="EventClick(item, ind)"
      >
        <slot name="slide" :prop="item">{{ item }}</slot>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Mousewheel, Scrollbar } from 'swiper'
import 'swiper/css/mousewheel'
import 'swiper/css/scrollbar'
export default {
  log: '',
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    index: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      per: 'auto',
      modules: [Mousewheel, Scrollbar]
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    },
    index: {
      handler(val) {
        this.swiper.slideTo(val)
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    EventClick(item, index) {
      this.$emit('update:index', index)
      this.$emit('select', item)
    },
    initSwiper(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-tab-swiper-y-a {
  height: 100%;
  ::v-deep(.swiper) {
    height: 100%;
    .swiper-slide {
      cursor: pointer;
      height: auto;
      .name {
        padding: 10px;
        color: #666;
        text-align: center;
        margin: 0 5px;
        box-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
      }
      &:hover {
        color: #33c3cc;
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .current {
      .name {
        position: relative;
        color: #33c3cc;
        font-weight: bold;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 30px;
          transform: translate(-50%, 0);
          border-bottom: 3px solid #33c3cc;
        }
      }
    }
  }
}
</style>
