<template>
  <div class="layer-marker-legend">
    <div class="all-view" v-if="legendList.length">负离子(个/cm3)</div>
    <ul>
      <template v-for="item in legendList" :key="item.name">
        <li
          :label="item.name"
          :value="item.key"
          class="view"
          @click="EventSelect(item)"
        >
          <div class="icon" :style="{ 'background-color': item.color }"></div>
          <div class="name">{{ item.name }}</div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    legendList() {
      return this.MapRoot?.legendList
    }
  },
  mounted() {},
  methods: {
    EventSelect(item) {
      this.MapRoot.visibleYear = [item.key]
    },
    EventAllView() {
      this.MapRoot.visibleYear = this.legendList.map((e) => e.key)
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-marker-legend {
  position: absolute;
  left: 0;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  line-height: 1.8;
  .all-view {
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.hide {
      opacity: 0.3;
    }
  }
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
</style>
