<template log="EChart-数据纠错和二次处理组件:用于对组装好的option进行纠错">
  <div class="m-chart-view">
    <NoData v-if="!hasData" :msg="msg" type="text"></NoData>
    <EchartsInit
      v-else-if="hasData"
      v-model:option="optionData"
      ref="REChart"
    ></EchartsInit>
  </div>
</template>
<script>
import EchartsInit from '@/components/common/chart/EchartsInit.vue'
import NoData from '@/components/common/other/NoData.vue'
import TypeIs from '@/api/TypeIs.js'
//import { nowSize } from '@/plug/chart/chartFn.js'
export default {
  components: {
    NoData,
    EchartsInit
  },
  props: {
    msg: {
      type: String
    }
  },
  data() {
    return {
      optionData: {},
      hasData: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    reData(option) {
      //数据纠错
      let textColor = '#333'
      let commonOpt = {
        darkMode: false,
        textStyle: {
          color: textColor
          //fontSize: nowSize(16)
        },
        legend: {
          textStyle: {
            color: textColor
            //fontSize: nowSize(16)
          }
        }
      }
      let ret = this.Api.extend(commonOpt, option)
      for (let serie in ret.series) {
        for (let i in ret.series[serie].data) {
          let item = ret.series[serie].data[i]
          if (TypeIs.Object(item)) {
            if (item.value || item.value === 0) {
              if (item.value == 9999 || item.value == -9999) {
                ret.series[serie].data[i].value = '-'
              }
            } else {
              ret.series[serie].data[i].value = '-'
            }
          } else {
            if (item || item === 0) {
              if (item == 9999 || item == -9999) {
                ret.series[serie].data[i] = '-'
              }
            } else {
              ret.series[serie].data[i] = '-'
            }
          }
        }
      }
      return ret
    },
    initData(option) {
      if (option) {
        let optionData = this.reData(option)
        let tempData = '-'
        if (optionData?.series?.length) {
          for (let serie of optionData.series) {
            tempData = this.getSerieValue(serie.data)
            break
          }
        }
        if (tempData === '-') {
          this.hasData = false
          return
        } else {
          this.hasData = true
        }
        if (this.hasData) {
          this.optionData = optionData
        }
      } else {
        this.hasData = false
        //console.error('ChartView.vue未传入chart-option')
      }
    },
    getSerieValue(serieData) {
      for (let item of serieData) {
        if (TypeIs.Object(item)) {
          if (item.value !== '-') {
            return item.value
          }
        } else {
          if (item !== '-') {
            return item
          }
        }
      }
      return '-'
    }
  }
}
</script>
<style lang="scss" scoped>
.m-chart-view {
  height: 100%;
}
</style>
