<template>
  <div class="cpn-item-news" :class="custom">
    <i class="icon" :class="icon"></i>
    <div class="title" :class="isNew ? 'new' : ''">
      <div class="text"><slot></slot></div>
    </div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    icon: {
      type: String,
      default: ''
    },
    custom: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-item-news {
  display: flex;
  align-items: center;
  .icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 16px;
    &.news {
      width: 12px;
      height: 12px;
      background: url('~@/assets/img/icon/icon-o.png') center center/50% 50%
        no-repeat;
    }
    &.zhengce {
      font-family: 'icon-cb';
      color: #23c4e0;
    }
    &.biaozhunguifan {
      font-family: 'icon-cb';
      color: #4ebca9;
    }
  }
  .title {
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    .text {
      width: 10px;
      flex-grow: 1;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
    &.new::before {
      margin-right: 6px;
      display: block;
      width: 27px;
      height: 14px;
      content: '';
      flex-shrink: 0;
      background: url('~@/assets/img/icon/icon-new.png') left center/contain
        no-repeat;
    }
  }
  &.item-green {
    color: #00c6bf;
    font-weight: bold;
    .icon.news {
      background: url('~@/assets/img/icon/icon-news.png') left center/contain
        no-repeat;
    }
  }
}
@media screen and (min-width: 1200px) {
  .cpn-item-news {
    .title {
      font-size: 16px;
    }
  }
}
</style>
