import _ from 'lodash'
export function moduleExtend(modulesFiles) {
  // 合并store的module用于store
  const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
  }, {})
  return modules
}

export function moduleObjectExtend(modulesFiles) {
  // 合并对象为1个对象，用于拆分某一个store为多个js之后的再合并，便于维护
  const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
  }, {})
  let mlist = []
  for (let i in modules) {
    mlist.push(modules[i])
  }
  return _.merge(...mlist)
}
