<!--
 * @Name: 氧吧区域查询
 * @Author: koyoyoo
 * @Date: 2024-04-22 10:35:41
-->
<template>
  <div class="area-oxy-search">
    <div class="column-container">
      <CpnTitleB name="品牌授予地区（景区）查询"></CpnTitleB>
      <div class="m-search" v-loading="loading">
        <div class="search-bar">
          <div class="lump-1">
            <div class="row-1">
              <ChildSearchBrand
                class="search-box"
                :list="list"
                @search="EventSearch"
                @save="EventSave"
              >
                <el-select
                  class="select-brand"
                  v-model="filterBrand"
                  :multiple="true"
                  collapse-tags
                  placeholder="请选择品牌"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </ChildSearchBrand>
              <div class="search-text">
                说明：本功能仅支持对中国天然氧吧、中国气候宜居城市（县）、避暑旅游目的地三个品牌地区的查询。
              </div>
            </div>
            <div class="row-2">
              <div class="text">扫码查询中国天然氧吧</div>
              <KOimg class="m-ewm" :src="ewm"></KOimg>
            </div>
          </div>
        </div>
        <div class="result-bar">
          <template v-if="list.length">
            <ChildResultBrandList
              v-if="count > 0"
              :list="reList"
            ></ChildResultBrandList>
            <div v-else class="ui-no-data">未查询到本地区（景区）相关信息</div>
          </template>
        </div>
      </div>
    </div>
    <div class="m-downloadResult" ref="RresultBar">
      <CpnBrandList :list="reList"></CpnBrandList>
    </div>
  </div>
</template>
<script>
import CpnTitleB from '@/components/view/title/CpnTitleB.vue'
import ChildResultBrandList from './child/ChildResultBrandList.vue'
import CpnBrandList from '@/components/content/list/CpnBrandList.vue'
import ChildSearchBrand from './child/ChildSearchBrand.vue'
import { Info_searchBrandData } from '@/info/infoSearchOxy.js'
import { getImg, downloadImage } from '@/plug/file/saveImg.js'
export default {
  inject: [],
  components: {
    CpnTitleB,
    ChildResultBrandList,
    CpnBrandList,
    ChildSearchBrand
  },
  props: {},
  data() {
    return {
      loading: false,
      ewm: require('@/assets/img/home/ewm.oxy.png'),
      list: [],
      selectBrand: false,
      count: 0,
      brandFilter: '全部',
      filterBrand: ['中国天然氧吧', '中国气候宜居城市', '避暑旅游目的地'],
      options: [
        {
          label: '中国天然氧吧',
          value: '中国天然氧吧'
        },
        {
          label: '中国气候宜居城市',
          value: '中国气候宜居城市'
        },
        {
          label: '避暑旅游目的地',
          value: '避暑旅游目的地'
        }
      ]
    }
  },
  watch: {},
  computed: {
    reList() {
      if (this.list?.length) {
        return this.list?.filter((item) =>
          this.filterBrand.includes(item.brandName)
        )
      } else {
        return []
      }
    }
  },
  mounted() {},
  methods: {
    EventSearch(keyword) {
      this.list = false
      let count = 0
      if (keyword) {
        this.loading = true
        Info_searchBrandData({
          name: keyword
        }).then((ret) => {
          this.loading = false
          let list = false
          if (ret.success) {
            list = ret?.data
            for (let item of list) {
              count += item.list.length
            }
          } else {
            this.msg = ret.msg ?? '未查询到结果'
            count = 0
          }
          this.list = list
          this.count = count
        })
      }
    },
    EventChange(ret) {
      this.selectBrand = ret
    },
    EventSave() {
      getImg(this.$refs.RresultBar).then((imgBase64) => {
        downloadImage(imgBase64, '品牌授予地区（景区）查询结果')
      })
    },
    EventClick_brandSearch() {}
  }
}
</script>
<style lang="scss" scoped>
.area-oxy-search {
  &.page-oxy {
    .m-search {
      padding: 0;
      .search-bar {
        width: 900px;
      }
    }
  }
  .m-search {
    padding: 20px;
  }
  .search-bar {
    .lump-1 {
      display: flex;
      margin-bottom: 10px;
      .row-1 {
        flex-shrink: 0;
        padding-top: 20px;
      }
      .row-2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        .m-ewm {
          width: 76px;
          height: 76px;
          margin-left: 10px;
        }
      }
    }
  }
  .result-bar {
    background: #f6fcfd;
  }
  .ui-no-data {
    padding: 20px;
  }

  .m-ewm {
    width: 74px;
    transition: 0.3s ease-out;
    &:hover {
      transform: scale(2);
    }
  }

  .search-text {
    color: #666;
  }
  .filter-brand {
    margin-top: 30px;
  }
}
.m-downloadResult {
  position: absolute;
  top: 1000px;
  left: -9000px;
  width: 1000px;
}
</style>
