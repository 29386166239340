<template>
  <div class="view-header">
    <div class="column-container dispost-area">
      <div class="m-logo">
        <img :src="logoImg" alt="" />
        <div class="name">
          <div class="t1">中国气象局气候生态品牌创建示范平台</div>
          <div class="t2">
            Climate Ecological Brand Creation and Demonstration Platform of CMA
          </div>
        </div>
      </div>
      <div class="m-now-time"><CpnNowTime></CpnNowTime></div>
      <div class="m-login" v-if="showLogin()">
        <!----$route.path == '/home' || $route.path == '/'-->
        <div class="btn login" @click="EventLogin('login')">登录</div>
        <div
          class="btn register"
          v-if="verRouterBrand(3)"
          @click="EventLogin('reg')"
        >
          注册
        </div>
      </div>
      <div class="m-login home-login" v-if="showHomeLogin()">
        <!----$route.path == '/home' || $route.path == '/'-->
        <div class="login-label">
          <div class="name">中国天然氧吧申报:</div>
          <div class="btn login" @click="EventLogin('3-login')">登录</div>
          <div class="btn register" @click="EventLogin('3-reg')">注册</div>
        </div>
        <div class="login-label">
          <div class="name">中国气候宜居城市(县) 申报:</div>
          <div class="btn login" @click="EventLogin('4')">登录</div>
        </div>
        <div class="login-label">
          <div class="name">避暑旅游目的地申报:</div>
          <div class="btn login" @click="EventLogin('5')">登录</div>
        </div>
      </div>
    </div>
  </div>
  <div class="view-nav" v-if="!['/book'].includes($route.path)">
    <div class="column-container dispost-area">
      <VNav></VNav>
    </div>
  </div>
</template>

<script>
import VNav from '@/views/index/VHead/VNav.vue'
import CpnNowTime from '@/components/content/other/CpnNowTime.vue'
export default {
  components: { CpnNowTime, VNav },
  data() {
    return {
      nowTime: '',
      moreShow: false,
      loginCount: '',
      drawerShow: false,
      logoImg: require('@/assets/img/index/logo.png')
    }
  },
  computed: {
    areaName() {
      return this.$store.state.info?.KOStore?.GArea?.name ?? '河南省'
    }
  },
  watch: {},
  mounted() {},
  methods: {
    verRouterBrand(type) {
      let dict = {
        3: '/cnoz',
        4: '/cclc',
        5: '/ccsd'
      }
      return (
        this.$route?.path?.includes(dict[type]) ||
        this.$route?.query?.brandType == type
      )
    },
    showLogin() {
      /*for (let i = 3; i < 6; i++) {
        if (this.verRouterBrand(i)) {
          return true
        }
      }*/
      return false
    },
    showHomeLogin() {
      return false //this.$route.path === '/'
    },
    EventLogin(ret) {
      let url = ''
      if (this.$route.path.includes('/cnoz')) {
        if (ret == 'login') {
          url = 'https://www.cma-nci.com/admin/login'
        } else {
          url = 'https://www.cma-nci.com/admin/register'
        }
      } else if (this.$route.path.includes('/cclc')) {
        url = 'http://www.ncc-cma.net/climate/sign'
      } else if (this.$route.path.includes('/ccsd')) {
        url = 'http://www.ncc-cma.net/climate/sign'
      } else if (this.$route.path === '/') {
        if (ret == '3-login') {
          url = 'https://www.cma-nci.com/admin/login'
        } else if (ret == '3-reg') {
          url = 'https://www.cma-nci.com/admin/register'
        } else {
          url = 'http://www.ncc-cma.net/climate/sign'
        }
      }
      if (url) {
        window.open(url)
      }
    },
    EventSelect(ret) {
      console.log(ret)
    },
    EventClick_drawer() {
      this.drawerShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.view-header {
  background-image: linear-gradient(#d7fcfd, #d4fbfb);
  position: relative;
  .column-container {
    height: 175px;
  }
  .m-now-time {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 14px;
  }
  .m-login {
    position: absolute;
    bottom: 70px;
    right: 10px;
    display: flex;
    &.home-login {
      flex-direction: column;
      bottom: 50%;
      transform: translate(0, 50%);
      .login-label {
        display: flex;
        align-items: center;
        margin: 5px 0;
        .name {
          width: 180px;
          margin-right: 20px;
          text-align: right;
        }
      }
    }
    .btn {
      padding: 10px 30px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 16px;
      &.login {
        background: #23c4e0;
        margin-right: 20px;
        color: #fff;
        &:hover {
          background: #27d0ee;
        }
      }
      &.register {
        background: #fff;
        &:hover {
          color: #23c4e0;
        }
      }
    }
  }
  .m-logo {
    position: absolute;
    left: 0;
    top: 58px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    img {
      height: 70px;
      width: 70px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .name {
      flex-grow: 1;
      .t1 {
        font-size: 32px;
        margin-bottom: 5px;
      }
      .t2 {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 1px;
        font-weight: bold;
      }
    }
  }
}
.view-nav {
  background: linear-gradient(90deg, #4bbe9d, #23c4df);
}
</style>
