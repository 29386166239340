<template>
  <div class="cn-map-oxy-datas">
    <CNMapMarkerOverList
      log="氧吧数据-overlay列表"
      v-if="MapRoot.mapPlug"
      :mapPlug="MapRoot.mapPlug"
      :list="oxyData"
      @clickOverlay="EventClickOverlay"
    >
      <template #markerOverlay="{ prop }">
        <div log="圆形overlay" class="m-round" :style="getRoundStyle(prop)">
          {{ prop.value == '999999' ? '异常' : prop.value }}
        </div>
      </template>
    </CNMapMarkerOverList>
    <div
      class="m-map-oxy-pupup"
      v-if="MapRoot.currentOverlay"
      :style="getPopupStyle"
    >
      <MPopupOxyMarker
        log="氧吧数据-弹出层内容"
        class="m-pupup-oxy-marker"
        :data="MapRoot.currentOverlay"
        @close="MapRoot.currentOverlay = false"
      ></MPopupOxyMarker>
    </div>
  </div>
</template>
<script>
import CNMapMarkerOverList from '@/components/content/map/cpn/CNMapMarkerOverList.vue'
import MPopupOxyMarker from '../layer/markerPopup/MPopupOxyMarker.vue'
import { InfoMap_oxyMapMarkers } from '@/info/infoOxyMapData.js'
export default {
  inject: ['MapRoot'],
  components: { CNMapMarkerOverList, MPopupOxyMarker },
  props: {},
  data() {
    return {
      oxyData: [],
      legendList: [
        {
          name: '>8000',
          color: 'rgba(72, 190, 163,0.7)',
          min: 8000,
          max: 'max'
        },
        {
          name: '6001~8000',
          color: 'rgba(81, 213, 184,0.7)',
          min: 6001,
          max: 8000
        },
        {
          name: '4001~6000',
          color: 'rgba(123, 225, 142,0.7)',
          min: 4001,
          max: 6000
        },
        {
          name: '2001~4000',
          color: 'rgba(161, 207, 255,0.7)',
          min: 2001,
          max: 4000
        },
        {
          name: '0~2000',
          color: 'rgba(134, 191, 249,0.7)',
          min: 0,
          max: 2000
        },
        { name: '异常', color: 'rgba(207, 187, 159,0.7)' }
      ]
    }
  },
  watch: {
    'MapRoot.areaCode': {
      handler() {
        this.MapRoot.currentOverlay = false
        this.getData_oxyDatas()
      }
    }
  },
  computed: {
    getPopupStyle() {
      return {
        left: this.MapRoot.currentOverlay.left,
        top: this.MapRoot.currentOverlay.top
      }
    }
  },
  mounted() {
    this.getData_oxyDatas()
  },
  methods: {
    getData_oxyDatas() {
      let postData = this.Api.classifyArea(this.MapRoot.areaCode)
      this.MapRoot.onLoading()
      InfoMap_oxyMapMarkers(postData)
        .then((ret) => {
          this.MapRoot.offLoading()
          this.oxyData = []
          for (let item of ret.data) {
            this.oxyData.push({
              ...item,
              value: item.avgNegativeO2
            })
          }
          this.setLegend()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setLegend() {
      let legendList = [],
        legendDict = {}
      for (let oxy of this.oxyData) {
        for (let i in this.legendList) {
          let legendItem = this.legendList[i]
          if (
            (oxy.value >= legendItem.min && oxy.value <= legendItem.max) ||
            (legendItem.max == 'max' &&
              oxy.value >= legendItem.min &&
              oxy.value != '999999') ||
            (oxy.value == '999999' && legendItem.name == '异常')
          ) {
            if (typeof legendDict[legendItem.name] == 'undefined') {
              legendDict[legendItem.name] = {
                name: legendItem.name,
                key: legendItem.key,
                color: legendItem.color,
                data: [],
                sort: i
              }
            }
            legendDict[legendItem.name].data.push(legendItem)
            oxy.color = legendItem.color
          }
        }
      }
      for (let i in legendDict) {
        legendList.push(legendDict[i])
      }
      this.MapRoot.legendList = legendList.sort((a, b) => {
        return a.sort - b.sort
      })
    },
    getRoundStyle(ret) {
      return { 'background-color': ret.color }
    },
    EventClickOverlay(ret) {
      this.MapRoot.currentOverlay = {
        ...ret,
        left: ret.left + 'px',
        top: ret.top + 'px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.m-round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 1);
  cursor: pointer;
  &:hover {
    color: #23c4e0;
  }
}
.m-map-oxy-pupup {
  position: absolute;
  z-index: 50;
  .m-pupup-oxy-marker {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 0);
  }
}
</style>
