<!--地图轮廓绘制-功能组件-->
<template>
  <ModuleFeaturesAdminDivi
    log="MapModuleMain:地图-多边形-行政区划"
    :geoJsonInner="adminDiviJson.inner"
    :geoJsonOuter="adminDiviJson.outer"
  ></ModuleFeaturesAdminDivi>
</template>
<script>
import { InfoMap_adminDivi, InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import ModuleFeaturesAdminDivi from '@/components/content/map/cpn/ModuleFeaturesAdminDivi.vue'
import { getAreaCodeLevel } from '@/api/func/DataPro.js'
export default {
  log: '',
  components: { ModuleFeaturesAdminDivi },
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      adminDiviJson: {}
    }
  },
  watch: {
    'MapRoot.areaCode': {
      handler() {
        this.initAdminDivi()
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initEvent()
    this.initAdminDivi()
  },
  methods: {
    initEvent() {
      this.MapRoot?.mapPlug?.map?.on('click', (e) => {
        let feature = this.MapRoot?.mapPlug?.map.forEachFeatureAtPixel(
          e.pixel,
          (feature) => feature
        )
        if (
          ['featurePolygonInner', 'featureName'].includes(feature?.featureType)
        ) {
          this.onClickFeature(feature)
        }
      })
    },
    onClickFeature(ret) {
      let excludeArea = ['110000', '120000', '310000', '500000']
      if (excludeArea.indexOf(this.MapRoot.areaCode.toString()) >= 0) {
        return
      }
      let areaCode = ret.data?.adcode || ret.data?.areaCode
      if (areaCode && this.MapRoot.areaLevel < 2) {
        this.MapRoot.selectAreaCode = areaCode
        this.MapRoot.areaCode = areaCode
        this.MapRoot.areaLevel = getAreaCodeLevel(areaCode)
        this.MapRoot.areaName = ret.data.name
        this.toAdminDivi(areaCode)
      }
    },
    initAdminDivi() {
      let areaCode = this.MapRoot.areaCode
      this.toAdminDivi(areaCode)
    },
    toAdminDivi(areaCode) {
      this.MapRoot.onLoading()
      Promise.all([
        InfoMap_adminDivi({ areaCode }),
        InfoMap_adminDiviOuter({ areaCode })
      ])
        .then((art) => {
          this.MapRoot.offLoading()
          if (art[0]?.features?.length) {
            this.adminDiviJson.inner = art[0]
          }
          if (art[1]?.features?.length) {
            //外轮廓
            this.adminDiviJson.outer = art[1]
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss">
.marker-featureName {
  font-size: 12px;
  color: #000;
  text-shadow: #fff 1px 0 0, #fff 0 1px 0, #fff -1px 0 0, #fff 0 -1px 0;
}
</style>
