<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-04-26 11:07:01
-->
<template>
  <div class="item-brand">
    <dl>
      <dt>{{ brandName }}：</dt>
      <dd>{{ name ?? '无' }}</dd>
    </dl>
    <dl>
      <dt>授牌时间：</dt>
      <dd>
        {{ publishTime?.Format('YYYY年MM月DD日') ?? '-' }}
      </dd>
    </dl>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    brandName: {
      type: String,
      default: '中国天然氧吧'
    },
    name: {
      type: String,
      default: ''
    },
    publishTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.item-brand {
  dl {
    flex-basis: 33.33%;
    margin: 5px 0;
    display: flex;
    dt {
      width: 130px;
      flex-shrink: 0;
      text-align: right;
    }
  }
}
</style>
