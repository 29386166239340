<template>
  <div class="cpn-now-time">{{ nowTime }}</div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      nowTime: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.setTime()
  },
  methods: {
    setTime() {
      this.nowTime = new Date().Format('YYYY年MM月DD日 星期w hh:mm:ss')
      setTimeout(() => {
        this.setTime()
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-now-time {
}
</style>
