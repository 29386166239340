import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector.js'
import { LineString, Point } from 'ol/geom'
import Feature from 'ol/Feature'
import { Stroke, Style, Fill, Circle as CircleStyle } from 'ol/style'
import { setRgbaAlpha } from '@/api/color/colorModify.js'
import { fromLonLat } from 'ol/proj' // 导入投影转换函数

class LineDrawer {
  color = '#ff784a'
  zIndex = 50

  constructor(ret) {
    Object.assign(this, ret)
    this.vectorLayer = this.createVectorLayer()
    this.map.addLayer(this.vectorLayer)
    this.lineFeature = this.createLineFeature()
    this.pointFeature = this.createPointFeature() // 创建起点特征
    this.vectorLayer.getSource().addFeature(this.lineFeature)
    this.vectorLayer.getSource().addFeature(this.pointFeature) // 添加到图层
    this.opacity = 1
    this.isFadingIn = false // 新增标志位
    this.isFadingOut = false // 新增标志位
  }

  createVectorLayer() {
    return new VectorLayer({
      source: new VectorSource(),
      zIndex: this.zIndex
    })
  }

  createLineFeature() {
    const lineCoordinates = this.transformCoordinatesList(this.lineData) // 转换坐标
    const lineString = new LineString(lineCoordinates)
    const lineFeature = new Feature({
      geometry: lineString
    })
    const lineStyle = new Style({
      stroke: new Stroke({
        color: this.color,
        width: 1,
        lineDash: [6, 4] // 虚线模式，6px实线，4px空白
      })
    })

    lineFeature.setStyle(lineStyle)
    return lineFeature
  }

  createPointFeature() {
    const startCoordinates = this.transformCoordinatesList(this.lineData)[
      this.lineData?.length - 1
    ] // 获取起点坐标并转换
    const point = new Point(startCoordinates) // 创建点
    const pointFeature = new Feature({
      geometry: point
    })
    const pointStyle = new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: this.color }) // 填充颜色
      })
    })
    pointFeature.setStyle(pointStyle)
    return pointFeature
  }

  // 坐标转换方法
  transformCoordinatesList(coordinates) {
    // 假设传入的是国标经纬度 (longitude, latitude)
    const mapProjection = this.map.getView().getProjection().getCode() // 获取地图的投影
    if (mapProjection == 'EPSG:4326') {
      return coordinates
    } else {
      return coordinates.map((coord) => fromLonLat(coord)) // 转换为墨卡托投影
    }
  }

  setVisible(visible) {
    this.vectorLayer.setVisible(visible)
  }

  updatePointFeatureStyle(feature, newColor) {
    feature?.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({ color: newColor }) // 填充颜色
        })
      })
    )
  }

  updateStrokeFeatureStyle(feature, newColor) {
    let style = new Style({
      stroke: new Stroke({
        color: newColor,
        width: 1,
        lineDash: [6, 4] // 虚线模式，6px实线，4px空白
      })
    })
    feature?.setStyle(style)
  }

  fadeIn(duration = 100) {
    this.isFadingIn = true // 开始淡入时设置标志位
    this.isFadingOut = false // 确保淡出标志位为 false
    const step = 1 / (duration / 16) // 每帧增加的透明度
    const fade = () => {
      if (!this.isFadingIn) return // 如果不再淡入，停止淡入
      this.opacity += step
      if (this.opacity >= 1) {
        this.opacity = 1
        this.isFadingIn = false // 淡入完成，重置标志位
      }
      let newColor = setRgbaAlpha(this.color, this.opacity)
      this.updateStrokeFeatureStyle(this.lineFeature, newColor)
      this.updatePointFeatureStyle(this.pointFeature, newColor)
      if (this.opacity < 1) {
        requestAnimationFrame(fade)
      }
    }

    fade()
  }

  fadeOut(duration = 100) {
    this.isFadingOut = true // 开始淡出时设置标志位
    this.isFadingIn = false // 确保淡入标志位为 false
    const step = 1 / (duration / 16) // 每帧减少的透明度
    const fade = () => {
      if (!this.isFadingOut) return // 如果不再淡出，停止淡出
      this.opacity -= step
      if (this.opacity <= 0) {
        this.opacity = 0
        this.isFadingOut = false // 淡出完成，重置标志位
      }
      let newColor = setRgbaAlpha(this.color, this.opacity)
      this.updateStrokeFeatureStyle(this.lineFeature, newColor)
      this.updatePointFeatureStyle(this.pointFeature, newColor)
      if (this.opacity > 0) {
        requestAnimationFrame(fade)
      }
    }

    fade()
  }

  update(lineData) {
    this.lineData = lineData
    const lineString = this.lineFeature.getGeometry() // 获取已有的LineString对象
    lineString.setCoordinates(this.transformCoordinatesList(lineData)) // 更新坐标
    this.updateStartPoint() // 更新起点
  }

  updateStartPoint() {
    const startCoordinates = this.transformCoordinatesList(this.lineData)[
      this.lineData?.length - 1
    ] // 获取起点坐标并转换
    this.pointFeature.getGeometry().setCoordinates(startCoordinates) // 更新点的坐标
  }

  clear() {
    this.vectorLayer.getSource().clear() // 清除所有要素
  }

  destroy() {
    this.clear() // 清除要素
    this.map.removeLayer(this.vectorLayer) // 从地图中移除图层
    this.vectorLayer = null // 释放图层引用
    this.lineFeature = null // 释放线特征引用
    this.pointFeature = null // 释放起点特征引用
  }
}

export default LineDrawer
