<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-04-26 10:30:26
-->
<template>
  <div class="child-result-brand-list">
    <div class="search-handler" v-if="list?.length">
      <i
        class="icon icon-xiajiantou iconfont"
        :class="openBrand ? 'open' : 'close'"
        @click="openBrand = !openBrand"
      ></i>
    </div>
    <div class="result-number">
      <div class="name">查询到结果：</div>
      <div class="item" v-for="item in list" :key="item">
        {{ item.brandName }}：{{ item.list?.length }}个
      </div>
    </div>
    <div class="result-scroll" :class="openBrand ? 'open' : 'close'">
      <CpnBrandList :list="list"></CpnBrandList>
    </div>
  </div>
</template>
<script>
import CpnBrandList from '@/components/content/list/CpnBrandList.vue'
export default {
  inject: [],
  components: { CpnBrandList },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      openBrand: true,
      brandGroups: []
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventLink(item, brandType) {
      if (brandType == 3) {
        this.Api.routerLink({
          path: '/cnozDetail',
          query: { id: item.id, hasCheck: 1 }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.child-result-brand-list {
  position: relative;
  .result-scroll {
    line-height: 1.6;
    transition: 0.3s ease-out;
    &.open {
      padding: 15px 20px;
      max-height: 300px;
      overflow-y: auto;
    }
    &.close {
      padding: 0 20px;
      max-height: 0;
      overflow: hidden;
    }
  }
  .brand-type-3 {
    &:hover {
      cursor: pointer;
      color: #33c3cc;
    }
  }

  .search-handler {
    position: relative;
    z-index: 10;
    .icon {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d5d5d5;
      background: #fff;
      border-radius: 50%;
      font-size: 18px;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .filter-brand {
    margin-bottom: 10px;
  }
  .result-number {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    font-size: 12px;
    color: #666;
    .item {
      margin-right: 20px;
    }
  }
}
</style>
