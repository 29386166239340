<template>
  <div class="layer-marker-legend" v-if="yearLegend?.length">
    <div
      class="all-view"
      v-if="yearLegend.length != visibleYears?.length"
      @click="EventAllView"
    >
      全部显示
    </div>
    <ul v-if="visibleYears?.length">
      <template v-for="item in yearLegend" :key="item.name">
        <li :class="getClass(item)" @click="EventSelect(item)">
          <div class="icon" :style="{ 'background-color': item.color }"></div>
          <div class="name">{{ item.name }}</div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { yearLegend, visibleYears, updateGLPoint } from '../js/setMapPoint.js'
export default {
  components: {},
  props: {},
  data() {
    return {
      yearLegend,
      visibleYears
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getClass(item) {
      let isShow = this.visibleYears.includes(item.value) ? 'view' : 'hide'
      return isShow
    },
    EventSelect(item) {
      this.visibleYears = [item.value]
      updateGLPoint()
    },
    EventAllView() {
      this.visibleYears = this.yearLegend.map((e) => e.value)
      updateGLPoint()
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-marker-legend {
  position: absolute;
  left: 0;
  top: 40px;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  .all-view {
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }
  li {
    display: flex;
    align-items: center;
    line-height: 1.8;
    cursor: pointer;
    &:hover {
      color: #23c4e0;
    }
    &.hide {
      opacity: 0.3;
    }
    &.current {
      color: #23c4e0;
    }
  }
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
</style>
