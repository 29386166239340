<template>
  <div class="area-histore-area-data">
    <div class="column-container">
      <CpnTitleB name="历年中国天然氧吧创建区域"></CpnTitleB>
      <CpnCollapse v-loading="loading" :list="tabList"></CpnCollapse>
    </div>
  </div>
</template>
<script>
import CpnTitleB from '@/components/view/title/CpnTitleB.vue'
import CpnCollapse from '@/components/content/book/CpnCollapse.vue'
import { InfoMap_marker } from '@/info/infoMap.js'
export default {
  inject: [],
  components: { CpnTitleB, CpnCollapse },
  props: {},
  data() {
    return {
      yearTabs: [],
      tabList: [],
      tabIndex: 0,
      loading: false
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.init()
    this.getData()
  },
  methods: {
    init() {
      let year = new Date().DateAdd('y', -1).Format('YYYY')
      let list = []
      for (let i = year; i >= 2016; i--) {
        list.push({
          name: i + '年',
          value: parseInt(i),
          areas: [],
          number: 0
        })
      }
      this.yearTabs = list
    },
    getData() {
      this.loading = true
      InfoMap_marker({
        brandType: 3
      }).then((ret) => {
        this.loading = false
        let list = []
        if (ret?.data?.length) {
          for (let item of this.yearTabs) {
            let reItem = this.setYearItem(item, ret.data)
            if (reItem.number > 0) {
              list.push(reItem)
            }
          }
        }
        this.tabList = list
      })
    },
    setYearItem(item, list) {
      let dict = {}
      for (let i in list) {
        let year = list[i].createTime?.Format('YYYY')
        if (item.value == year) {
          let area = list[i].regionText.split('-')[0]
          if (typeof dict[area] == 'undefined') {
            dict[area] = {
              title: area,
              list: [],
              sort: list[i].orderNo
            }
            item.areas.push(dict[area])
          }
          dict[area].list.push(list[i])
          item.number++
        }
      }
      return { ...item, number: item.number }
    }
  }
}
</script>
<style lang="scss" scoped></style>
