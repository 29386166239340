<template>
  <div class="area-shenbao">
    <div class="column-container dispost-area">
      <CpnTitle icon="tt-4">申报说明</CpnTitle>
      <CpnTabShenbao
        :tabs="tabs"
        v-model="tabIndex"
        class="mgb-50"
      ></CpnTabShenbao>
    </div>
    <div class="shenbao-area">
      <template v-for="(item, index) in imgs" :key="item">
        <KOimg v-show="index == tabIndex" :src="item"></KOimg>
      </template>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import CpnTabShenbao from '@/components/view/tab/CpnTabShenbao.vue'
import { IDict_channelType } from '@/info/infoDict.js'
export default {
  log: '',
  components: { CpnTitle, CpnTabShenbao },
  props: {},
  data() {
    return {
      tabs: [],
      tabIndex: 0,
      imgs: [
        require('@/assets/img/home/shengbao-3.jpg'),
        require('@/assets/img/home/shengbao-4.jpg'),
        require('@/assets/img/home/shengbao-5.jpg')
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initTabs()
  },
  methods: {
    initTabs() {
      IDict_channelType().then((ret) => {
        {
          this.tabs = ret.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shenbao-area {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
