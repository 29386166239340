<template>
  <div class="box-rank-list">
    <div class="sub-title">{{ subTitle }}</div>
    <div class="title">
      <div class="t t1">名次</div>
      <div class="t t2">中国天然氧吧地区</div>
      <div class="t t3">个/cm3</div>
    </div>
    <div class="content">
      <ScrollMore
        memoryScroll="0"
        pullDownRefresh="1"
        pageName="pageNum"
        limitName="pageSize"
        :FnGetData="FnSM_getData"
        @setList="EventSM_setList"
        ref="scrollMore"
        class="full-content"
      >
        <ul class="m-product-list">
          <li v-for="(item, index) in list" :key="item">
            <div class="t t1">
              <h2>{{ index + 1 }}</h2>
            </div>
            <div class="t t2">{{ item.regionPath }}</div>
            <div class="t t3">{{ parseInt(item.negativeO2) }}</div>
          </li>
        </ul>
      </ScrollMore>
    </div>
  </div>
</template>
<script>
import ScrollMore from '@/components/common/scroll/ScrollMore.vue'
import { Info_chartNegativeO2DayList } from '@/info/infoHome.js'
export default {
  inject: [],
  components: { ScrollMore },
  props: {
    areaCode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      list: [],
      subTitle: new Date().DateAdd('d', -1).Format('YYYY年MM月DD日')
    }
  },
  watch: {
    areaCode: {
      handler() {
        this.initPlug_scrollMore()
      }
    }
  },
  computed: {},
  mounted() {
    this.initPlug_scrollMore()
  },
  methods: {
    initPlug_scrollMore() {
      //初始化插件 ScrollMore并加载
      this.Plug_scrollMore = this.$refs?.scrollMore
      this.Plug_scrollMore.initData({
        data: { regionProvincialId: this.areaCode }
      }) //默认加载
    },
    FnSM_getData(ret) {
      return new Promise((resolve) => {
        Info_chartNegativeO2DayList(ret).then((ret) => {
          if (ret?.list?.length) {
            this.subTitle = ret.list[0].dateTime.Format('YYYY年MM月DD日')
            resolve({ list: ret.list, count: ret.total })
          } else {
            resolve([])
          }
        })
      })
    },
    EventSM_setList(ret) {
      //ScrollMore在接收到数据后会发送此事件，用于父组件把数据写入到Dom
      this.list = ret.list //给数据加上
    },
    getTestData() {
      this.list = [
        {
          name: '黑河市爱辉区',
          value: '6200'
        },
        {
          name: '凤凰山国家森林公园',
          value: '5898'
        },
        {
          name: '牡丹江绥芬河市',
          value: '5115'
        },
        {
          name: '伊春市伊美区',
          value: '4869'
        },
        {
          name: '伊春市金林区',
          value: '4745'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.box-rank-list {
  font-size: 14px;
  .sub-title {
    text-align: center;
    color: #999;
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    background: #f6fcfd;
    color: #a5a5a5;
    line-height: 1.6;
    margin-bottom: 5px;
  }
  .content {
    height: 160px;
  }
  .m-product-list {
    li {
      display: flex;
      color: #333;
      line-height: 1.6;
    }
  }
  .t {
    padding: 5px 0;
    width: 10px;
    text-align: center;
  }
  .t1 {
    flex-grow: 0.6;
    h2 {
      background: #00bfbf;
      color: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
    }
  }
  .t2 {
    padding-left: 10px;
    flex-grow: 3;
    text-align: left;
  }
  .t3 {
    flex-grow: 1;
  }
}
@media screen and (max-width: 1200px) {
  .box-rank-list {
    font-size: 12px;
  }
}
</style>
