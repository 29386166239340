<template>
  <div class="scroll-wrapper scroll-more-body" ref="RScrollWrap">
    <div class="scroll-content" ref="RScrollContent">
      <div class="pulldown-tips" :class="pullDownStateClass">
        <div class="pulldown-con">
          <span class="pulldown-txt">{{ pullDownText }}</span>
        </div>
      </div>
      <slot></slot>
      <div class="pullup-tips" :class="pullUpStateClass">
        <div class="pullup-con">
          <span class="pullup-txt">{{ pullUpText }}</span>
        </div>
      </div>
    </div>
    <!-- custom-vertical-scrollbar-->
    <div class="custom-vertical-scrollbar" ref="vertical">
      <div class="custom-vertical-indicator"></div>
    </div>
  </div>
</template>

<script>
import TypeIs from '@/api/TypeIs'
import BScroll from 'better-scroll'
/*
better-scroll api:
https://better-scroll.github.io/docs/zh-CN/plugins/
*/
export default {
  name: 'BScroll',
  props: {
    FnGetData: {
      type: Function,
      required: true,
      default: () => {}
    },
    limit: {
      //每页数量
      type: [Number, String],
      default() {
        return 10
      }
    },
    pageName: {
      //分页的page名称
      type: String,
      default() {
        return 'page'
      }
    },
    limitName: {
      //分页的limit名称
      type: String,
      default() {
        return 'limit'
      }
    },
    pullDownRefresh: {
      //是否开启下拉刷新
      type: String,
      default() {
        return ''
      }
    },
    memoryScroll: {
      //记忆滚动位置
      type: String,
      default() {
        return ''
      }
    },
    noDataText: {
      type: String,
      default: '没有更多数据'
    }
  },
  data() {
    return {
      bscroll: false, //bscroll组件对象
      postData: {},
      dataType: {},
      beforePullDown: true,
      isPullUpLoad: false,
      isPullingDown: false,
      pullUpStateClass: '',
      pullUpText: '',
      pullDownStateClass: '',
      pullDownText: '',
      pullDownOpt: {
        threshold: 60,
        stop: 42
      }
    }
  },
  mounted() {
    this.initPlug()
  },
  methods: {
    initPlug() {
      if (this.bscroll == false) {
        this.bscroll = new BScroll(this.$refs.RScrollWrap, {
          mouseWheel: {
            speed: 20,
            invert: false
          },
          scrollbar: {
            customElements: [this.$refs.vertical],
            fade: false,
            interactive: true,
            scrollbarTrackClickable: true
          },
          bounceTime: 200,
          observeDOM: true, //开启对 content 以及 content 子元素 DOM 改变的探测
          observeImage: true, //开启图片监听，图片加载完毕后刷新滚动
          movable: true, //用于移动端
          click: true, //允许内部的点击事件生效
          scrollX: false,
          scrollY: true,
          pullUpLoad: true, //上拉加载
          pullDownRefresh: this.pullDownRefresh ? this.pullDownOpt : false, //下拉刷新
          isPullUpLoad: false //上拉加载中
        })
        this.bscroll.on('scroll', (pos) => {
          this.scrollHandler(pos)
          this.$emit('eventOut_scroll', pos)
        })
        this.bscroll.on('beforeScrollStart', () => {
          this.touchIn = true
        })
        this.bscroll.on('touchEnd', () => {
          this.touchIn = false
        })
        if (this.pullDownRefresh) {
          this.setPullDownState('none')
          this.bscroll.on('pullingDown', this.pullingDownHandler)
        }
        this.bscroll.on('pullingUp', this.pullingUpHandler)
      }
    },
    refresh() {
      this.pullingDownHandler()
    },
    pullingDownHandler: function () {
      var postKey = JSON.stringify(this.postData)
      this.init_dataType()
      this.setPullDownState('loading')
      this.dataType[postKey].page = 1
      this.pullingState = 'pullingDown'
      this.getData_list({ state: this.pullingState })
      this.$emit('eventOut_pullingDown')
    },
    pullingUpHandler() {
      let postKey = JSON.stringify(this.postData)
      this.init_dataType()
      this.dataType[postKey].page++
      this.pullingState = 'pullingUp'
      this.getData_list({ state: this.pullingState })
    },
    init_dataType() {
      let postKey = JSON.stringify(this.postData)
      if (
        typeof this.dataType[postKey] == 'undefined' ||
        typeof this.dataType[postKey].page == 'undefined'
      ) {
        this.dataType[postKey] = {
          page: 0,
          list: [],
          state: 'has-data'
        }
      }
    },
    initData(ret) {
      //ret.saveData=true 用于事先缓存数据
      //调用方法：this.$refs.scrollMore.initData({data:{type:2},saveData:true});
      this.initPlug()
      let def = {
        data: {}
      }
      let opt = Object.assign({}, def, ret)
      this.bscroll?.stop()
      if (opt && opt.data) {
        this.postData = opt.data
      }
      let paramData = this.postData
      let postKey = JSON.stringify(paramData)

      if (this.pullDownRefresh) {
        this.setPullDownState('none')
        this.bscroll.finishPullDown()
      }
      if (
        this.dataType[postKey] &&
        this.dataType[postKey].page &&
        this.dataType[postKey].page > 0
      ) {
        this.setPullUpState(this.dataType[postKey].state)
        this.$emit('setList', {
          param: paramData,
          list: this.dataType[postKey].list
        })
        this.bscroll.refresh()
        if (this.dataType[postKey].scroll && this.memoryScroll) {
          //如果有记忆滚动位置，则回到该滚动位置
          this.bscroll.scrollTo(0, this.dataType[postKey].scroll.y)
        }
      } else {
        //如果没有此数据类别的上拉加载对象，则需要创建
        this.init_dataType()
        this.dataType[postKey].page = 1
        this.bscroll.finishPullUp() //加载之前先让上拉加载处于待命状态
        this.pullingState = opt.state = 'init'
        this.getData_list(opt)
      }
      //console.log(this.dataType);
    },
    scrollHandler: function (pos) {
      //滚动时触发
      if (this.pullDownRefresh && this.touchIn) {
        if (
          pos.y >= this.pullDownOpt.threshold &&
          this.pullDownStateClass == 'done'
        ) {
          this.setPullDownState('loosen-refresh')
        } else if (pos.y < this.pullDownOpt.threshold) {
          this.setPullDownState('done')
        }
      } else if (this.pullDownStateClass == 'done') {
        this.setPullDownState('none')
      }
      let postKey = JSON.stringify(this.postData)
      if (this.dataType && this.dataType[postKey]) {
        this.dataType[postKey].scroll = pos //记忆滚动位置
      }
    },
    getData_list(ret) {
      let def = {}
      let opt = Object.assign({}, def, ret)
      let _this = this
      let paramData = this.postData
      let postKey = JSON.stringify(paramData)
      let paging = {}
      paging[this.pageName] = this.dataType[postKey].page
      paging[this.limitName] = this.limit
      let postData = Object.assign({}, this.postData, paging)
      if (opt && opt.saveData) {
        //仅存储数据，不发送写Dom事件
      } else if (opt.state == 'pullingUp' || opt.state == 'init') {
        _this.setPullUp_typeState(postKey, 'loading')
      }
      _this.FnGetData(postData).then((ret) => {
        let list, count
        if (TypeIs.Array(ret)) {
          list = ret
        } else if (TypeIs.Object(ret)) {
          list = ret.list
          count = ret.count
        }
        if (opt && opt.saveData) {
          //仅存储数据，不发送写Dom事件
          if (opt.state == 'pullingDown') {
            _this.dataType[postKey].list = list
            _this.setPullDownState('success')
          } else if (opt.state == 'pullingUp') {
            _this.dataType[postKey].list.push(...list)
          } else if (opt.state == 'init') {
            _this.dataType[postKey].list = list
          }
        } else {
          if (_this.pullingState == opt.state) {
            //判断callback中的state和当前state是否一致，如果不一致。可能是短时间多次请求的遗留callback，直接无视掉，只取state一致的一个callback输出到dom
            if (opt.state == 'pullingDown') {
              _this.dataType[postKey].list = list
              _this.setPullDownState('success')
            } else if (opt.state == 'pullingUp') {
              _this.dataType[postKey].list.push(...list)
            } else if (opt.state == 'init') {
              _this.dataType[postKey].list = list
            }
            _this.$emit('setList', {
              param: postData,
              list: _this.dataType[postKey].list
            }) //往父组件发送list数据
            if (
              (list.length >= 0 && list.length < _this.limit) ||
              (count && _this.dataType[postKey].list.length >= count)
            ) {
              _this.setPullUp_typeState(postKey, 'no-more')
            } else {
              _this.setPullUp_typeState(postKey, 'has-data')
              _this.bscroll.refresh()
              _this.hasDataScroll()
            }
          }
        }
      })
    },
    hasDataScroll() {
      this.$nextTick(() => {
        if (
          this.$refs.RScrollContent?.scrollHeight <
          this.$refs.RScrollWrap?.offsetHeight
        ) {
          this.pullingUpHandler()
        }
      })
    },
    setPullUp_typeState(postKey, text) {
      //console.log(this.dataType,postKey,text);
      this.dataType[postKey].state = text
      this.setPullUpState(text)
    },
    setPullUpState(text) {
      this.pullUpStateClass = text
      if (text == 'no-more') {
        this.bscroll.closePullUp()
        this.pullUpText = this.noDataText
      } else if (text == 'has-data') {
        this.bscroll.finishPullUp()
        this.pullUpText = '上拉加载更多'
      } else if (text == 'loading') {
        this.pullUpText = '数据加载中...'
      }
    },
    setPullDownState: function (pullState) {
      //设置scrollPull的upllDownState状态
      this.pullDownStateClass = pullState
      if (pullState == 'none') {
        this.pullDownText = ''
      } else if (pullState == 'done') {
        this.pullDownText = '下拉刷新'
      } else if (pullState == 'loosen-refresh') {
        this.pullDownText = '松开刷新'
      } else if (pullState == 'success') {
        this.pullDownText = '刷新成功'
        this.bscroll.finishPullDown()
        setTimeout(() => {
          this.setPullDownState('none')
        }, 400 + 100)
      } else if (pullState == 'loading') {
        this.pullDownText = '数据加载中...'
      }
    }
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scroll-wrapper {
  height: 100%;
}
.scroll-more-body {
  position: relative;
  padding-right: 15px;
  overflow: hidden;
}
.pulldown-tips {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: #999;
  padding: 8px 10px;
  font-size: 14px;
  position: absolute;
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
}
.pulldown-tips .pulldown-txt {
  display: inline-block;
  margin-left: 5px;
}
.pulldown-tips .pulldown-con {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pulldown-tips.done .pulldown-con:before {
  content: '\e69b';
  font-family: iconfont;
}
.pulldown-tips.loosen-refresh .pulldown-con:before {
  content: '\e675';
  font-family: iconfont;
}
.pulldown-tips.loading .pulldown-con:before {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1em;
  border-radius: 100%;
  border: 0.08em solid rgba(200, 200, 200, 0.7);
  border-bottom-color: transparent;
  vertical-align: middle;
  -webkit-animation: rotate 0.75s linear infinite;
  animation: rotate 0.75s linear infinite;
}

.pullup-tips {
  color: rgba(150, 150, 150, 0.7);
  padding: 10px;
  font-size: 14px;
}
.pullup-tips .pullup-txt {
  display: inline-block;
  margin-left: 5px;
}
.pullup-tips .pullup-con {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pullup-tips.has-data .pullup-con:before {
  content: '\e675';
  font-family: iconfont;
}
.pullup-tips.loading .pullup-con:before {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1em;
  border-radius: 100%;
  border: 0.08em solid rgba(200, 200, 200, 0.7);
  border-bottom-color: transparent;
  vertical-align: middle;
  -webkit-animation: rotate 0.75s linear infinite;
  animation: rotate 0.75s linear infinite;
}

.custom-vertical-scrollbar {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0;
  width: 7px;
  border-radius: 6px;
  background-color: rgb(200, 200, 200, 0.3);
  .custom-vertical-indicator {
    width: 100%;
    height: 20px;
    border-radius: 6px;
    background-color: #00bfbf;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
