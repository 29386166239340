export function tooltipStation(params, ret) {
  let html = ''
  let stationHtml = '<div>' + params[0].name + '</div>'
  for (let i = 0; i < params.length; i++) {
    html +=
      '<div>' +
      params[i].marker +
      params[i].seriesName +
      '：' +
      (params[i].value == '-' ? '-' : params[i].value + ret?.unit) +
      '</div>'
  }
  return stationHtml + html
}

export function getLinearColor(colors) {
  let colorStops = []
  for (let i = 0; i < colors.length; i++) {
    colorStops.push({
      offset: i,
      color: colors[i] // 0% 处的颜色
    })
  }
  return {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: colorStops,
    global: false // 缺测为 false
  }
}

export function nowSize(val, initWidth = 1920) {
  return val * (document.documentElement.clientWidth / initWidth)
}
