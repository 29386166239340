<template>
  <div class="box-project">
    <div class="main-bar">
      <ul class="m-list" v-if="list?.length">
        <li
          v-for="item in list"
          :key="item"
          :class="type == 'video' ? 'li-video' : ''"
          @click="EventSelect(item)"
        >
          <KOimg :src="item.img"></KOimg>
          <div class="name">{{ item.name }}</div>
        </li>
      </ul>
      <div v-else class="ui-no-data">暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    brandType: {
      type: [Number, String],
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    firstItem() {
      return this.list[0]
    },
    nextItems() {
      return this.list
    }
  },
  mounted() {},
  methods: {
    EventSelect(item) {
      let brandItem = this.Api.getBrandItem(this.brandType)
      //避暑旅游地没有详情页
      if (brandItem?.path && item.id && brandItem.detail) {
        this.Api.routerLink({
          path: brandItem.path + 'Detail',
          query: {
            id: item.id,
            hasCheck: this.brandType == 3 ? 1 : ''
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-project {
  .m-list {
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      flex-basis: calc((100% - 60px) / 4);
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: 0.3s ease-out;
      &:hover {
        color: var(--theme-color);
      }
      &.li-video::after {
        content: '';
        position: absolute;
        top: 40%;
        left: 50%;
        display: block;
        width: 73px;
        height: 73px;
        transform: translate(-50%, -50%);
        background: url('@/assets/img/icon/icon-video.png') center no-repeat;
      }
      .ko-img {
        height: 180px;
      }
      .name {
        padding: 15px 0;
        text-align: center;
        background: #fff;
        font-size: 18px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
/*@media screen and (min-width: 2000px) {
  .box-project {
    .m-list {
      li {
        .ko-img {
          height: 240px;
        }
      }
    }
  }
}*/
@media screen and (max-width: 1200px) {
  .box-project {
    .m-list {
      li {
        .ko-img {
          height: 130px;
        }
        .name {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
