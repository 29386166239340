<template>
  <ChartCore ref="RChartView"></ChartCore>
</template>
<script>
import ChartCore from '@/components/content/chart/ChartCore.vue'
//import { nowSize } from '@/plug/chart/chartFn.js'
export default {
  log: '',
  components: { ChartCore },
  props: {
    fmt: {
      type: String,
      default: 'hh:mm'
    }
  },
  data() {
    return {
      listData: [],
      ChartDataObj: {},
      dateKey: 'Datetime',
      elementKey: '',
      title: '',
      option: {}
    }
  },
  watch: {},
  computed: {},
  updated() {},
  mounted() {
    this.initData()
    this.initEvent()
  },
  methods: {
    initEvent() {
      window.addEventListener('resize', () => {
        this.initData()
      })
    },
    initData(list) {
      if (list) {
        this.listData = list ?? []
      }
      let option = this.getData()
      if (this.$refs.RChartView) {
        if (option) {
          this.$refs.RChartView.initData(option)
        } else {
          this.$refs.RChartView.initData(option)
        }
      }
    },
    getData() {
      let list = []
      for (let item of this.listData) {
        list.push({
          name: item.name + '(' + item.value + ')',
          value: item.value
        })
      }
      let opt = {
        color: [
          '#00DDFF',
          '#37A2FF',
          '#FF0087',
          '#FFBF00',
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc',
          '#80FFA5'
        ],
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 'right',
          itemGap: 5,
          itemHeight: 8,
          itemWidth: 15,
          formatter: (e) => {
            return e
          }
        },
        series: [
          {
            type: 'pie',
            center: ['35%', '50%'],
            radius: ['20%', '50%'],
            data: list,
            label: {
              formatter: '{c}'
            }
          }
        ],
        tooltip: {
          trigger: 'item'
        }
      }
      return opt
    }
  }
}
</script>
<style lang="scss" scoped></style>
