import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { Style, Text, Fill } from 'ol/style'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { setRgbaAlpha } from '@/api/color/colorModify.js'
import { fromLonLat } from 'ol/proj' // 导入投影转换函数

class LabelDrawer {
  constructor({ map, labels, center }) {
    this.map = map
    this.labels = labels
    this.center = center

    this.vectorSource = new VectorSource()
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    })

    this.map.addLayer(this.vectorLayer)
    this.drawLabels()
    this.opacity = 1
    this.isFadingOut = false // 新增标志位
    this.isFadingIn = false // 新增标志位
  }

  drawLabels() {
    this.vectorSource.clear() // 清空之前的标签
    this.labels.forEach((label) => {
      const feature = new Feature({
        geometry: new Point(this.transformCoordinates(this.center)) // 转换坐标
      })
      this.setLabelFeatureStyle(feature, label)
      feature.set('labelData', label) // 保存初始颜色
      feature.set('featureType', label.featureType ?? 'label-drawer')
      this.vectorSource.addFeature(feature)
    })
  }

  setLabelFeatureStyle(feature, label) {
    feature.setStyle(
      new Style({
        text: new Text({
          text: label.text,
          fill: new Fill({
            color: label.color
          }),
          font: label.fontSize + ' Calibri,sans-serif',
          offsetX: label.offsetX,
          offsetY: label.offsetY,
          textAlign: label.textAlign ?? 'center',
          backgroundFill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        })
      })
    )
  }

  // 坐标转换方法
  transformCoordinates(coordinates) {
    // 假设传入的是国标经纬度 (longitude, latitude)
    const mapProjection = this.map.getView().getProjection().getCode() // 获取地图的投影
    if (mapProjection == 'EPSG:4326') {
      return coordinates
    } else {
      return fromLonLat(coordinates) // 转换为墨卡托投影
    }
  }

  setVisible(visible) {
    this.vectorLayer.setVisible(visible)
  }

  updateFeatureOpacity(feature, opacity) {
    const label = feature.get('labelData') // 保存初始颜色
    label.color = setRgbaAlpha(label.color, opacity)
    this.setLabelFeatureStyle(feature, label)
  }

  fadeIn(duration = 100) {
    this.isFadingIn = true // 开始淡入时设置标志位
    this.isFadingOut = false // 确保淡出标志位为 false
    const step = 1 / (duration / 16) // 每帧增加的透明度
    const fade = () => {
      if (!this.isFadingIn) return // 如果不再淡入，停止淡入
      this.opacity += step
      if (this.opacity >= 1) {
        this.opacity = 1
        this.isFadingIn = false // 淡入完成，重置标志位
      }
      if (this.vectorSource) {
        this.vectorSource.getFeatures().forEach((feature) => {
          this.updateFeatureOpacity(feature, this.opacity)
        })
        if (this.opacity < 1) {
          requestAnimationFrame(fade)
        }
      }
    }

    fade()
  }

  fadeOut(duration = 100) {
    this.isFadingOut = true // 开始淡出时设置标志位
    this.isFadingIn = false // 确保淡入标志位为 false
    const step = 1 / (duration / 16) // 每帧减少的透明度

    const fade = () => {
      if (!this.isFadingOut) return // 如果不再淡出，停止淡出
      this.opacity -= step
      if (this.opacity <= 0) {
        this.opacity = 0
        this.isFadingOut = false // 淡出完成，重置标志位
      }
      if (this.vectorSource) {
        this.vectorSource.getFeatures().forEach((feature) => {
          this.updateFeatureOpacity(feature, this.opacity)
        })
        if (this.opacity > 0) {
          requestAnimationFrame(fade)
        }
      }
    }

    fade()
  }

  update(labels) {
    this.labels = labels // 更新标签数据
    this.drawLabels() // 重新绘制标签
  }

  clear() {
    this.vectorSource.clear() // 清除所有标签
  }

  destroy() {
    this.clear() // 清除标签
    this.map.removeLayer(this.vectorLayer) // 从地图中移除图层
    this.vectorSource = null // 释放资源
    this.vectorLayer = null // 释放资源
    this.map = null // 释放资源
  }
}

export default LabelDrawer
