<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-10-11 14:24:13
-->
<template>
  <CpnMapPopup
    v-if="clickPointPopup"
    :data="clickPointPopup"
    :left="clickPointPopup.left"
    :top="clickPointPopup.top"
  >
    <template #default="{ slotData }">
      <MPopupOxyViewMarker
        :data="slotData"
        @close="clickPointPopup = null"
      ></MPopupOxyViewMarker>
    </template>
  </CpnMapPopup>
</template>
<script>
import CpnMapPopup from '@/components/content/map/popup/CpnMapPopup.vue'
import MPopupOxyViewMarker from './child/MPopupOxyViewMarker'
import { clickPointPopup } from './js/mapPointPopup.js'
export default {
  inject: [],
  components: { CpnMapPopup, MPopupOxyViewMarker },
  props: {},
  data() {
    return {
      clickPointPopup
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped></style>
