<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-10-12 09:58:34
-->
<template>
  <div class="map-select-area">
    <SelectArea
      v-model:areaCode="MapData.areaCode"
      ref="RSelectArea"
      @select="EventSelect_area"
    ></SelectArea>
  </div>
</template>
<script>
import SelectArea from '@/components/content/select/SelectArea.vue'
import { MapData, changeAreaCode } from '../js/MapPlugAdminPolygon.js'
export default {
  inject: [],
  components: { SelectArea },
  props: {},
  data() {
    return {
      MapData
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSelect_area(ret) {
      changeAreaCode(ret.areaCode, ret.areaName)
    }
  }
}
</script>
<style lang="scss" scoped>
.map-select-area {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
