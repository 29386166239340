<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-10-10 17:39:16
-->
<template>
  <div class="m-map-zoom-button">
    <MapButton @EventClick="EventClick">
      <template v-slot:top>
        <div log="插槽:上部按钮" class="ui-button" @click="EventClick_home">
          <i class="jiachang icon-cb"></i>
        </div>
      </template>
      <template v-slot:bottom>
        <div
          v-if="MapData.level > 0"
          log="插槽:下部按钮"
          class="ui-button"
          @click="EventClick_back"
        >
          <i class="icon-chexiao iconfont"></i>
        </div>
      </template>
    </MapButton>
  </div>
</template>
<script>
import MapButton from '@/components/common/map/base/MapButton.vue'
import {
  MapData,
  changeAreaCode
} from '@/components/view/map/modelMapOxyView/js/MapPlugAdminPolygon.js'
import { zoomCtrl } from '@/components/view/map/modelMapOxyView/js/initMap.js'
import { getParentAreaCode } from '@/api/func/DataPro.js'
export default {
  inject: [],
  components: { MapButton },
  props: {},
  data() {
    return {
      MapData
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(key) {
      zoomCtrl(key)
    },
    EventClick_home() {
      changeAreaCode(100000, '全国')
    },
    EventClick_back() {
      let areaCode = getParentAreaCode(MapData.areaCode)
      changeAreaCode(areaCode)
    }
  }
}
</script>
<style lang="scss" scoped>
.m-map-zoom-button {
  position: absolute;
  right: 0;
  bottom: 20px;
}
</style>
