<template>
  <div class="m-map-area-radio-button">
    <el-radio-group
      ref="RRadioGroup"
      v-model="MapRoot.areaCode"
      size="large"
      @change="EventChange_area"
    >
      <el-radio-button
        v-for="item in MapRoot.areaDatas"
        :key="item.value"
        :label="item.value"
        >{{ item.name }}</el-radio-button
      >
    </el-radio-group>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getData_araes()
    },
    getData_araes() {
      this.MapRoot.areaDatas = [
        { name: '黑龙江', value: '230000' }
        //{ name: '云南', value: '530000' }
      ]
    },
    EventChange_area(areaCode) {
      for (let item of this.MapRoot.areaDatas) {
        if (item.value == areaCode) {
          this.MapRoot.areaName = item.name
          break
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.m-map-area-radio-button {
  --el-color-primary: #38cae3;
}
</style>
