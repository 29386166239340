import { InfoCrops } from '@/info/info.js'
export default {
  namespaced: true,
  state() {
    return {
      storeName: 'global',
      cropList: [],
      mapScreen: true //地图显示状态
    }
  },
  getters: {},
  mutations: {
    initData(state) {
      this.dispatch(state.storeName + '/Action_cropList')
    },
    saveState(state, ret) {
      state[ret.key] = ret.value
    },
    setCropList(state, ret) {
      state.cropList = ret
    }
  },
  actions: {
    Action_cropList({ commit }) {
      InfoCrops().then((ret) => {
        if (ret?.data?.length) {
          commit('setCropList', ret.data)
        } else {
          console.error('获取作物列表失败')
        }
      })
    }
  }
}
