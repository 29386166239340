<template>
  <div class="area-map">
    <div class="column-container">
      <CpnTabMap
        :tabs="tabs"
        v-model="tabIndex"
        @select="EventSelect"
        class="mgb-30"
      ></CpnTabMap>
      <LayerFilterPoint v-if="PageRoot.brandType == 3"></LayerFilterPoint>
      <div
        class="mgb-40"
        v-if="PageRoot.brandType == 3 && PageRoot.pointView == 1"
      >
        <ModelMapOxy log="负氧离子专用地图" mapId="map-oxy"></ModelMapOxy>
        <ModelMapOxyView log="氧吧分布地图" v-if="false"></ModelMapOxyView>
      </div>
      <ModelMapChaye
        log="茶叶专用地图"
        v-else-if="PageRoot.brandType == 6"
        mapId="map-oxy"
        class="mgb-40"
      ></ModelMapChaye>
      <ModelMapHome
        class="mgb-40"
        v-else
        log="通用地图"
        :mapId="mapId"
      ></ModelMapHome>
    </div>
  </div>
</template>
<script>
import CpnTabMap from '@/components/view/tab/CpnTabMap.vue'
import { IDict_channelType } from '@/info/infoDict.js'
import ModelMapHome from '@/components/view/map/modelMapHome/ModelMapHome.vue'
import ModelMapOxy from '@/components/view/map/modelMapOxy/ModelMapOxy.vue'
import ModelMapOxyView from '@/components/view/map/modelMapOxyView/ModelMapOxyView.vue'
import ModelMapChaye from '@/components/view/map/modelMapChaye/ModelMapChaye.vue'
import LayerFilterPoint from '@/views/pages/home/layer/LayerFilterPoint.vue'

export default {
  log: '',
  components: {
    CpnTabMap,
    ModelMapHome,
    ModelMapOxy,
    ModelMapOxyView,
    ModelMapChaye,
    LayerFilterPoint
  },
  props: {},
  provide: function () {
    return {
      PageRoot: this.PageRoot
    }
  },
  data() {
    return {
      list: [],
      tabs: [],
      mapId: 'map-home',
      tabIndex: 0,
      PageRoot: {
        brandType: 3,
        brandName: '中国天然氧吧',
        pointView: 0 //氧吧点的数据类别：全部、只有负氧离子的
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initTabs()
  },
  methods: {
    initTabs() {
      IDict_channelType().then((ret) => {
        this.tabs = ret.data
      })
    },
    EventSelect(ret) {
      this.PageRoot.brandType = ret.key
      this.PageRoot.brandName = ret.name
      console.log(ret)
    }
  }
}
</script>
<style lang="scss" scoped></style>
