<template>
  <div class="m-select-wrap">
    <el-cascader
      class="m-select-area"
      v-model="selectCity_value"
      :props="cascaderProps"
      popper-class="is-dark"
      :filterable="true"
      size="default"
      :collapse-tags="true"
      :show-all-levels="false"
      :options="selectCity_options"
      @change="cascaderChange"
      ref="RCascaderArea"
    ></el-cascader>
  </div>
</template>
<script>
import { Info_areaTree } from '@/info/info.js'
import { getAreaCodeLevel } from '@/api/func/DataPro.js'
import { DataFlat } from '@/api/func/areaTreePro.js'
// import chinaJson from '@/assets/json/china.json'
export default {
  components: {},
  props: {
    areaCode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      cascaderProps: {
        checkStrictly: true,
        label: 'name',
        value: 'id',
        emitPath: false
      },
      selectCity_value: '',
      selectCity_options: [],
      areaDict: {}
    }
  },
  watch: {
    areaCode: {
      handler(val) {
        this.selectCity_value = val.toString()
      },
      immediate: true
    }
  },
  computed: {},
  created() {
    this.initData()
  },
  mounted() {},
  methods: {
    initData() {
      Info_areaTree().then((ret) => {
        if (ret?.data?.length) {
          let treeJson = ret.data
          treeJson[0].id = '100000'
          treeJson[0].name = '全国'
          this.reData_tree(treeJson)
          this.selectCity_options = treeJson
          this.setTreeDict()
        }
      })
    },
    setTreeDict() {
      let obj = DataFlat({
        list: this.selectCity_options,
        areaNameKey: 'name',
        areaCodeKey: 'id'
      })
      this.$emit('getDict', obj.dict)
    },
    reData_tree(ret) {
      for (let item of ret) {
        if (getAreaCodeLevel(item.id) == 2) {
          //去掉区县的数据
          delete item.children
        }
        if (item.children && item.children.length) {
          this.reData_tree(item.children)
        } else {
          delete item.children
        }
      }
    },
    cascaderChange() {
      let nodes = this.$refs.RCascaderArea.getCheckedNodes()
      this.$refs.RCascaderArea.togglePopperVisible(false)
      this.$emit('update:areaCode', nodes[0]?.value)
      this.$emit('select', {
        areaName: nodes[0]?.label,
        areaCode: nodes[0]?.value,
        level: getAreaCodeLevel(nodes[0]?.value)
      })
    },
    clearArea() {
      this.selectCity_value = ''
    }
  }
}
</script>
<style scoped lang="scss">
.m-select-wrap {
  width: 180px;
}
.m-select-area {
  display: block;
}
</style>
