<template>
  <div class="cpn-marker-select-legend">
    <el-select
      v-model="MapRoot.visibleYear"
      class="m-select"
      popper-class="m-markerLegend-popper"
      :multiple="true"
      collapse-tags
    >
      <el-option
        v-for="item in legendDict"
        :key="item.name"
        :label="item.name"
        :value="item.key"
        ><span class="icon" :style="{ 'background-color': item.color }"></span>
        <span class="name">{{ item.name }}</span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      allRead: false,
      allHide: false
    }
  },
  watch: {
    legendDict: {
      handler(val) {
        if (val !== 'label') {
          this.EventAllHide()
        }
      }
    }
  },
  computed: {
    markerPoints() {
      return this.MapRoot?.markerPoints ?? []
    },
    legendDict() {
      return this.MapRoot?.legendDict
    }
  },
  mounted() {},
  methods: {
    EventClick(ret) {
      this.allRead = true
      for (let item of this.markerPoints) {
        if (item.key == ret.key) {
          ret.visible = true
        } else {
          item.visible = false
        }
      }
    },
    EventAllShow() {
      this.allRead = false
      for (let item of this.markerPoints) {
        item.visible = true
      }
    },
    EventAllHide() {
      this.allHide = true
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-marker-select-legend {
  width: 170px;
  border-radius: 5px;
  margin-right: 10px;
  .top-lump {
    margin-bottom: 12px;
    display: flex;
    .link {
      flex-grow: 1;
      text-align: center;
      color: #0cc;
      cursor: pointer;
      &:hover {
        color: #0ff;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    .icon {
      height: 12px;
      width: 12px;
      margin-right: 7px;
      border-radius: 50%;
      opacity: 0.5;
      background: var(--legend-color);
    }
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.point-visible {
      color: #fff;
      .icon {
        opacity: 1;
      }
    }
  }
}
.m-markerLegend-popper {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .icon {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
</style>
