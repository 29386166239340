<template log="homeMap-包组件">
  <div class="cpn-map-wrap">
    <div class="cpn-map-home" :class="'cpn-map-home-' + mapId">
      <div
        :id="mapId"
        class="map-container"
        v-loading="mapLoadingArr?.length"
        :element-loading-text="mapLoadingText"
        element-loading-background="rgba(255, 255, 255, 0.5)"
      ></div>
      <!--基础-->
      <CNMapInit></CNMapInit>
      <CNMapZoomButton></CNMapZoomButton>
      <!--功能-->
      <CNMapAdminDivi
        ref="RCNMapAdminDivi"
        v-if="MapRoot.mapPlug && MapRoot.areaCode"
        log="地图组件-轮廓多边形"
      ></CNMapAdminDivi>
      <!--图层-->
      <MarkerPopup></MarkerPopup>
      <div class="layer-left-top-area">
        <MarkerSelectLegend></MarkerSelectLegend>
        <SelectArea
          :areaCode="MapRoot.areaCode"
          ref="RSelectArea"
          @getDict="EventGetDict"
          @select="EventSelect_area"
        ></SelectArea>
      </div>
      <MarkerLegend></MarkerLegend>
      <MapEXNum log="审图号"></MapEXNum>
      <!--其他图层-->
      <Map9Line v-if="MapRoot.areaCode == '100000'" log="9段线"></Map9Line>
    </div>
    <OtherDataView
      v-if="PageRoot.brandType != 6"
      class="m-layer-right"
    ></OtherDataView>
  </div>
</template>
<script>
import initMap from '@/plug/map/openLayer/initMap.js'
//import MapBaseImg from '@/plug/map/openLayer/MapBaseImg.js'
/*base*/
import CNMapInit from '@/components/content/map/base/CNMapInit.vue'
import CNMapZoomButton from '@/components/content/map/base/CNMapZoomButton.vue'
/*cpn*/
import CNMapAdminDivi from '@/components/content/map/cpn/CNMapAdminDivi.vue'
import SelectArea from '@/components/content/select/SelectArea.vue'
/*lay*/
import MarkerPopup from './layer/MarkerPopup.vue'
import MarkerSelectLegend from './layer/MarkerSelectLegend.vue'
import MarkerLegend from './layer/MarkerLegend.vue'

import OtherDataView from './layer/OtherDataView.vue'
import MapEXNum from '@/components/common/map/layer/MapEXNum.vue'
import Map9Line from '@/components/common/map/layer/Map9Line.vue'
import { MXmap } from './mixin/MXmap'
//import { nowSize } from '@/plug/chart/chartFn.js'

export default {
  inject: ['PageRoot'],
  mixins: [MXmap],
  components: {
    CNMapInit,
    CNMapZoomButton,

    CNMapAdminDivi,
    SelectArea,
    MarkerPopup,
    MarkerSelectLegend,
    MarkerLegend,
    OtherDataView,
    MapEXNum,
    Map9Line
  },
  props: {
    mapId: {
      type: String,
      default: 'map-main'
    }
  },
  provide: function () {
    return {
      MapRoot: this.MapRoot
    }
  },
  data() {
    return {
      mapLoadingArr: [],
      mapLoadingText: '地图加载中',
      MapRoot: {
        //地图共享数据集，子组件通过inject获取
        mapPlug: false, //地图组件
        areaCode: this.Api.areaCode,
        areaName: this.Api.areaName,
        selectAreaCode: '100000',
        areaLevel: 0,
        areaDict: {},
        parentAreaCodes: [],
        parentAreaNames: [],
        adminDiviJson: {
          inner: {},
          outer: {}
        },
        plottingScale: '', //地图分辨率
        mouseCoord: '', //鼠标移动的经纬度
        clickPoint: false, //海量点组件-当前点击的点
        mapViewType: '', // 地图组件预览类型：1.label
        visibleYear: [], //显示的年份marker
        selectAreaYear: [], //鼠标选中的区域年份
        legendDict: [], //点的图例字典
        //方法
        onLoading: this.onLoading,
        offLoading: this.offLoading
      }
    }
  },
  watch: {
    'PageRoot.brandType': {
      handler() {
        //初始化地图
        this.MapRoot.mapPlug
        this.MapRoot.areaCode = this.Api.areaCode
        this.MapRoot.selectAreaCode = this.Api.areaCode
        this.MapRoot.areaName = this.Api.areaName
        this.$refs.RSelectArea.clearArea()
      }
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let config = {
        id: this.mapId,
        ...this.Api.getMapConfig(),
        center: [103.5, 34],
        zoom: 4.35
      }
      let mapPlug = new initMap(config)
      /*mapPlug.mapBaseImg = new MapBaseImg({
        mapPlug: mapPlug,
        opacity: 1
      })*/
      this.MapRoot.mapPlug = mapPlug

      this.initMXMap()
    },
    EventSelect_area(ret) {
      this.MapRoot.selectAreaCode = ret.areaCode
      this.MapRoot.areaLevel = ret.level
      this.MapRoot.areaName = ret.areaName
      this.MapRoot.areaCode = ret.areaCode
    },
    EventGetDict(dict) {
      this.MapRoot.areaDict = dict
    },
    onLoading(text) {
      this.mapLoadingArr.push(text)
      this.mapLoadingText = text
    },
    offLoading() {
      this.mapLoadingArr.splice(0, 1)
      this.mapLoadingText = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-wrap {
  display: flex;
}
.cpn-map-home {
  position: relative;
  height: 690px;
  padding-top: 30px;
  flex-grow: 1;
  //background: url('~@/assets/img/home/map-bg.jpg') center/cover no-repeat;
  .map-container {
    height: 100%;
    ::v-deep(.ol-viewport) {
      position: static !important;
      .ol-overlaycontainer-stopevent {
        z-index: 50 !important;
      }
    }
  }
  .m-mapline-9 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    border: 1px solid #38cae3;
  }
}
.layer-left-top-area {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
}
</style>
