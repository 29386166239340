<!-- components/MapPopup.vue -->
<template>
  <div :style="popupStyle" class="cpn-map-popup">
    <div class="popup-main">
      <slot :slotData="data"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    left: {
      type: Number,
      required: true
    },
    top: {
      type: Number,
      required: true
    }
  },
  computed: {
    popupStyle() {
      return {
        position: 'absolute',
        left: `${this.left}px`,
        top: `${this.top}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cpn-map-popup {
  position: absolute;
  z-index: 30;
  .popup-main {
    position: absolute;
    bottom: 5px;
    left: 0;
    transform: translate(-50%, 0);
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -5px;
      margin-top: -1px;
      border-width: 5px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
}
</style>
