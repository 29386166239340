<template>
  <div class="cbox-home-news" v-loading="loading">
    <div class="first-news">
      <CarItemImgNews
        v-if="recommendList?.length"
        :list="recommendList"
      ></CarItemImgNews>
    </div>
    <div class="news-list">
      <ul class="list">
        <li v-for="item in list" :key="item" @click="EventOpen(item)">
          <CpnItemNews
            icon="news"
            :isNew="getNewStyle(item)"
            :title="item.title"
            >{{ item.title }}</CpnItemNews
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import CarItemImgNews from './boxNews/CarItemImgNews.vue'
import CpnItemNews from '@/components/view/listItem/CpnItemNews.vue'
import { Info_homeNewsList } from '@/info/infoHome.js'
export default {
  log: '',
  components: { CarItemImgNews, CpnItemNews },
  props: {
    typeCode: {
      type: String,
      default: 'ZXDT'
    },
    brandType: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      recommendList: []
    }
  },
  watch: {
    typeCode: {
      handler() {
        this.initData()
      }
    }
  },
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      Info_homeNewsList({
        pageNum: 1,
        pageSize: 13,
        typeCode: this.typeCode,
        sortField: 'isTop DESC,isRecommend DESC,publishTime DESC',
        isRecommend: 1,
        brandType: this.brandType
      }).then((ret) => {
        this.loading = false
        if (ret?.list?.length) {
          let list = ret.list.map((e) => {
            return { ...e, img: this.Api.getImgPath() + e.img }
          })
          this.recommendList = list.splice(0, 4)
          this.list = list
        }
      })
    },
    getNewStyle(item) {
      return this.Api.isNew(item.publishTime)
    },
    EventOpen(item) {
      this.Api.openNewsDetail(item)
    }
  }
}
</script>
<style lang="scss" scoped>
.cbox-home-news {
  display: flex;
  .first-news {
    width: 0;
    flex-grow: 1;
    width: 10px;
    cursor: pointer;
  }
  .news-list {
    flex-grow: 1;
    width: 10px;
    padding: 10px 0;
    box-sizing: border-box;
    background: #f6fcfd;
    .list {
      flex-grow: 1;
      min-height: 330px;
      padding: 28px 20px 0 20px;
      li {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .cbox-home-news {
    .news-list {
      display: flex;
      align-items: center;
      .list {
        padding: 0 30px;
      }
    }
  }
}
</style>
