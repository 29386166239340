import { KOAjax } from '../service/koajax.js'
import Api from '@/api/Api.js'
import { JsonAjax as DefAjax } from '../service/ajax.js'

/**
 * @获取字典Option
 */
export function Info_dictOption(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return KOAjax({
    morePost: true,
    postType: 'formData',
    url: '/disasterMonitorEarlyWarning/api/common/dictList',
    method: 'post',
    data: opt
    //load: 'mock'
  })
}

/**
 * @通过areaCode获取areaName
 */
export function Info_getAreaName(ret) {
  let def = { areaCode: Api.getAreaCode() }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api-am/areaService/areaInfo',
    params: opt
  })
}

/**
 *粮油作物+特色作物
 * @param {cropCategoryID:100（粮油作物）、200（特色作物）} ret
 * @returns
 */
export function InfoCrops(ret) {
  let def = { areaCode: Api.getAreaCode() }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'post',
    url: '/api-am/CropService/getCropList',
    params: opt
  })
}

/**
 * @查询作物列表
 */
export function Info_sc_crop_list(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/api-am/CropService/getAllCrop',
    params: opt
  })
}

/**
 * @获取_area树
 */
export function Info_areaTree(ret) {
  let def = { regionId: '' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: 'api/region/tree',
    params: opt
  })
}
