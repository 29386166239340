<template>
  <div class="cpn-tab-swiper-d">
    <Swiper
      :slides-per-view="per"
      :slides-per-group="per"
      @swiper="initSwiper"
      :modules="modules"
      :mousewheel="false"
      navigation
    >
      <SwiperSlide
        v-for="(item, ind) in orderList"
        :class="ind == index ? 'current' : ''"
        :key="item"
        @click="EventClick(item, ind)"
      >
        <slot name="slide" :prop="{ ...item, index: index }"></slot>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Mousewheel, Navigation } from 'swiper'
import 'swiper/css/mousewheel'
import 'swiper/css/navigation'
export default {
  log: '',
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    index: {
      type: [String, Number],
      default: 0
    },
    per: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      modules: [Mousewheel, Navigation]
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    },
    index: {
      handler(val) {
        this.swiper.slideTo(val)
      }
    }
  },
  computed: {
    orderList() {
      const list = this.list
      let reList = list.sort((a, b) => {
        return a.orderNo - b.orderNo
      })
      return reList
    }
  },
  mounted() {},
  methods: {
    EventClick(item, index) {
      this.$emit('update:index', index)
      this.$emit('select', { ...item, index: index })
    },
    initSwiper(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-tab-swiper-d {
  ::v-deep(.swiper) {
    .swiper-wrapper {
      padding-bottom: 20px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 50%;
    }
    .swiper-button-disabled {
      display: none;
    }
    .swiper-scrollbar-drag {
      background: #00bfbf;
    }
    .swiper-scrollbar {
      width: 7px;
    }
    .swiper-slide {
      cursor: pointer;
      width: auto;
      .name {
        padding: 10px;
        color: #666;
        text-align: center;
        margin: 0 5px;
        box-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .current {
      .name {
        position: relative;
        color: #33c3cc;
        font-weight: bold;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 30px;
          transform: translate(-50%, 0);
          border-bottom: 3px solid #33c3cc;
        }
      }
    }
  }
}
</style>
