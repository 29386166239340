<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-10-10 14:17:29
-->
<template>
  <div class="cpn-map-9-line">
    <KOimg :src="map9img" class="m-mapline-9" />
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      map9img: require('@/assets/img/home/map-9.jpg')
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.m-mapline-9 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 160px;
  border: 1px solid #38cae3;
}
</style>
