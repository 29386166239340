import GeoJSON from 'ol/format/GeoJSON'
import * as turf from '@turf/turf'
/**
 * 计算Feature的边界。
 *
 * @param {object} feature - Feature对象。
 * @returns {array} 包含边界的数组，格式为 [minX, minY, maxX, maxY]。
 */
export function calculateFeatureBounds(feature) {
  const geometry = feature.geometry
  if (
    !geometry ||
    (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon')
  ) {
    throw new Error('每个Feature中必须包含Polygon或MultiPolygon几何')
  }

  const coordinates = geometry.coordinates

  if (!coordinates || coordinates.length === 0) {
    throw new Error('几何必须包含坐标')
  }

  if (geometry.type === 'Polygon') {
    return calculatePolygonBounds(coordinates)
  } else if (geometry.type === 'MultiPolygon') {
    return calculateMultiPolygonBounds(coordinates)
  }
}

/**
 * 计算多边形的边界。
 *
 * @param {array} coordinates - 多边形的坐标数组。
 * @returns {array} 包含边界的数组，格式为 [minX, minY, maxX, maxY]。
 */
export function calculatePolygonBounds(coordinates) {
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  coordinates.forEach((ring) => {
    ring.forEach((point) => {
      const [x, y] = point
      minX = Math.min(minX, x)
      minY = Math.min(minY, y)
      maxX = Math.max(maxX, x)
      maxY = Math.max(maxY, y)
    })
  })

  return [minX, minY, maxX, maxY]
}

/**
 * 计算多多边形的边界。
 *
 * @param {array} coordinates - 多多边形的坐标数组。
 * @returns {array} 包含边界的数组，格式为 [minX, minY, maxX, maxY]。
 */
export function calculateMultiPolygonBounds(coordinates) {
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  coordinates.forEach((polygon) => {
    const bounds = calculatePolygonBounds(polygon)
    minX = Math.min(minX, bounds[0])
    minY = Math.min(minY, bounds[1])
    maxX = Math.max(maxX, bounds[2])
    maxY = Math.max(maxY, bounds[3])
  })

  return [minX, minY, maxX, maxY]
}

/**
 * 计算FeatureCollection中所有Feature的边界（合并边界）。
 *
 * @param {object} geojson - 包含多个Feature的FeatureCollection GeoJSON。
 * @returns {array} 包含合并边界的数组，格式为 [minX, minY, maxX, maxY]。
 * @throws {Error} 如果输入不是FeatureCollection或Feature中没有Polygon几何，将引发错误。
 */
export function calculateBounds(geojson) {
  if (geojson.type !== 'FeatureCollection') {
    throw new Error('输入的GeoJSON必须是一个FeatureCollection')
  }

  const features = geojson.features

  if (!features || features.length === 0) {
    throw new Error('FeatureCollection必须包含至少一个Feature')
  }

  let totalMinX = Infinity
  let totalMinY = Infinity
  let totalMaxX = -Infinity
  let totalMaxY = -Infinity

  features.forEach((feature) => {
    const bounds = calculateFeatureBounds(feature)
    totalMinX = Math.min(totalMinX, bounds[0])
    totalMinY = Math.min(totalMinY, bounds[1])
    totalMaxX = Math.max(totalMaxX, bounds[2])
    totalMaxY = Math.max(totalMaxY, bounds[3])
  })

  return [totalMinX, totalMinY, totalMaxX, totalMaxY]
}

// 计算叉积
function crossProduct(p1, p2, p3) {
  return (
    (p2.lon - p1.lon) * (p3.lat - p1.lat) -
    (p2.lat - p1.lat) * (p3.lon - p1.lon)
  )
}

// 计算两点之间的距离
export function distance(p1, p2) {
  return Math.sqrt(Math.pow(p2.lon - p1.lon, 2) + Math.pow(p2.lat - p1.lat, 2))
}

/**
 * 返回经纬度点集的凸包
 * @param {Array} points //传入一个包含经纬度的数组
 * @returns {Array} //返回一个凸包数组
 */
export function convexHull(points) {
  points.sort((a, b) => a.lon - b.lon || a.lat - b.lat) // 按照 lon（经度）排序

  const lower = []
  for (const point of points) {
    while (
      lower.length >= 2 &&
      crossProduct(lower[lower.length - 2], lower[lower.length - 1], point) <= 0
    ) {
      lower.pop()
    }
    lower.push(point)
  }
  const upper = []
  for (let i = points.length - 1; i >= 0; i--) {
    const point = points[i]
    while (
      upper.length >= 2 &&
      crossProduct(upper[upper.length - 2], upper[upper.length - 1], point) <= 0
    ) {
      upper.pop()
    }
    upper.push(point)
  }

  upper.pop()
  lower.pop()
  return lower.concat(upper)
}

/**
 *获取point点是否在多边形polygon中
 * @param {Array} point //经纬度[lon,lat]
 * @param {Array} polygon //经纬度数组集合[[lon,lat],[lon,lat]]
 * @returns
 */
export function isPointInPolygon(point, polygon) {
  const [lon, lat] = point
  let isInside = false

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, yi] = polygon[i]
    const [xj, yj] = polygon[j]

    const intersect =
      yi > lat !== yj > lat && lon < ((xj - xi) * (lat - yi)) / (yj - yi) + xi

    if (intersect) isInside = !isInside
  }

  return isInside
}
/**
 *获取point点是否在多边形polygon类型的geojson中
 * @param {Array} point //经纬度[lon,lat]
 * @param {Object} geojson - 包含FeatureCollection的GeoJSON对象
 * @returns
 */
export function isPointInGeojson(point, featureCollection) {
  // 创建点的 GeoJSON 对象
  const pointGeoJSON = turf.point(point)
  // 遍历 FeatureCollection 中的每个 feature
  for (let feature of featureCollection.features) {
    // 根据 geometry 类型进行处理
    if (feature.geometry.type === 'Polygon') {
      // 如果是 Polygon 类型
      const polygonGeoJSON = turf.polygon(feature.geometry.coordinates)
      if (turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON)) {
        return true
      }
    } else if (feature.geometry.type === 'MultiPolygon') {
      // 如果是 MultiPolygon 类型
      for (let polygon of feature.geometry.coordinates) {
        const polygonGeoJSON = turf.polygon(polygon)
        if (turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON)) {
          return true
        }
      }
    }
  }
  // 如果点不在任何 feature 的 geometry 内，返回 false
  return false
}

/**
 *获取point点是否在多边形polygon类型的geojson中
 * @param {Array} points //经纬度[[lon,lat],[...]]
 * @param {Object} geojson - 包含FeatureCollection的GeoJSON对象
 * @returns
 */
export function getPointsInGeojson(points, geojson) {
  let list = []
  for (let item of points) {
    if (isPointInGeojson([item.lon, item.lat], geojson)) {
      list.push(item)
    }
  }
  return list
}

/**
 * 更新边界值
 * @param {Array} coord - 坐标数组，形式为 [经度, 纬度]
 * @param {Object} bounds - 当前的边界值
 */
function updateBounds(coord, bounds) {
  let lat = coord[1]
  let lng = coord[0]
  bounds.minLat = Math.min(bounds.minLat, lat)
  bounds.minLng = Math.min(bounds.minLng, lng)
  bounds.maxLat = Math.max(bounds.maxLat, lat)
  bounds.maxLng = Math.max(bounds.maxLng, lng)
}
/**
 * 处理点类型的坐标
 * @param {Array} coordinates - 坐标数组
 * @param {Object} bounds - 当前的边界值
 */
function processPointCoordinates(coordinates, bounds) {
  updateBounds(coordinates, bounds)
}
/**
 * 处理线或多点类型的坐标
 * @param {Array} coordinates - 坐标数组
 * @param {Object} bounds - 当前的边界值
 */
function processLineOrMultiPointCoordinates(coordinates, bounds) {
  coordinates.forEach((coord) => {
    updateBounds(coord, bounds)
  })
}
/**
 * 处理多边形或多线类型的坐标
 * @param {Array} coordinates - 坐标数组
 * @param {Object} bounds - 当前的边界值
 */
function processPolygonOrMultiLineCoordinates(coordinates, bounds) {
  coordinates.forEach((ring) => {
    ring.forEach((coord) => {
      updateBounds(coord, bounds)
    })
  })
}
/**
 * 处理多多边形类型的坐标
 * @param {Array} coordinates - 坐标数组
 * @param {Object} bounds - 当前的边界值
 */
function processMultiPolygonCoordinates(coordinates, bounds) {
  coordinates.forEach((polygon) => {
    processPolygonOrMultiLineCoordinates(polygon, bounds)
  })
}
/**
 * 获取包围框的经纬度范围
 * @param {object} geojson - 包含地理信息的 JSON 对象
 * @returns {Array} - 返回经纬度范围数组 [minLng, minLat, maxLng, maxLat]
 */
export function getBoundsByGeojson(geojson) {
  let bounds = {
    minLat: Infinity,
    minLng: Infinity,
    maxLat: -Infinity,
    maxLng: -Infinity
  }
  if (geojson?.type === 'Feature') {
    processFeature(geojson, bounds)
  } else if (geojson?.type === 'FeatureCollection') {
    geojson.features.forEach((feature) => {
      processFeature(feature, bounds)
    })
  }
  return [bounds.minLng, bounds.minLat, bounds.maxLng, bounds.maxLat]
}

/**
 * 处理单个地理信息要素
 * @param {object} feature - 单个地理信息要素对象
 * @param {object} bounds - 当前包围框的经纬度范围对象
 */
function processFeature(feature, bounds) {
  let coordinates = feature.geometry.coordinates
  if (feature.geometry.type === 'Point') {
    processPointCoordinates(coordinates, bounds)
  } else if (
    feature.geometry.type === 'LineString' ||
    feature.geometry.type === 'MultiPoint'
  ) {
    processLineOrMultiPointCoordinates(coordinates, bounds)
  } else if (
    feature.geometry.type === 'Polygon' ||
    feature.geometry.type === 'MultiLineString'
  ) {
    processPolygonOrMultiLineCoordinates(coordinates, bounds)
  } else if (feature.geometry.type === 'MultiPolygon') {
    processMultiPolygonCoordinates(coordinates, bounds)
  }
}

export function GeoJSON2Feature(polygonEPSG4326Geojson) {
  // 解析 GeoJSON 对象
  var format = new GeoJSON()
  var feature = format.readFeature(polygonEPSG4326Geojson, {
    dataProjection: 'EPSG:4326', // 输入数据的投影坐标系
    featureProjection: 'EPSG:4326' // 输出 feature 的投影坐标系
  })
  return feature
}
/**
 * 将一个Polygon或MultiPolygon类型的FeatureCollection转换为指定的格式
 * @param {Object} featureCollection - GeoJSON格式的FeatureCollection对象
 * @returns {Array} - 转换后的坐标数组
 * @throws {Error} - 当FeatureCollection无效或第一个Feature不是Polygon或MultiPolygon时抛出错误
 */
export function transformPolygonFeatureCollection(featureCollection) {
  // 检查FeatureCollection是否有效
  if (
    !featureCollection ||
    !featureCollection.features ||
    featureCollection.features.length === 0
  ) {
    throw new Error('无效的FeatureCollection')
  }

  // 从FeatureCollection中获取第一个Feature
  const firstFeature = featureCollection.features[0]
  // 检查第一个Feature是否为Polygon或MultiPolygon
  if (
    firstFeature.geometry.type !== 'Polygon' &&
    firstFeature.geometry.type !== 'MultiPolygon'
  ) {
    throw new Error('第一个Feature不是Polygon或MultiPolygon类型')
  }
  let coordinates = []
  if (firstFeature.geometry.type == 'Polygon') {
    // 获取Polygon或MultiPolygon的坐标
    coordinates = firstFeature.geometry.coordinates
  } else if (firstFeature.geometry.type == 'MultiPolygon') {
    for (let item of firstFeature.geometry.coordinates) {
      coordinates.push(item[0])
    }
  }
  // 返回指定格式的坐标数组
  return coordinates
}

/**
 * 将一个MultiPolygon合并为一个Polygon
 * @param {Array} multiPolygonCoordinates - MultiPolygon的坐标数组
 * @returns {Array} - 合并后的Polygon的坐标数组
 * @throws {Error} - 当输入不是有效的MultiPolygon坐标数组时抛出错误
 */
export function mergeMultiPolygonToPolygon(multiPolygonCoordinates) {
  // 检查输入是否为有效的MultiPolygon坐标数组
  if (
    !Array.isArray(multiPolygonCoordinates) ||
    multiPolygonCoordinates.length === 0
  ) {
    throw new Error('无效的MultiPolygon坐标数组')
  }

  // 用于存储合并后的Polygon坐标
  const mergedCoordinates = []
  // 遍历MultiPolygon的每个Polygon
  for (const polygon of multiPolygonCoordinates) {
    // 检查每个Polygon是否为有效的坐标数组
    if (!Array.isArray(polygon) || polygon.length === 0) {
      throw new Error('无效的Polygon坐标数组')
    }

    // 将每个Polygon的坐标添加到合并后的坐标数组中
    mergedCoordinates.push(...polygon[0])
  }

  // 返回合并后的Polygon坐标数组
  return mergedCoordinates
}
