<template>
  <div class="map-ex-num">
    <img :src="amapLogo" alt="" />© 2022 高德软件 GS京(2022)1061号
    <slot></slot>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      amapLogo: require('@/assets/img/amap-logo.png')
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.map-ex-num {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  img {
    height: 20px;
    margin-right: 5px;
  }
}
</style>
