<template>
  <div class="cpn-title-A">
    <div class="title"><slot></slot></div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    more: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-title-A {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 5px 0;
  .title {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: -20px;
      margin-top: -10px;
      content: '';
      width: 20px;
      height: 20px;
      background: url('~@/assets/img/icon/icon-news.png') center/50% auto
        no-repeat;
      transform: rotate(90deg);
    }
    &:after {
      position: absolute;
      top: 50%;
      right: -20px;
      margin-top: -10px;
      content: '';
      width: 20px;
      height: 20px;
      background: url('~@/assets/img/icon/icon-news.png') center/50% auto
        no-repeat;
    }
  }
}
@media screen and (max-width: 1200px) {
  .cpn-title-A {
    font-size: 12px;
  }
}
</style>
