import VectorImageLayer from 'ol/layer/VectorImage'
import VectorSource from 'ol/source/Vector.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import { Style, Fill, Stroke, Text } from 'ol/style.js'
import Point from 'ol/geom/Point.js'
import MultiPolygon from 'ol/geom/MultiPolygon.js'

class FeaturePolygonLabel {
  map
  geojsonData
  textKeys = ['AreaName', 'name', 'areaName']
  layerType = 'adminDivi'
  zIndex = 20
  showText = true // 控制文本显示状态
  showPolygon = true // 控制多边形显示状态

  constructor(props) {
    Object.assign(this, props)
    this.VectorLayer_featurePolygon = null // 存储矢量图层以便以后更新
    this.VectorLayer_featureLabel = null // 存储矢量图层以便以后更新
    this.initMap()
  }

  // 初始化地图
  initMap() {
    this.createVectorLayer()
    this.drawFeatures(this.VectorLayer_featurePolygon) // 仅在数据变化时绘制特征
    this.drawFeatures(this.VectorLayer_featureLabel) // 仅在数据变化时绘制特征
  }

  createVectorLayer() {
    this.VectorLayer_featurePolygon = new VectorImageLayer({
      source: new VectorSource(),
      properties: { type: 'adminPolygonLayer' },
      style: this.showPolygon
        ? (feature) => this.createPolygonStyle(feature)
        : null,
      zIndex: this.zIndex
    })

    this.VectorLayer_featureLabel = new VectorImageLayer({
      source: new VectorSource(),
      properties: { type: 'adminLabelLayer' },
      style: this.showText ? (feature) => this.createTextStyle(feature) : null,
      zIndex: this.zIndex + 1
    })
    this.map.addLayer(this.VectorLayer_featurePolygon)
    this.map.addLayer(this.VectorLayer_featureLabel)
  }

  updateStyle({ textStyleConfig }) {
    Object.assign(this.textStyleConfig, textStyleConfig)

    this.VectorLayer_featurePolygon.setStyle((feature) => {
      return this.createPolygonStyle(feature)
    })
    this.VectorLayer_featureLabel.setStyle((feature) => {
      feature.layerType = this.layerType
      return this.createTextStyle(feature)
    })
  }

  // 更新GeoJSON数据的方法
  update(geojsonData) {
    if (JSON.stringify(this.geojsonData) !== JSON.stringify(geojsonData)) {
      this.geojsonData = geojsonData
      this.drawFeatures(this.VectorLayer_featurePolygon) // 仅在数据变化时绘制特征
      this.drawFeatures(this.VectorLayer_featureLabel) // 仅在数据变化时绘制特征
    }
  }

  setVisible(isVisible) {
    this.VectorLayer_featurePolygon.setVisible(isVisible)
    this.VectorLayer_featureLabel.setVisible(isVisible)
  }

  // 绘制特征
  drawFeatures(layer) {
    let source = layer.getSource()
    source?.clear()
    if (this.geojsonData?.features?.length) {
      const mapProjection = this.map.getView().getProjection().getCode() // 获取地图的投影
      const geojsonFormat = new GeoJSON({
        dataProjection: 'EPSG:4326', // 输入投影
        featureProjection: mapProjection // 输出投影
      })
      let features = geojsonFormat.readFeatures(this.geojsonData)
      source.addFeatures(features)
    }
  }

  clear() {
    if (this.VectorLayer_featurePolygon) {
      this.map.removeLayer(this.VectorLayer_featurePolygon)
      this.VectorLayer_featurePolygon.getSource().clear()
      this.VectorLayer_featurePolygon = null
    }
    if (this.VectorLayer_featureLabel) {
      this.map.removeLayer(this.VectorLayer_featureLabel)
      this.VectorLayer_featureLabel.getSource().clear()
      this.VectorLayer_featureLabel = null
    }
  }

  getCenterPoint(geometry) {
    if (geometry instanceof MultiPolygon) {
      const polygons = geometry.getPolygons()
      let maxPointsPolygon = polygons[0]
      let maxPoints = this.getTotalPoints(maxPointsPolygon)
      polygons.forEach((polygon) => {
        const totalPoints = this.getTotalPoints(polygon)
        if (totalPoints > maxPoints) {
          maxPointsPolygon = polygon
          maxPoints = totalPoints
        }
      })
      return maxPointsPolygon.getInteriorPoint().getCoordinates()
    } else {
      return geometry.getInteriorPoint().getCoordinates()
    }
  }

  getTotalPoints(polygon) {
    return polygon.getCoordinates().reduce((sum, ring) => sum + ring.length, 0)
  }

  createPolygonStyle(feature) {
    const fillColor = feature.get('fillColor')
    const strokeColor = feature.get('strokeColor')
    const strokeWidth = feature.get('strokeWidth')
    const featureId = feature.get('featureId')
    const polygonStyle = new Style({
      fill: new Fill({
        color: fillColor ?? 'rgba(0,0,0,0.1)'
      }),
      stroke: new Stroke({
        color: strokeColor ?? 'rgba(0,0,0,1)',
        width: strokeWidth ?? 1
      })
    })
    feature.layerType = this.layerType
    feature.set('featureType', featureId ? featureId + '-polygon' : 'polygon')
    return polygonStyle
  }

  createTextStyle(feature) {
    feature.layerType = this.layerType
    const featureId = feature.get('featureId')
    feature.set('featureType', featureId ? featureId + '-label' : 'label')
    const geometry = feature.getGeometry()
    const center = this.getCenterPoint(geometry)
    let name = ''
    for (let item of this.textKeys) {
      name = feature.get(item)
      if (name) {
        break
      }
    }
    if (name) {
      const font = feature.get('font')
      const fontColor = feature.get('fontColor')
      const fontStrokeColor = feature.get('fontStrokeColor')
      const fontStrokeWidth = feature.get('fontStrokeWidth')
      const offsetX = feature.get('fontOffsetX')
      const offsetY = feature.get('fontOffsetY')
      const placement = feature.get('fontPlacement')
      const textStyle = new Style({
        text: new Text({
          font: font ?? '10px Calibri,sans-serif',
          text: name,
          fill: new Fill({
            color: fontColor ?? '#000'
          }),
          stroke: new Stroke({
            color: fontStrokeColor ?? '#fff',
            width: fontStrokeWidth ?? 3
          }),
          offsetX: offsetX ?? 0,
          offsetY: offsetY ?? 0,
          placement: placement ?? 'point'
        }),
        geometry: new Point(center)
      })
      return textStyle
    }
    return null
  }

  toggleTextVisibility(show) {
    this.showText = show
    this.drawFeatures(this.VectorLayer_featureLabel) //  重新绘制特征以应用新的文本显示状态
  }

  togglePolygonVisibility(show) {
    this.showPolygon = show
    this.drawFeatures(this.VectorLayer_featurePolygon) // 重新绘制特征以应用新的多边形显示状态
  }

  viewport(padding = [30, 30, 30, 30]) {
    let source = this.VectorLayer_featurePolygon?.getSource()
    let features = source.getFeatures()
    if (features?.length) {
      let extent = source?.getExtent()
      this.map.getView().fit(extent, { padding })
    }
  }

  // 销毁方法
  destroy() {
    this.clear()
  }
}

export default FeaturePolygonLabel
