import { Debouncer } from '@/api/utilsFn.js'

const map = new WeakMap()

/**
 * ResizeObserver 回调函数，用于处理元素大小变化。
 * @param {ResizeObserverEntry[]} entries - 观察到的元素变化条目。
 */
const Ob = new ResizeObserver((entries) => {
  for (const entry of entries) {
    // 从 WeakMap 中获取与当前元素关联的防抖处理器
    const handler = map.get(entry.target)
    if (handler) {
      // 调用防抖处理器，传递元素的新尺寸
      handler.call({
        width: entry.borderBoxSize[0].inlineSize,
        height: entry.borderBoxSize[0].blockSize
      })
    }
  }
})

export default {
  /**
   * Vue 指令的 mounted 生命周期钩子。
   * @param {HTMLElement} el - 绑定指令的元素。
   * @param {Object} binding - 包含指令相关信息的对象。
   */
  mounted(el, binding) {
    // 创建一个新的防抖处理器
    const debouncedHandler = new Debouncer(
      (size) => {
        // 调用绑定的回调函数
        binding.value(size)
      },
      false, // 不在防抖开始时立即执行
      100 // 防抖等待时间为 100 毫秒
    )

    // 将防抖处理器与元素关联
    map.set(el, debouncedHandler)
    // 开始观察元素
    Ob.observe(el)
  },

  /**
   * Vue 指令的 unmounted 生命周期钩子。
   * @param {HTMLElement} el - 绑定指令的元素。
   */
  unmounted(el) {
    // 停止观察元素
    Ob.unobserve(el)
    // 从 WeakMap 中移除元素的处理器
    map.delete(el)
  }
}
