import { ref, reactive } from 'vue'
import { InfoMap_adminDivi, InfoMap_adminDiviOuter } from '@/info/infoMap.js'
import { InfoMap_pieProductList } from '@/info/infoOxyMapData.js'
import FeaturePolygonLabel from '@/plug/map/openLayer/polygon/FeaturePolygonLabel.js'
import LineDrawer from '@/plug/map/openLayer/line/LineDrawer.js'
import LabelDrawer from '@/plug/map/openLayer/label/LabelDrawer.js'
import { roundCorners } from '@/plug/map/openLayer/line/utils.js'
import { getYears } from '@/api/func/dateFn.js'
import { KOMap } from './initMap.js'
import { adminLineOptions } from './adminLineOptions.js'
import { getPostDataByAreaCode } from '@/work/workCommon.js'
import { getAreaCodeLevel } from '@/api/func/DataPro.js'
import { PromiseGetAreaName } from './utilsMap.js'
import { initMapPoint, clearMapPoint, destroyMapPoint } from './setMapPoint'
import {
  viewBounds,
  getColorByLegend
} from '@/plug/map/openLayer/utils/utilsFn.js'
export const AdminPlug = ref(null)
export const AdminMultLinePlug = ref(null)
// const AdminOuterPlug = ref(null)
let geojsonInner = null
// let geojsonOuter = null
export const MapData = reactive({
  loading: false,
  brandType: 3,
  areaCode: '100000',
  areaName: '全国',
  level: 0
})

export const oxyLegend = ref([
  { color: '#43ABB1', val: 20, name: '>20' },
  { color: '#71C9CE', val: 16, name: '16~20' },
  { color: '#A6E3E9', val: 11, name: '11~15' },
  { color: '#CBF1F5', val: 6, name: '6~10' },
  { color: '#E3FDFD', val: 1, name: '1~5' },
  { color: '#FFF', val: 0, name: '0' }
])

export function initMapData() {
  MapData.areaCode = '100000'
  MapData.areaName = '全国'
  MapData.level = 0
}

export function initPlugAdminDivi(areaCode = '100000') {
  let postData = getPostDataByAreaCode(areaCode)
  let level = getAreaCodeLevel(areaCode)
  MapData.loading = true
  Promise.all([
    InfoMap_adminDivi({ areaCode }),
    InfoMap_adminDiviOuter({ areaCode }),
    InfoMap_pieProductList({
      ...postData,
      brandType: 3,
      years: getYears(2016).join(',')
    })
  ])
    .then((art) => {
      MapData.loading = false
      if (art[0]?.features?.length) {
        geojsonInner = art[0]
      }
      // if (art[1]?.features?.length) {
      //   //外轮廓
      //   geojsonOuter = art[1]
      // }
      let oxyDataDict = {}
      let areaList = []
      let list = []
      if (level == 0) {
        list = art[2]?.data.provinceList
      } else if (level > 0) {
        list = art[2]?.data.cityList
      }
      if (list?.length) {
        for (let item of list) {
          let code = item.province || item.city || item.region
          oxyDataDict[code] = item.total
        }
      }
      for (let item of geojsonInner.features) {
        let areaCode = item.properties.adcode
        let value = oxyDataDict[areaCode] ?? 0
        item.properties.fillColor = getColorByLegend(oxyLegend.value, value)
        item.properties.fillHoverColor = '#FBE1B6'
        item.properties.strokeColor = '#3c9398'
        item.properties.strokeHoverColor = '#3c9398'
        item.properties.fontStrokeWidth = 0
        item.properties.fontStrokeColor = 'rgba(0,0,0,0)'
        item.properties.featureId = 'admin'
        areaList.push({
          areaName: item.properties.name,
          areaCode: areaCode.toString(),
          value: value,
          points: adminLineOptions[areaCode]?.points,
          label: adminLineOptions[areaCode]?.label
        })
      }
      // for (let item of geojsonOuter.features) {
      //   item.properties.fillColor = 'rgba(0,0,0,0)'
      //   item.properties.strokeColor = '#43a0a5'
      //   item.properties.strokeWidth = 8
      // }
      setMap_polygonAdmin()
      if (areaCode == '100000') {
        viewBounds(KOMap.value, [72, 17, 135, 54], [0, 0, 40, 0])
        setMap_lineLabels(areaList)
        clearMapPoint()
      } else {
        initMapPoint()
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

function setMap_lineLabels(list) {
  let plug = {}
  for (let item of list) {
    if (item.points?.length) {
      plug[item.areaCode] = draw_lineLabel(item)
    }
  }
  AdminMultLinePlug.value = plug
}
function draw_lineLabel(ret) {
  let rePoint = roundCorners(ret.points)
  let areaName = ret.areaName.substring(0, 3)
  if (areaName != '黑龙江') {
    areaName = ret.areaName.substring(0, 2)
  }
  let leng = areaName.length * 15 + 15
  let plugs = {
    line: new LineDrawer({
      map: KOMap.value,
      lineData: rePoint
    }),
    label: new LabelDrawer({
      map: KOMap.value,
      labels: [
        {
          text: areaName,
          areaCode: ret.areaCode,
          color: '#0f7e8e',
          fontSize: '18px',
          offsetX: ret.label.offsetX,
          offsetY: ret.label.offsetY,
          textAlign: ret.label.textAlign ?? 'left'
        },
        {
          text: ret.value > 0 ? ret.value + '个' : '',
          color: '#425d54',
          fontSize: '16px',
          offsetX: ret.label.offsetX + leng,
          offsetY: ret.label.offsetY,
          textAlign: ret.label.textAlign ?? 'left'
        }
      ],
      center: ret.points[0]
    })
  }

  return plugs
  //lineDrawer.fadeOut()
}

function setMap_polygonAdmin() {
  if (!AdminPlug.value) {
    AdminPlug.value = new FeaturePolygonLabel({
      map: KOMap.value,
      geojsonData: geojsonInner,
      showText: true,
      zIndex: 45
    })
  } else {
    AdminPlug.value.update(geojsonInner)
    AdminPlug.value.viewport()
    destroyMapPlug()
  }
  // if (!AdminOuterPlug.value) {
  //   AdminOuterPlug.value = new FeaturePolygonLabel({
  //     map: KOMap.value,
  //     geojsonData: geojsonOuter,
  //     showText: true,
  //     zIndex: 10
  //   })
  // }
  // AdminOuterPlug.value.update(geojsonOuter)
}

export function changeAreaCode(areaCode, areaName) {
  MapData.level = getAreaCodeLevel(areaCode)
  if (MapData.level < 3) {
    MapData.areaCode = areaCode
    if (areaName) {
      MapData.areaName = areaName
      initPlugAdminDivi(areaCode)
    } else {
      PromiseGetAreaName(areaCode).then((areaName) => {
        MapData.areaName = areaName
        initPlugAdminDivi(areaCode)
      })
    }
  }
}

export function destroyMapPlug() {
  //销毁线和文本组件
  for (let i in AdminMultLinePlug.value) {
    let selectPlug = AdminMultLinePlug.value[i]
    selectPlug.line.destroy()
    selectPlug.label.destroy()
  }
  destroyMapPoint()
  AdminMultLinePlug.value = null
}
