<template>
  <VIndex></VIndex>
</template>

<script>
import VIndex from '@/views/VIndex.vue'
export default {
  components: { VIndex },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
