<template log="EChart-渲染组件:仅做图表渲染，不处理数据">
  <div class="echart-wrap">
    <div class="echart-core" :ref="id" :style="chartStyle"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import ResizeListener from 'element-resize-detector'
export default {
  props: {
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      id: 'REchart' + (Math.random() * 100000).toFixed(0),
      optionData: {},
      chartStyle: { width: '100%', height: '100%' }
    }
  },
  watch: {
    option: {
      handler(val) {
        this.optionData = val
        this.initData()
        // this.option.xAxis[0].data = val.dataName
        // this.option.series[0].data = val.dataValue
        // var myChart = echarts.init(document.getElementById('mains'))
        // // 使用刚指定的配置项和数据显示图表。
        // myChart.setOption(this.option)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      ResizeListener().listenTo(this.$refs[this.id], () => {
        this.initData()
      })
    },
    initData() {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        if (this.$refs[this.id]?.clientWidth > 0) {
          if (!this.myChart && this.optionData?.series) {
            let myChart = echarts.init(this.$refs[this.id])
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(this.optionData)
            if (this.optionData.dispatchAction) {
              //显示默认的tooltip
              myChart.dispatchAction(this.optionData.dispatchAction)
            }
            // 图表自适应
            this.myChart = myChart
          } else if (this.optionData?.series?.length) {
            this.myChart.resize()
            this.myChart.clear()
            this.myChart.setOption(this.optionData)
            if (this.optionData.dispatchAction) {
              this.myChart.dispatchAction(this.optionData.dispatchAction)
            }
          } else {
            console.error('意料之外的错误')
          }
        }
      })
    },
    refresh() {
      if (this.myChart && this.$refs[this.id]?.clientWidth > 0) {
        this.myChart.resize()
        this.myChart.clear()
        this.myChart.setOption(this.optionData)
      }
    },
    resize() {
      if (this.$refs[this.id]?.clientWidth > 0) {
        this.initData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.echart-wrap {
  height: 100%;
}
.chart-comp {
  height: 100%;
  width: 100%;
}
</style>
