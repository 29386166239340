<template>
  <div class="no-data">
    <img src="~@/assets/img/pic_hd.jpg" v-if="type == 'img'" class="err-img" />
    <div class="err-msg">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: '暂无数据'
    },
    type: {
      type: String,
      default: 'img'
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
