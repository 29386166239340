<template>
  <div>
    <div class="fix-declare" :style="{ top: 300 + top + 'px' }">
      <KOimg :src="require('@/assets/img/index/ew.png')"></KOimg>
      <div class="name">国家气候标志<br />公众号</div>
    </div>
    <div
      class="fix-backtop"
      @click="EventBackTop"
      :style="{ top: 550 + top + 'px' }"
      v-show="top > 800"
    >
      <i class="icon iconfont icon-zhiding"></i>回顶部
    </div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      top: 0
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initScroll()
  },
  methods: {
    initScroll() {
      window.addEventListener('scroll', () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
      })
    },
    EventBackTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.fix-declare {
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
  background: #fff;
  padding-bottom: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
  .ko-img {
    width: 100px;
  }
}
.fix-backtop {
  position: absolute;
  right: 0;
  width: 20px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
  text-align: center;
  cursor: pointer;
  z-index: 11;
  .icon {
    color: #3dc1bb;
    font-size: 18px;
  }
}
</style>
