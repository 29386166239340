<template>
  <div class="m-button">
    <el-button v-bind="$attrs" :class="newStyle" v-on:click="EventClick"
      ><slot></slot
    ></el-button>
  </div>
</template>
<script>
export default {
  emits: ['click'],
  log: '',
  name: 'UiButton',
  components: {},
  props: {
    newStyle: {
      type: String,
      default: 'button-setup'
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
.m-button {
  .button-setup {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: #fff;
  }
  .button-none {
    background: rgba(200, 200, 200, 0.2);
    border: 1px solid rgba(200, 200, 200, 0.2);
    color: rgba(255, 255, 255, 0.8);
  }
  .button-cancel {
    background: rgba(200, 200, 200, 0.2);
    border: 1px solid rgba(200, 200, 200, 0.6);
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>
